import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Tax } from 'app/modules/financial-management/models/Tax';
import { TaxService } from 'app/modules/financial-management/service/Tax/tax.service';

@Component({
  selector: 'ngx-update-taxe',
  templateUrl: './update-taxe.component.html',
  styleUrls: ['./update-taxe.component.scss']
})
export class UpdateTaxeComponent implements OnInit {

  taxId: string;
  tax: Tax;
  taxForm: FormGroup;
  formDisabled: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private taxService: TaxService,
    private toastrService: NbToastrService,
    private router: Router,
  ) {
    this.taxForm = this.formBuilder.group({
      description: [{ value: '', disabled: true }, Validators.required],
      name: [{ value: '', disabled: true }, Validators.required],
      rate: [{ value: '', disabled: true }, Validators.required],
      active: [{ value: '', disabled: true }, Validators.required], 
      country: [{ value: '', disabled: true }, Validators.required], 
    });
  }

  ngOnInit(): void {
    this.taxId = this.route.snapshot.paramMap.get('id');
    this.getTaxDetails();
  }

  getTaxDetails(): void {
    this.taxService.getTaxById(this.taxId).subscribe(
      tax => {
        this.tax = tax;
        this.populateForm();
      },
      error => {
        console.error('Error fetching tax details:', error);
      }
    );
  }

  populateForm(): void {
    if (this.tax) {
      this.taxForm.patchValue({
        description: this.tax.description,
        name: this.tax.name,
        rate: this.tax.rate,
        active: this.tax.active,
        country: this.tax.country,
      });
    }
  }

  onSubmit(): void {
    if (this.taxForm.valid) {
      const updateTaxData = this.taxForm.value;
      this.taxService.updateTax(this.taxId, updateTaxData).subscribe(
        updatedAccount => {
          console.log('Tax updated successfully:', updatedAccount);
          this.showToast('success', 'Success', 'Tax updated successfully');
          this.router.navigate(['/finance/taxes']);
        },
        error => {
          console.error('Error updating tax:', error);
        }
      );
    }
  }

  archiveConfirmation(): void {
    if (window.confirm('Are you sure you want to archive this tax?')) {
      this.archiveTax();
    }
  }

  archiveTax(): void {
    if (this.tax && this.tax.id) {
      this.taxService.archiveTax(this.tax.id).subscribe({
        next: () => {
          this.showToast('success', 'Tax Archived', 'The tax has been archived successfully.');
          this.router.navigate(['/finance/taxes']);
        },
        error: (err: any) => {
          console.error('Error archiving tax:', err);
          this.showToast('danger', 'Error', 'An error occurred while archiving the account.');
        }
      });
    } else {
      console.error('Tax id is undefined.');
      this.showToast('danger', 'Error', 'An error occurred. Please try again.');
    }
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  enableFormFields(): void {
    this.formDisabled = false;
    Object.keys(this.taxForm.controls).forEach(key => {
      this.taxForm.controls[key].enable();
    });
  }

  markAllAsTouched(): void {
    Object.keys(this.taxForm.controls).forEach(key => {
      this.taxForm.controls[key].markAsTouched();
    });
  }
}
