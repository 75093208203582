import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-button',
  template: `
    <button nbButton status="primary" (click)="selectRow()">
      Select
    </button>
  `,
})
export class SelectButtonComponent {
  @Input() rowData: any; // Données de ligne passées au composant

  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  selectRow() {
    this.onSelect.emit(this.rowData); // Émettre l'événement de sélection avec les données de la ligne
  }
}
