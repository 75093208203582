import { Component } from '@angular/core';

@Component({
  selector: 'ngx-tax-helper',
  templateUrl: './tax-helper.component.html',
  styleUrls: ['./tax-helper.component.scss']
})
export class TaxHelperComponent {

}
