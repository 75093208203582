<nb-card>
    <nb-card-header>
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h3>Dividend management process</h3>
            </div>
            <div>
                <button nbTooltip="Export to Excel file" (click)="exportExcel()" nbTooltipStatus="primary" nbButton status="success" class="mr-2"><i class="fas fa-file-excel text-white"></i></button>
                <button nbTooltip="Click here for help" nbTooltipStatus="primary" nbButton status="basic" (click)="openWindow()"><i class="fas fa-exclamation-circle fa-lg"></i></button>
            </div>
        </div>
    </nb-card-header>

    <nb-card-body>
        <nb-stepper orientation="horizontal" #stepper>

            <nb-step [label]="'Add Shareholder'">
                <h4>Add Shareholder</h4>
                <form [formGroup]="shareholderGroup" (ngSubmit)="submitShareholder()">


                    <div class="form-group">
                        <label class="label" for="shareholderFirstName"> First Name:</label>
                        <input formControlName="shareholderFirstName" id="shareholderFirstName" nbInput fieldSize="large" fullWidth type="text">
                        <div *ngIf="shareholderGroup.get('shareholderFirstName').invalid && (shareholderGroup.get('shareholderFirstName').dirty || shareholderGroup.get('shareholderFirstName').touched)" class="text-danger">
                            <div *ngIf="shareholderGroup.get('shareholderFirstName').errors.required">First Name is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="label" for="shareholderlastName"> Last Name:</label>
                        <input formControlName="shareholderlastName" id="shareholderlastName" nbInput fieldSize="large" fullWidth type="text">
                        <div *ngIf="shareholderGroup.get('shareholderlastName').invalid && (shareholderGroup.get('shareholderlastName').dirty || shareholderGroup.get('shareholderlastName').touched)" class="text-danger">
                            <div *ngIf="shareholderGroup.get('shareholderFirstName').errors.required">Last Name is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="label" for="shareholderAdress">Adress:</label>
                        <input formControlName="shareholderAdress" id="shareholderAdress" nbInput fieldSize="large" fullWidth type="text">
                        <div *ngIf="shareholderGroup.get('shareholderAdress').invalid && (shareholderGroup.get('shareholderAdress').dirty || shareholderGroup.get('shareholderAdress').touched)" class="text-danger">
                            <div *ngIf="shareholderGroup.get('shareholderAdress').errors.required">Adress is required.</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label" for="shareholderEmail">Email:</label>
                        <input formControlName="shareholderEmail" id="shareholderEmail" nbInput fieldSize="large" fullWidth type="text">
                        <div *ngIf="shareholderGroup.get('shareholderEmail').invalid && (shareholderGroup.get('shareholderEmail').dirty || shareholderGroup.get('shareholderEmail').touched)" class="text-danger">
                            <div *ngIf="shareholderGroup.get('shareholderEmail').errors.required">Email is required.</div>
                            <div *ngIf="dividendGroup.get('shareholderEmail').errors.email">Invalid email format.</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label" for="shareholderPhoneNumber">Phone number:</label>
                        <input formControlName="shareholderPhoneNumber" id="shareholderPhoneNumber" nbInput fieldSize="large" fullWidth type="text">
                        <div *ngIf="shareholderGroup.get('shareholderPhoneNumber').invalid && (shareholderGroup.get('shareholderPhoneNumber').dirty || shareholderGroup.get('shareholderPhoneNumber').touched)" class="text-danger">
                            <div *ngIf="shareholderGroup.get('shareholderPhoneNumber').errors.required">Phone number is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="label" for="shareholderNumberOfActions">Number of actions (%):</label>
                        <input formControlName="shareholderNumberOfActions" id="shareholderNumberOfActions" nbInput fieldSize="large" fullWidth type="number">
                        <div *ngIf="shareholderGroup.get('shareholderNumberOfActions').invalid && (shareholderGroup.get('shareholderNumberOfActions').dirty || shareholderGroup.get('shareholderNumberOfActions').touched)" class="text-danger">
                            <div *ngIf="shareholderGroup.get('shareholderNumberOfActions').errors.required">Number of actions is required.</div>
                        </div>
                    </div>


                    <div style="margin-left: 430px">
                        <br>
                        <button nbButton status="primary" class="ml-5">Send</button>

                    </div>
                    <br>


                    <nb-card>
                        <nb-card-header>
                            <h2>Shareholder's list</h2>

                        </nb-card-header>
                        <ng2-smart-table [settings]="shareholdersSettings" class="table" [source]="shareholders" (custom)="onCustomShareholders($event)">
                        </ng2-smart-table>
                    </nb-card>




                    <div class="button-group">
                        <div style="flex: 1;">
                            <button nbButton nbStepperPrevious>prev</button>
                        </div>

                        <div style="flex: 1; text-align: right;">
                            <button nbButton nbStepperNext>Next</button>
                        </div>
                    </div>

                </form>
            </nb-step>
            <nb-step [label]="'Add Dividend'">
                <h4>Add Dividend</h4>
                <form [formGroup]="dividendGroup" (ngSubmit)="submitDividend()">
                    <div class="form-group">
                        <label class="label" for="amount">Amount:</label>
                        <input formControlName="amount" id="amount" nbInput fieldSize="large" fullWidth type="number">
                        <div *ngIf="dividendGroup.get('amount').invalid && (dividendGroup.get('amount').dirty || dividendGroup.get('amount').touched)" class="text-danger">
                            <div *ngIf="dividendGroup.get('amount').errors.required">Amount is required.</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label" for="paymentDate">Payment date:</label>
                        <input formControlName="paymentDate" nbInput id="paymentDate" fieldSize="large" fullWidth [nbDatepicker]="dividend">
                        <nb-datepicker withSeconds #dividend></nb-datepicker>
                        <div *ngIf="dividendGroup.get('paymentDate').invalid && (dividendGroup.get('paymentDate').dirty || dividendGroup.get('paymentDate').touched)" class="text-danger">
                            <div *ngIf="dividendGroup.get('paymentDate').errors.required">Payment date is required.</div>
                        </div>
                    </div>

                    <div class="form-group full-width">
                        <label class="label" for="shareholder">Shareholder:</label><br>
                        <nb-select formControlName="shareholderId" id="shareholderId" class="shareholder-select" placeholder="Select shareholder" fullWidth>
                            <nb-option *ngFor="let shareholder of shareholders" [value]="shareholder.id">{{ shareholder.shareholderFirstName }} {{ shareholder.shareholderlastName }}</nb-option>
                        </nb-select>
                    </div>

                    <div class="form-group-pair">
                        <div class="form-group">
                            <label class="label" for="frequency">Frequency:</label><br>
                            <nb-select formControlName="frequency" id="frequency" fieldSize="large" fullWidth nbInput placeholder="Select frequency">
                                <nb-option value="quarterly">Quarterly</nb-option>
                                <nb-option value="annually">Annually</nb-option>
                            </nb-select>
                        </div>
                        <div class="form-group">
                            <label class="label" for="status">Status:</label><br>
                            <nb-select formControlName="status" id="status" fieldSize="large" fullWidth nbInput placeholder="Select status">
                                <nb-option value="confirmed">Confirmed</nb-option>
                                <nb-option value="pending">Pending</nb-option>
                                <nb-option value="cancelled">Cancelled</nb-option>
                            </nb-select>
                        </div>
                    </div>
                    <div class="form-group-pair">
                        <div class="form-group">
                            <label class="label" for="paymentMethod">Payment method:</label><br>
                            <nb-select formControlName="paymentMethod" id="paymentMethod" fieldSize="large" fullWidth nbInput placeholder="Select payment method">
                                <nb-option value="bank_transfer">Bank Transfer</nb-option>
                                <nb-option value="cheque">Cheque</nb-option>
                                <nb-option value="direct_deposit">Direct Deposit</nb-option>
                            </nb-select>
                        </div>
                        <div class="form-group">
                            <label class="label" for="currency">Currency:</label><br>
                            <nb-select formControlName="currency" id="currency" fieldSize="large" fullWidth nbInput placeholder="Select currency">
                                <nb-option value="USD">USD</nb-option>
                                <nb-option value="EUR">EUR</nb-option>
                                <nb-option value="TND">TND</nb-option>
                                <nb-option value="GBP">GBP</nb-option>
                                <nb-option value="JPY">JPY</nb-option>
                            </nb-select>
                        </div>
                    </div>




                    <div class="button-group">
                        <div style="flex: 1;">
                            <button nbButton nbStepperPrevious>prev</button>
                        </div>
                        <div style="flex: 1; text-align: center;">
                            <button nbButton status="primary" type="submit">Send</button>
                        </div>
                        <div style="flex: 1; text-align: right;">
                            <button nbButton nbStepperNext>Next</button>
                        </div>
                    </div>

                </form>





            </nb-step>

            <nb-step [label]="'Dividend\'s List'">
                <nb-card-header>
                    <h4>Dividend's List</h4>
                </nb-card-header>
                <nb-card-body>
                    <ng2-smart-table [settings]="settings" class="table" [source]="dividends" (custom)="onCustom($event)">
                    </ng2-smart-table>
                </nb-card-body>
                <div style="margin-top: 20px;">
                    <button nbButton nbStepperPrevious>prev</button>
                </div>
            </nb-step>

        </nb-stepper>
    </nb-card-body>
</nb-card>