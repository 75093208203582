<nb-card>
    <nb-card-header>
        <div class="row align-items-center">
            <div class="col-md-6">
                <h3>Add New Tax</h3>
            </div>
            <div class="col-md-6 text-right">
                <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/finance/taxes']" nbButton>
                    <nb-icon icon="corner-up-right-outline"></nb-icon>
                </a>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="taxForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <div align="left">
                    <label for="name" class="label">Name</label>
                </div>
                <input nbInput id="name" placeholder="Name" formControlName="name" class="form-control" nbInput fieldSize="large" fullWidth>
                <div *ngIf="taxForm.get('name').invalid && (taxForm.get('name').dirty || taxForm.get('name').touched)" class="text-danger">
                    <div *ngIf="taxForm.get('name').errors.required">Name is required.</div>
                </div>
            </div>
            <div class="form-group">
                <div align="left">
                    <label for="rate" class="label">Rate</label>
                </div>
                <input nbInput id="rate" placeholder="Rate" formControlName="rate" class="form-control" nbInput fieldSize="large" fullWidth>
                <div *ngIf="taxForm.get('rate').invalid && (taxForm.get('rate').dirty || taxForm.get('rate').touched)" class="text-danger">
                    <div *ngIf="taxForm.get('rate').errors.required">Rate is required.</div>
                </div>
            </div>
            <div class="form-group">
                <div align="left">
                    <label for="country" class="label">Country</label>
                </div>
                <input nbInput id="country" placeholder="Country" formControlName="country" class="form-control" nbInput fieldSize="large" fullWidth>
                <div *ngIf="taxForm.get('country').invalid && (taxForm.get('country').dirty || taxForm.get('country').touched)" class="text-danger">
                    <div *ngIf="taxForm.get('country').errors.required">Country is required.</div>
                </div>
            </div>
            <div class="form-group">
                <div align="left">
                    <label for="description" class="label">Description</label>
                </div>
                <input nbInput id="description" placeholder="Description" formControlName="description" class="form-control" nbInput fieldSize="large" fullWidth>
                <div *ngIf="taxForm.get('description').invalid && (taxForm.get('description').dirty || taxForm.get('description').touched)" class="text-danger">
                    <div *ngIf="taxForm.get('description').errors.required">Description is required.</div>
                </div>
            </div>
            <div class="row justify-content-center mt-4">
                <div class="col-md-12 text-center">
                    <button nbButton status="primary" class="mr-2" [disabled]="taxForm.invalid">
                        <nb-icon icon="checkmark-circle-outline"></nb-icon>Create
                    </button>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>