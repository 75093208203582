<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h3>Add provision</h3>
                    </div>
                    <div class="col-md-6 text-right">
                        <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/finance/provsionDataList']" nbButton>
                            <nb-icon icon="corner-up-right-outline"></nb-icon>
                        </a>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>
                <form [formGroup]="provisionForm" (ngSubmit)="onSubmit()">


                    <div class="form-group">
                        <div align="left">
                            <label class="label" for="description">Description:</label>
                        </div>
                        <input nbInput id="description" placeholder="Description" formControlName="description" class="form-control" fieldSize="large" fullWidth>
                        <div *ngIf="provisionForm.get('description').invalid && (provisionForm.get('description').dirty || provisionForm.get('description').touched)" class="text-danger">
                            <div *ngIf="provisionForm.get('description').errors.required">Description is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div align="left">
                            <label class="label" for="account">Account:</label>
                        </div>
                        <nb-select formControlName="account" id="account" fieldSize="large" fullWidth placeholder="Select account">
                            <nb-option *ngFor="let account of accounts" [value]="account.id">
                                {{ account.name }}
                            </nb-option>
                        </nb-select>

                        <div *ngIf="provisionForm.get('account').invalid && (provisionForm.get('account').dirty || provisionForm.get('account').touched)" class="text-danger">
                            <div *ngIf="provisionForm.get('account').errors.required">Account is required.</div>
                        </div>

                        <div class="form-group">
                            <label class="label" for="type">Type:</label><br>
                            <nb-select formControlName="type" id="type" fieldSize="large" fullWidth placeholder="Select provision type">
                                <nb-option value="PROVISION_FOR_DOUBTFUL_DEBTS">Provision for Doubtful Debts</nb-option>
                                <nb-option value=" PROVISION_FOR_STOCK_DEPRECIATION">Provision for Stock Depreciation</nb-option>
                                <nb-option value="PROVISION_FOR_LOSSES_ON_CONTRACTS">Provision for Losses on Contracts</nb-option>
                                <nb-option value="PROVISION_FOR_LITIGATION_AND_DISPUTES">Provision for Litigation and Disputes</nb-option>
                                <nb-option value="PROVISION_FOR_DEFERRED_TAXES">Provision for Deferred Taxes</nb-option>
                                <nb-option value="PROVISION_FOR_WARRANTIES">Provision for Warranties</nb-option>

                            </nb-select>
                        </div>
                        <div *ngIf="provisionForm.get('type').invalid && (provisionForm.get('type').dirty || provisionForm.get('type').touched)" class="text-danger">
                            <div *ngIf="provisionForm.get('type').errors.required">Type is required.</div>
                        </div>
                    </div>



                    <div class="form-group">
                        <div align="left">
                            <label class="label" for="amount">Amount:</label>
                        </div>
                        <input nbInput id="amount" placeholder="Amount" formControlName="amount" class="form-control" fieldSize="large" fullWidth>
                        <div *ngIf="provisionForm.get('amount').invalid && (provisionForm.get('amount').dirty || provisionForm.get('amount').touched)" class="text-danger">
                            <div *ngIf="provisionForm.get('amount').errors.required">Amount is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="label" for="currency">Currency:</label><br>
                        <nb-select formControlName="currency" id="currency" fieldSize="large" fullWidth placeholder="Select currency">
                            <nb-option value="USD">USD</nb-option>
                            <nb-option value="EUR">EUR</nb-option>
                            <nb-option value="TND">TND</nb-option>
                            <nb-option value="GBP">GBP</nb-option>
                            <nb-option value="JPY">JPY</nb-option>
                        </nb-select>
                        <div *ngIf="provisionForm.get('currency').invalid && (provisionForm.get('currency').dirty || provisionForm.get('currency').touched)" class="text-danger">
                            <div *ngIf="provisionForm.get('currency').errors.required">Currency is required.</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div align="left">
                            <label class="label" for="percentage">Percentage:</label>
                        </div>
                        <input nbInput id="percentage" placeholder="Percentage" formControlName="percentage" class="form-control" fieldSize="large" fullWidth>
                        <div *ngIf="provisionForm.get('percentage').invalid && (provisionForm.get('percentage').dirty || provisionForm.get('percentage').touched)" class="text-danger">
                            <div *ngIf="provisionForm.get('percentage').errors.required">Percentage is required.</div>
                            <div *ngIf="provisionForm.get('percentage').errors.min">Percentage must be greater than 0.</div>
                            <div *ngIf="provisionForm.get('percentage').errors.max">Percentage must be less than or equal to 100.</div>
                        </div>
                    </div>

                    <div class="row justify-content-center mt-4">
                        <div class="col-md-12 text-center">
                            <button nbButton status="primary" class="mr-2">
                                <nb-icon icon="checkmark-circle-outline"></nb-icon>Create
                            </button>
                        </div>
                    </div>
                </form>
            </nb-card-body>
        </nb-card>
    </div>
</div>