<nb-card>
  <nb-card-header>
    <h3>Usage tips</h3>
  </nb-card-header>
  <nb-card-body>
    <p>
      1-Click on a specific row to validate the entity and take its data to the next step in order to create a document
      <br>
      2-You can also add data manually
      <br>
      3-Once you click on create , a document is generated automatically
      <br>

      4-Click on the "Next" And "Prev" buttons to navigate between steps
      <br>
      5-You can view your stored documents or delete them in the list below the form

    </p>

  </nb-card-body>
</nb-card>