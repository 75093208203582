import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MoneyFlow } from '../../models/MoneyFlow';
import { environment } from '../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MoneyFlowService {

  private apiUrl = environment.financeUrl + 'api/money-flows';

  constructor(private http: HttpClient) {}

  recordMoneyFlow(moneyFlow: MoneyFlow): Observable<MoneyFlow> {
    return this.http.post<MoneyFlow>(`${this.apiUrl}/record`, moneyFlow);
  }

  updateMoneyFlow(id: string, actual: number): Observable<MoneyFlow> {
    const params = new HttpParams().set('actual', actual.toString());
    return this.http.put<MoneyFlow>(`${this.apiUrl}/update/${id}`, null, { params });
  }

  analyzeMoneyFlows(): Observable<MoneyFlow[]> {
    return this.http.get<MoneyFlow[]>(`${this.apiUrl}/analyze`);
  }
}
