import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NbWindowService, NbToastrService, NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition } from '@nebular/theme';
import { BudgetsService } from 'app/modules/financial-management/service/BudgetService/budgets.service';
import { DisabledInputComponent } from '../../../Transactions/annual-cashflow/months/Utils/Editors/disabled-input/disabled-input.component';
import { NumberTypeComponent } from '../../../Transactions/annual-cashflow/months/Utils/Editors/income-type/number-type.component';
import { SelectForExpenseTypeComponent } from '../../../Transactions/annual-cashflow/months/Utils/Editors/select-for-expense-type/select-for-expense-type.component';
import { SelectTypeComponent } from '../../../Transactions/annual-cashflow/months/Utils/Editors/select-type/select-type.component';
import { TypeTypeComponent } from '../../../Transactions/annual-cashflow/months/Utils/Editors/type-type/type-type.component';
import { TransactionService } from 'app/modules/financial-management/service/Transaction/transaction.service';
import { MoneyFlow } from 'app/modules/financial-management/models/MoneyFlow';
import { PeridicBudgetHelperComponent } from '../../util/helpers/peridic-budget-helper/peridic-budget-helper.component';
@Component({
  selector: 'ngx-periodic-budget-management',
  templateUrl: './periodic-budget-management.component.html',
  styleUrls: ['./periodic-budget-management.component.scss']
})
export class PeriodicBudgetManagementComponent {
  constructor( private ws: NbWindowService, private bs: BudgetsService, 
    private router: Router, private route: ActivatedRoute,private ts: TransactionService,
    private toastrService: NbToastrService ) { }
expenses;
incomes;

startYear: number;
endYear: number;

minDate = new Date(localStorage.getItem('startDate')).toISOString();
maxDate = new Date(localStorage.getItem('endDate')).toISOString();
from = localStorage.getItem('startDate');
to = localStorage.getItem('endDate');
source;

date: Date = new Date();
defaultYear: number = this.date.getFullYear();
  monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  defaultMonth: string = this.monthArray[this.date.getMonth()];
 






yearArray = [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010,
  2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
  2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036,
  2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049,
  2050, 2051];
yearChange (event) {

  this.router.navigateByUrl('/finance/monthlyBudget/' + this.defaultMonth + '/' + event).then(() => {});

}


filterData(): void {
  this.expenses = this.source.filter((transaction) => transaction.category === 'Expense');
  this.incomes = this.source.filter((transaction) => transaction.category === 'Income');
}



 // tables settings
 expenseSettings = {

  add: {
    addButtonContent: ' <i class="nb-plus"></i> ',
    createButtonContent: '<i class="nb-checkmark"></i>',
    cancelButtonContent: '<i class="nb-close"></i>',
    confirmCreate: true,
    inputClass: 'MoneyFlow',
  },
  edit: {
    editButtonContent: '<i class="nb-edit"></i>',
    saveButtonContent: '<i class="nb-checkmark"></i>',
    cancelButtonContent: '<i class="nb-close"></i>',
    confirmSave : true,
    inputClass: 'MoneyFlow',
  },
  delete: {
    deleteButtonContent: '<i class="nb-trash"></i>',
    confirmDelete: true,
  },

  hideSubHeader: false ,

  columns : {
    subCategory: {
      title: 'Category',
      type: 'string',
      editor: {
        type : 'custom', component: TypeTypeComponent,
      },

    },
    category: {
      title: 'Expense Type',
      type: 'html',
      // filter : true,
      editor: {type: 'custom', component: SelectForExpenseTypeComponent},
      valuePrepareFunction: (category) => {
        switch (category) {
          case 'Salaries': {
            return `<h5><span class="badge badge-success">${category}</span></h5>`;
          }
          case 'Withdrawals': {
            return `<h5><span class="badge badge-secondary">${category}</span></h5>`;
          }
          case 'Miscellaneous purchases': {
            return `<h5><span class="badge badge-warning">${category}</span></h5>`;
          }
          case 'Rent': {
            return `<h5><span class="badge badge-info">${category}</span></h5>`;
          }
          case 'Commissions': {
            return `<h5><span class="badge badge-danger">${category}</span></h5>`;
          }
          case 'Health expenses': {
            return `<h5><span class="badge badge-primary">${category}</span></h5>`;
          }
          case 'Vehicle expenses': {
            return `<h5><span class="badge badge-light">${category}</span></h5>`;
          }
          case 'Insurance': {
            return `<h5><span class="badge badge-dark">${category}</span></h5>`;
          }
          case 'Telephone and internet': {
            return `<h5><span class="badge badge-success">${category}</span></h5>`;
          }
          case 'Utilities': {
            return `<h5><span class="badge badge-secondary">${category}</span></h5>`;
          }
          case 'Advertising and marketing': {
            return `<h5><span class="badge badge-warning">${category}</span></h5>`;
          }
          case 'Consultants and professional services': {
            return `<h5><span class="badge badge-info">${category}</span></h5>`;
          }
          case 'Software and licenses': {
            return `<h5><span class="badge badge-danger">${category}</span></h5>`;
          }
          case 'Equipment maintenance': {
            return `<h5><span class="badge badge-primary">${category}</span></h5>`;
          }
          case 'Facilities repairs': {
            return `<h5><span class="badge badge-light">${category}</span></h5>`;
          }
          case 'Office supplies': {
            return `<h5><span class="badge badge-dark">${category}</span></h5>`;
          }
          case 'Training expenses': {
            return `<h5><span class="badge badge-success">${category}</span></h5>`;
          }
          case 'Business travel': {
            return `<h5><span class="badge badge-secondary">${category}</span></h5>`;
          }
          case 'Printed materials and publications': {
            return `<h5><span class="badge badge-warning">${category}</span></h5>`;
          }
          case 'Bank fees': {
            return `<h5><span class="badge badge-info">${category}</span></h5>`;
          }
          case 'Donations and contributions': {
            return `<h5><span class="badge badge-danger">${category}</span></h5>`;
          }
          case 'Taxes': {
            return `<h5><span class="badge badge-warning">${category}</span></h5>`;
          }
          case 'Total': {
            return `<h5><span class="badge badge-info">${category}</span></h5>`;
          }
          case 'Non Operating': {
            return `<h5><span class="badge badge-light">${category}</span></h5>`;
          }
        }
        


      },
    },
    actual: {
      title: 'Actual',
      type: 'number',
      addable: true,


      editor: {type: 'custom', component: NumberTypeComponent},
    },
    budget: {
      title: 'Budget',
      type: 'number',
      // filter : {type :'custom' , component:MoneyFilterComponent},
      editor: {type: 'custom', component: NumberTypeComponent},
    },
    difference: {
      title: 'Difference',
      type : 'html',
      editor: {type: 'custom', component: DisabledInputComponent},
      valuePrepareFunction: (difference) => {
        switch (true) {
          case (difference < 0): {
            return `<h5><span class="badge badge-success">${difference} </span></h5>`;
          }
          case (difference >= 0) : {
            return `<h5><span class="badge badge-danger">${difference} </span></h5>`;
          }

        }



      },
    },





  },
  pager: {
    display: true,
    perPage: 10,
  },
};
incomeSettings = {

  add: {
    addButtonContent: ' <i class="nb-plus"></i> ',
    createButtonContent: '<i class="nb-checkmark"></i>',
    cancelButtonContent: '<i class="nb-close"></i>',
    confirmCreate: true,
    inputClass: 'Transaction',
  },
  edit: {
    editButtonContent: '<i class="nb-edit"></i>',
    saveButtonContent: '<i class="nb-checkmark"></i>',
    cancelButtonContent: '<i class="nb-close"></i>',
    confirmSave : true,
    inputClass: 'Transaction',
  },
  delete: {
    deleteButtonContent: '<i class="nb-trash"></i>',
    confirmDelete: true,
  },

  hideSubHeader: false ,

  columns : {
    subCategory: {
      title: 'Category',
      type: 'string',
      editor: {
        type : 'custom', component: TypeTypeComponent,
      },

    },
    category: {
      title: 'Income Type',
      type: 'html',
      // filter : true,
      editor: {type: 'custom', component: SelectTypeComponent},
      valuePrepareFunction: (category) => {
        switch (category) {
          case 'Operating': {
            return `<h5><span class="badge badge-primary">${category} </span></h5>`;
          }
          case 'Non Operating': {
            return `<h5><span class="badge badge-info">${category} </span></h5>`;
          }
          case 'Total': {
            return `<h5><span class="badge badge-light">${category} </span></h5>`;
          }
          case 'Net Income Before Tax': {
            return `<h5><span class="badge badge-secondary">${category} </span></h5>`;
          }
          case 'Net Income': {
            return `<h5><span class="badge badge-secondary">${category} </span></h5>`;
          }
          case 'Product Sales': {
            return `<h5><span class="badge badge-success">${category}</span></h5>`;
          }
          case 'Service Sales': {
            return `<h5><span class="badge badge-warning">${category}</span></h5>`;
          }
          case 'Subscription Revenue': {
            return `<h5><span class="badge badge-info">${category}</span></h5>`;
          }
          case 'Rental Income': {
            return `<h5><span class="badge badge-primary">${category}</span></h5>`;
          }
          case 'Commission Income': {
            return `<h5><span class="badge badge-danger">${category}</span></h5>`;
          }
          case 'Licensing Revenue': {
            return `<h5><span class="badge badge-dark">${category}</span></h5>`;
          }
          case 'Investment Income': {
            return `<h5><span class="badge badge-secondary">${category}</span></h5>`;
          }
          case 'Consulting Revenue': {
            return `<h5><span class="badge badge-warning">${category}</span></h5>`;
          }
          case 'Advertising Revenue': {
            return `<h5><span class="badge badge-success">${category}</span></h5>`;
          }
          case 'Grant Income': {
            return `<h5><span class="badge badge-primary">${category}</span></h5>`;
          }
          case 'Partnership Revenue': {
            return `<h5><span class="badge badge-info">${category}</span></h5>`;
          }
          case 'Franchise Revenue': {
            return `<h5><span class="badge badge-danger">${category}</span></h5>`;
          }
          case 'Training Revenue': {
            return `<h5><span class="badge badge-dark">${category}</span></h5>`;
          }
          case 'Maintenance and Support Revenue': {
            return `<h5><span class="badge badge-success">${category}</span></h5>`;
          }
          case 'Online Sales Revenue': {
            return `<h5><span class="badge badge-warning">${category}</span></h5>`;
          }
        }
        



      },
    },
    actual: {
      title: 'Actual',
      type: 'number',
      addable: true,


      editor: {type: 'custom', component: NumberTypeComponent},
    },
    budget: {
      title: 'Budget',
      type: 'number',
      // filter : {type :'custom' , component:MoneyFilterComponent},
      editor: {type: 'custom', component: NumberTypeComponent},
    },
    difference: {
      title: 'Difference',
      type : 'html',
      editor: {type: 'custom', component: DisabledInputComponent},
      valuePrepareFunction: (difference) => {
        switch (true) {
          case (difference >= 0): {
            return `<h5><span class="badge badge-success">${difference} </span></h5>`;
          }
          case (difference < 0) : {
            return `<h5><span class="badge badge-danger">${difference} </span></h5>`;
          }

        }



      },
    },





  },
  pager : {
    display : true,
    perPage: 15,
  },
};
// toast config
destroyByClick = true;
duration = 5000;
hasIcon = true;
position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
preventDuplicates = false;
private action (state: NbComponentStatus, title: string, message: string) {
  const config = {
    status : state,
    destroyByClick: this.destroyByClick,
    duration: this.duration,
    hasIcon: this.hasIcon,
    position: this.position,
    preventDuplicates: this.preventDuplicates,
  };
  this.toastrService.show( message, title, config);
}
// end toast config
// crud
// Fonction pour afficher les notifications



afficherDonnees() {
  this.bs.getMoneyFlowsByYearRange(this.startYear, this.endYear)
    .subscribe(
      (data: MoneyFlow[]) => {
        this.expenses = data.filter(flow => flow.direction === 'Expense');
        
        this.incomes = data.filter(flow => flow.direction === 'Income');
      },
      (error) => {
        console.error('Une erreur est survenue lors de la récupération des données :', error);
      }
    );
}


 // excel export

 exportExcel () {
  const  title = 'Periodic Budget Plan ' ;
   const expense = [];
   const expenseExemple  = { description: '', type : '', actual : 0, budget: 0, difference: 0};
   this.expenses.forEach(row => {
     type ObjectKey = keyof typeof row;
     const myVar1 = 'subCategory' as ObjectKey;
     expenseExemple.description = row[myVar1];
     type ObjectKey2 = keyof typeof row;
     const myVar2 = 'category' as ObjectKey2;
     expenseExemple.type = row[myVar2];
     type ObjectKey3 = keyof typeof row;
     const myVar3 = 'actual' as ObjectKey3;
     expenseExemple.actual = row[myVar3];
     type ObjectKey4 = keyof typeof row;
     const myVar4 = 'budget' as ObjectKey4;
     expenseExemple.budget = row[myVar4];
     type ObjectKey5 = keyof typeof row;
     const myVar5 = 'difference' as ObjectKey5;
     expenseExemple.difference = row[myVar5];
     expense.push(Object.values(expenseExemple));

   });
   console.log(expense);
   const income = [];
     this.incomes.forEach(row => {
       type ObjectKey = keyof typeof row;
       const myVar1 = 'subCategory' as ObjectKey;
       expenseExemple.description = row[myVar1];
       type ObjectKey2 = keyof typeof row;
       const myVar2 = 'category' as ObjectKey2;
       expenseExemple.type = row[myVar2];
       type ObjectKey3 = keyof typeof row;
       const myVar3 = 'actual' as ObjectKey3;
       expenseExemple.actual = row[myVar3];
       type ObjectKey4 = keyof typeof row;
       const myVar4 = 'budget' as ObjectKey4;
       expenseExemple.budget = row[myVar4];
       type ObjectKey5 = keyof typeof row;
       const myVar5 = 'difference' as ObjectKey5;
       expenseExemple.difference = row[myVar5];
       income.push(Object.values(expenseExemple));
     });

     this.bs.exportExcel(title, expense, income);


 }

 openWindow() {
  this.ws.open(PeridicBudgetHelperComponent, {title: `Help`});

}

addExpense(event) {
  if (event.newData.category === 'Tax') {
    this.moneyFlow = event.newData;
    this.moneyFlow.year = this.defaultYear;
    this.moneyFlow.type = 'Monthly';
    this.moneyFlow.month = this.defaultMonth;
    this.bs.addTax(this.moneyFlow).subscribe(() => {

        this.action('success', 'Success', 'Tax Added !');
        location.reload();


      },
      () => {
        this.action('danger', 'Error', 'Please check your input !');

      });
  } else {
  this.moneyFlow = event.newData;
  this.moneyFlow.year = this.defaultYear;
  this.moneyFlow.type = 'Monthly';
  this.moneyFlow.month = this.defaultMonth;
  this.bs.addExpenseMoneyFlow(this.moneyFlow).subscribe(
    () => {


      this.action('success', 'Success', 'Budget Entry Added !');
      location.reload();


    },
    () => {
      this.action('danger', 'Error', 'Please check your input !');

    },



  ); }



}

moneyFlow: MoneyFlow = new MoneyFlow();

addIncome(event) {
  this.moneyFlow = event.newData;
  this.moneyFlow.year = this.defaultYear;
  this.moneyFlow.type = 'Yearly';
  this.moneyFlow.month = 'void';
  this.bs.addIncomeMoneyFlow(this.moneyFlow).subscribe(
    result => {

      this.action('success', 'Success', 'Budget Entry Added !');
location.reload();
    },
    () => {
      this.action('danger', 'Error', 'Please check your input !');

    },



  );



}
updateExpense(event) {
  if (event.newData.category === 'Tax') {
    this.bs.updateTax(event.newData).subscribe(() => {

        this.action('success', 'Success', 'Tax Updated !');
      //  this.redirectTo('/finance/annualBudget/' + this.defaultYear);
      window.location.reload();


      },
      () => {
        this.action('danger', 'Error', 'Please check your input !');

      }); } else {
    this.bs.updateExpense(event.newData).subscribe(
      () => {

        this.action('success', 'Success', 'Expense Updated !');
      //  this.redirectTo('/finance/annualBudget/' + this.defaultYear);

      window.location.reload();


      },
      () => {
        this.action('danger', 'Error', 'Please check your input !');

      },
    ); }
}
updateIncome(event) {
  this.bs.updateIncome(event.newData).subscribe(
    () => {

      this.action('success', 'Success', 'Income Updated !');
    //  this.redirectTo('/finance/annualBudget/' + this.defaultYear);
    window.location.reload();


    },
    () => {
      this.action('danger', 'Error', 'Please check your input !');

    },
  );
}
deleteIncome(event) {
  this.bs.deleteIncome(event.data.id).subscribe(
    () => {

      this.action('success', 'Success', 'Income Deleted !');
location.reload();
    },
    () => {
      this.action('danger', 'Error', 'Please check your input !');

    },
  );
}
deleteExpense(event) {
  if (event.data.category === 'Tax') {
    this.bs.deleteTax(event.data.id).subscribe(() => {

        this.action('success', 'Success', 'Tax Deleted !');
     //   this.redirectTo('/finance/annualBudget/' + this.defaultYear);
     window.location.reload();


      },
      () => {
        this.action('danger', 'Error', 'Please check your input !');

      });
  } else {
    this.bs.deleteExpense(event.data.id).subscribe(
      () => {

        this.action('success', 'Success', 'Expense Deleted !');
     //   this.redirectTo('/finance/annualBudget/' + this.defaultYear);

     window.location.reload();

     

      },
      () => {
        this.action('danger', 'Error', 'Please check your input !');

      },
    );
  }}




}
