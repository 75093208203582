<div class="content">
    <div class="container">
        <nb-card-header>
            <div class="row">
                <div class="col-md-3">
                    <h3>Expenses</h3>
                </div>

                <div class="col-md-9 d-flex justify-content-end">
                    <a [routerLink]="['/finance/expensesDataList']" nbButton nbTooltip="Visualize data on keypad" nbTooltipStatus="primary" status="warning">
                        <nb-icon icon="keypad-outline"></nb-icon>
                    </a>&nbsp;
                    <a [routerLink]="['/finance/expensesList']" nbButton nbTooltip="Visualize data on list" nbTooltipStatus="primary" status="basic">
                        <nb-icon icon="list-outline"></nb-icon>
                    </a>&nbsp;
                    <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" (click)="exportExcel()">
                            <i class="fas fa-file-excel text-white"></i>
                        </button>&nbsp;
                    <button [routerLink]="['/finance/addExpense']" class="mx-1" nbButton nbTooltip="Click here to create a new expense" nbTooltipStatus="primary" status="primary">
                            <nb-icon icon="plus-outline"></nb-icon> New expense
                        </button>
                </div>
            </div>
        </nb-card-header>
    </div>
    <nb-card>



        <nb-card-body>
            <ng2-smart-table [settings]="settings" [source]="expenses" class="table" (custom)="onCustom($event)"></ng2-smart-table>
        </nb-card-body>
    </nb-card>