import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { TokenStorageService } from '../../service/token/token.service';
import { NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../../environments/environment';
import { NbAuthOAuth2Token, NbAuthResult, NbAuthService, NbAuthToken } from '@nebular/auth';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginform: FormGroup;
  rememberMe: boolean = false;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  userRole;
  loginData;
  btnDis = false;
  captchaResponse: string = '';
  showPassword = false;
  recaptchaSecret: string = environment.captchaKey;
  token: NbAuthOAuth2Token;


  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private toastrService: NbToastrService,
  ) {}

  ngOnInit(): void {
    const formControls = {
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}$/u)]),
      password: new FormControl('', [Validators.required]),
      rememberMe: new FormControl(false),
    };
    this.loginform = this.fb.group(formControls);

    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberMe) {
      this.rememberMe = JSON.parse(rememberMe);
      this.loginform.patchValue({ rememberMe: this.rememberMe });
    }

    if (this.rememberMe) {
      const email = localStorage.getItem('email');
      const password = localStorage.getItem('password');
      if (email && password) {
        this.loginform.patchValue({ email, password });
        this.login();
      }
    }
  }

  get email() {
    return this.loginform.get('email');
  }

  get password() {
    return this.loginform.get('password');
  }

  login() {
    this.btnDis = true;
    setTimeout(() => {
      const { email, password, rememberMe } = this.loginform.value;

      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
        localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMe');
      }

      this.loginData = {
        email: email,
        password: password,
      };

      this.authService.login(this.loginData).subscribe({
        next: (user) => {
          this.tokenStorageService.saveToken(user.token);
          this.tokenStorageService.saveUser({
            email: user.email,
            username: user.username,
            company: user.company,
            matriculate: user.matriculate,
            role: user.roles.map((item) => item),
            id: user.id,
          });
          this.userRole = user.roles.map((item) => item.name);

          this.handleRoleBasedNavigation();
        },
        error: (error) => {
          this.btnDis = false;
          this.handleError(error);
        },
      });
    }, 3000);
  }

  handleRoleBasedNavigation() {
    if (this.userRole.includes('ROLE_FIN_MANAGER')) {
      this.showToast('Logged In', this.position, 'success');
      window.location.href = '/finance/financeInsights';
    } else {
      this.showToast('Error', this.position, 'danger');
    }
  }

  handleError(error) {
    if (error.status === 404) {
      this.showToast('This user account does not exist, please enter an existing account', this.position, 'warning');
    } else if (error.status === 401) {
      this.showToast('Invalid Credentials, please verify your password', this.position, 'warning');
    } else if (error.status === 303) {
      if (error.error.tempLocked) {
        this.showToast('Too many login attempts, your account is temporary locked. Please answer the 3 security questions to unlock', this.position, 'danger');
        this.router.navigate(['/auth/unlocking', this.email.value]);
      } else if (!error.error.accountEnabled && error.error.nonLocked) {
        this.showToast('Your account is disabled, please contact your administrator', this.position, 'danger');
      } else if (!error.error.nonLocked) {
        this.showToast('Your account is blocked, please contact Manajero administrator', this.position, 'danger');
      }
    }
  }

  onCaptchaResolved(response: string) {
    this.captchaResponse = response;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  showToast(message, position, status) {
    this.toastrService.show(status || 'Success', message, { position, status });
  }
}