import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from 'app/modules/financial-management/models/Account';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { NbToastrService } from '@nebular/theme';


@Component({
  selector: 'ngx-update-account',
  templateUrl: './update-account.component.html',
  styleUrls: ['./update-account.component.scss']
})
export class UpdateAccountComponent implements OnInit {
  accountId: string;
  account: Account;
  accountForm: FormGroup;
  formDisabled: boolean = true; 


  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private accountsService: AccountsService,
    private toastrService: NbToastrService,
    private router: Router,

  ) { 
    this.accountForm = this.fb.group({
      code: [{ value: '', disabled: this.formDisabled }, Validators.required],
      name: [{ value: 0, disabled: this.formDisabled }, Validators.required],
      createdAt: [{ value: '', disabled: this.formDisabled }, Validators.required],
      balance: [{ value: '', disabled: this.formDisabled }, Validators.required],
    });
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get('id');
    this.getAccountDetails();
  //  this.createForm();
  }

  getAccountDetails(): void {
    this.accountsService.getAccountById(this.accountId).subscribe(
      account => {
        this.account = account;
        this.populateForm();
      },
      error => {
        console.error('Error fetching account details:', error);
      }
    );
  }

  createForm(): void {
    this.accountForm = this.fb.group({
      code: ["", Validators.required],
      name: ["", Validators.required],
      createdAt: ["", Validators.required],
      balance: ["", Validators.required], 

    });
  }

  populateForm(): void {
    if (this.account) {
      this.accountForm.patchValue({
        code: this.account.code,
        name: this.account.name,
        createdAt: this.account.createdAt ,
        balance: this.account.balance 

      });
    }
  }

  onSubmit(): void {

    if (this.accountForm.invalid) {
      console.error('Form is invalid');
      return;
    }

    else if (this.accountForm.valid) {
      const updatedAccountData = this.accountForm.value;
      this.accountsService.updateAccount(this.accountId, updatedAccountData).subscribe(
        updatedAccount => {
          console.log('Account updated successfully:', updatedAccount);
          this.showToast('success', 'Success', 'Account updated successfully');
          this.router.navigate(['/finance/accountManagement']);


        },
        error => {
          console.error('Error updating account:', error);
        }
      );
    }
  }

  archiveConfirmation(): void {
    if (window.confirm('Are you sure you want to archive this account?')) {
      this.archiveAccount();
    }
}

archiveAccount(): void {
    if (this.account && this.account.id) {
        this.accountsService.archiveAccount(this.account.id).subscribe({
            next: () => {
                this.showToast('success', 'Account Archived', 'The account has been archived successfully.');
                this.router.navigate(['/finance/accountManagement']);

            },
            error: (err: any) => {
                console.error('Error archiving account:', err);
                this.showToast('danger', 'Error', 'An error occurred while archiving the account.');
            }
        });
    } else {
        console.error('Account or account id is undefined.');
        this.showToast('danger', 'Error', 'An error occurred. Please try again.');
    }
}

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }


   // Méthode pour activer tous les champs du formulaire
   enableFormFields(): void {
    this.formDisabled = false;
    Object.keys(this.accountForm.controls).forEach(key => {
      this.accountForm.controls[key].enable();
    });
  }

}