<div class="container-fluid h-100">
    <div class="row justify-content-center h-100">
        <div class="col-md-8 col-xl-6 chat">
            <div class="card">
                <div class="card-header msg_head">
                    <div class="d-flex bd-highlight">
                        <div class="img_cont">
                            <img src="assets/images/logo/Manajero-logo.png" class="rounded-circle user_img">
                            <span class="online_icon"></span>
                        </div>
                        <div class="user_info">
                            <span>Manajero ChatBot</span>
                            <p>Ask us anything!</p>
                        </div>
                    </div>
                </div>
                <div id="messageFormeight" class="card-body msg_card_body">
                    <!-- Messages will appear here -->
                    <div *ngFor="let message of messages">
                        <div *ngIf="message.type === 'user'" class="d-flex justify-content-end mb-4">
                            <div class="msg_cotainer_send">
                                {{ message.text }}
                                <span class="msg_time_send">{{ message.time }}</span>
                            </div>
                            <div class="img_cont_msg">
                                <img src="https://i.ibb.co/d5b84Xw/Untitled-design.png" class="rounded-circle user_img_msg">
                            </div>
                        </div>
                        <div *ngIf="message.type === 'bot'" class="d-flex justify-content-start mb-4">
                            <div class="img_cont_msg">
                                <img src="assets/images/logo/Manajero-logo.png" class="rounded-circle user_img_msg">
                            </div>
                            <div class="msg_cotainer">
                                {{ message.text }}
                                <span class="msg_time">{{ message.time }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <form id="messageArea" class="input-group" (submit)="sendMessage($event)">
                        <input type="text" [(ngModel)]="messageText" [ngModelOptions]="{standalone: true}" placeholder="Type your message..." autocomplete="off" class="form-control type_msg" required />
                        <div class="input-group-append">
                            <button type="submit" id="send" class="input-group-text send_btn"><i class="fas fa-location-arrow"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>