import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Asset } from '../../models/Asset';
import { environment } from '../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AssetService {

  private baseUrl = environment.financeUrl + 'api/assets';


  constructor(private http: HttpClient) { }

  getAllAssets(): Observable<Asset[]> {
    return this.http.get<Asset[]>(`${this.baseUrl}/getAllAssets`);
  }

  getAssetById(id: string): Observable<Asset> {
    return this.http.get<Asset>(`${this.baseUrl}/getAssetById/${id}`);
  }

  createAsset(asset: Asset): Observable<Asset> {
    return this.http.post<Asset>(`${this.baseUrl}/createAsset`, asset);
  }

  updateAsset(id: string, asset: Asset): Observable<Asset> {
    return this.http.put<Asset>(`${this.baseUrl}/updateAsset/${id}`, asset);
  }
  
  deleteAsset(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/deleteAsset/${id}`);
  }

  applyDepreciation(id: string, depreciationRate: number): Observable<Asset> {
    return this.http.post<Asset>(`${this.baseUrl}/depreciate/${id}?depreciationRate=${depreciationRate}`, {});
  }

  archiveAsset(assetId: string): Observable<Asset> {
    return this.http.put<Asset>(`${this.baseUrl}/${assetId}/archive`, {});
  }
  

}
