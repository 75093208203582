import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssetService } from 'app/modules/financial-management/service/AssetService/asset-service.service';
import { Asset } from 'app/modules/financial-management/models/Asset';
import { Account } from 'app/modules/financial-management/models/Account';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-add-asset',
  templateUrl: './add-asset.component.html',
  styleUrls: ['./add-asset.component.scss']
})
export class AddAssetComponent implements OnInit {
  assetForm: FormGroup;
  accounts: Account[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private assetService: AssetService,
    private accountService: AccountsService,
    private toastrService: NbToastrService

  ) {
    this.assetForm = this.fb.group({
      name: ['', Validators.required],
      initialValue: [0, Validators.required],
      currentValue: [{ value: 0, disabled: true }, Validators.required],
      purchaseDate: ['', Validators.required],
   //   depreciationDate: ['', Validators.required],
    //  depreciationRate: [0, Validators.required],
   //   archived: [false, Validators.required],
      account: [null, Validators.required]
    });

    // Set currentValue to initialValue whenever initialValue changes
    this.assetForm.get('initialValue').valueChanges.subscribe(value => {
      this.assetForm.get('currentValue').setValue(value);
    });
  }

  ngOnInit(): void {
    this.loadAccounts();
  }

  loadAccounts(): void {
    this.accountService.getAllAccounts().subscribe(
      (data: Account[]) => {
        this.accounts = data;
      },
      (error) => {
        console.error('Error loading accounts:', error);
      }
    );
  }

  onSubmit(): void {
    if (this.assetForm.valid) {
        const newAsset: Asset = this.assetForm.getRawValue(); // Get raw value to include disabled fields
        // Make sure the account object is formatted correctly
        newAsset.account = { id: this.assetForm.get('account').value };
        this.assetService.createAsset(newAsset).subscribe(
            () => {
              this.showToast('success', 'Account Created', 'The account has been created successfully.');

                this.router.navigate(['/finance/assetsList']);
            },
            (error) => {
                console.error('Error creating asset:', error);
            }
        );
    }
}


showToast(status: string, title: string, message: string) {
  this.toastrService.show(message, title, { status });
}

}
