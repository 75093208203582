import { Component, OnInit, TemplateRef } from '@angular/core';
import { Provision } from 'app/modules/financial-management/models/Provision';
import { ProvisionService } from 'app/modules/financial-management/service/Provision/provision.service';
import { ExportService } from 'app/shared/exports/export.service';
import { NbToastrConfig, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService, NbToastrService, NbWindowService } from '@nebular/theme';

@Component({
  selector: 'ngx-provision-list',
  templateUrl: './provision-list.component.html',
  styleUrls: ['./provision-list.component.scss']
})
export class ProvisionListComponent implements OnInit {
  config: NbToastrConfig;
  current: number = 1;
  search: string = '';
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';
  pageSize: number = 3;
  provisonData: Provision[] = [];

  constructor(
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private es: ExportService,
    private provisionService: ProvisionService,
  ) { }

  ngOnInit(): void {
    this.getAllProvisions();
  }

  getProvisionByType(e: any) {
    this.filterByType(e);
  }

  filterByType(e: any) {
    this.provisionService.getAllProvisions().subscribe(
      (data: Provision[]) => {
        this.provisonData = data.filter(d => d.type === e);
      }, (err) => {
        console.error(err);
      });
  }

  getArchived(e: any) {
    this.filterByArchived(e);
  }

  filterByArchived(e: any) {
    this.provisionService.getAllProvisions().subscribe(
      (data: Provision[]) => {
        this.provisonData = data.filter(d => d.archived === e);
      }, (err) => {
        console.error(err);
      });
  }

  getAllProvisions() {
    this.provisionService.getAllProvisions().subscribe((data: Provision[]) => {
      this.provisonData = data.filter(p => !p.archived);
    }, (err) => {
      console.error(err);
    });
  }


  exportExcel() {
    const title = 'Provisions list';
    const data = this.provisonData.map(p => ({
        'Account': p.account.name,
        'Description': p.description,
        'Amount': p.amount,
        'Currency': p.currency,
        'Type': p.type,
        'Percentage': p.percentage,
        'Calculated amount': p.calculation,

        

    }));
    this.es.exportExcel(title, data);
}
  

  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

  onArchiveConfirm(provision: Provision, ref: any) {
    this.provisionService.archiveProvision(provision.id).subscribe(
      () => {
        this.showToast('success', 'info', 'Operation successful!');
        this.provisonData.splice(this.provisonData.indexOf(provision), 1);
        ref.close();
      }, errorArchive => {
        console.error(errorArchive);
      });
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
