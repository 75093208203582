import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService, NbToastrConfig, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService } from '@nebular/theme';
import { Expense } from 'app/modules/financial-management/models/Expense';
import { Tax } from 'app/modules/financial-management/models/Tax';
import { TaxService } from 'app/modules/financial-management/service/Tax/tax.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-tax-data-list',
  templateUrl: './tax-data-list.component.html',
  styleUrls: ['./tax-data-list.component.scss']
})
export class TaxDataListComponent  implements OnInit {

  constructor(private taxService: TaxService ,
             private router: Router,
             private es: ExportService,
             private toastrService: NbToastrService,
             private dialogService: NbDialogService


 )  {}

taxData: Tax[] = [];
tax: Tax;
config: NbToastrConfig;
current: number = 1;
search: string = '';
index = 1;
destroyByClick = true;
duration = 2000;
hasIcon = true;
position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
preventDuplicates = false;
status: NbComponentStatus = 'primary';
pageSize: number = 3;

ngOnInit(): void {
  this.loadTaxes();
}

loadTaxes(): void {
  this.taxService.getAllTaxes().subscribe(
    (data: Tax[]) => {
      this.taxData = data.filter(data => !data.archived);
      console.log(this.taxData); 
    }, (err) => {
      return err;
    },
  );
}






exportExcel(): void {
  const title = 'Taxes';
  const tabSource = [];

  const headers = ['Name',  'Description', 'Rate', 'Country'];
  tabSource.push(headers);

  this.taxData.forEach(row => {
      tabSource.push([row.name, row.description, row.rate.toString() , row.country]);
  });

  this.es.exportExcel(title, tabSource);
}


  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }


  getArchived(isArchived: boolean) {
    this.taxService.getAllTaxes().subscribe(
      (data: Expense[]) => {
        this.taxData = data.filter(expense => expense.archived === isArchived);
      }, (err) => {
        console.error(err);
      }
    );
  }
  
  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

  onArchiveConfirm(tax: Tax, ref: any) {
    this.taxService.archiveTax(tax.id).subscribe(
      () => {
        this.showToast('success', 'info', 'Operation successful!');
        this.taxData.splice(this.taxData.indexOf(tax), 1);
        ref.close();
      }, errorArchive => {
        console.error(errorArchive);
      });
  }


}
