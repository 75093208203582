import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Provision } from 'app/modules/financial-management/models/Provision';
import { ProvisionService } from 'app/modules/financial-management/service/Provision/provision.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-provision-data-list',
  templateUrl: './provision-data-list.component.html',
  styleUrls: ['./provision-data-list.component.scss']
})
export class ProvisionDataListComponent  implements OnInit{
  provision: Provision[];
    isLoading = true;

    provisionData: Provision[] = [];
    settings = {
        hideSubHeader: false,
      
        actions: {
            add: false,
            edit: false,
            delete: false,
            custom: [
              {
                name: 'view',
                title: '<br><i  class="fas fa-eye small mt-2"></i>',
          
              }],},
        columns: {
          description: {
                title: 'Description',
                type: 'string',
            },
        
            amount: {
                title: 'Amount',
                type: 'string',
            },
            type: {
              title: 'Type',
              type: 'html',
              valuePrepareFunction: (type) => {
                const formattedType = type
                  .split('_')
                  .map(word => word.charAt(0) + word.slice(1).toLowerCase())
                  .join(' ');
      
                switch (type) {
                  case 'PROVISION_FOR_DOUBTFUL_DEBTS': return `<h5><span class="badge badge-danger">${formattedType}</span></h5>`;
                  case 'PROVISION_FOR_STOCK_DEPRECIATION': return `<h5><span class="badge badge-success">${formattedType}</span></h5>`;
                  case 'PROVISION_FOR_LOSSES_ON_CONTRACTS': return `<h5><span class="badge badge-primary">${formattedType}</span></h5>`;
                  case 'PROVISION_FOR_LITIGATION_AND_DISPUTES': return `<h5><span class="badge badge-warning">${formattedType}</span></h5>`;
                  case 'PROVISION_FOR_DEFERRED_TAXES': return `<h5><span class="badge badge-info">${formattedType}</span></h5>`;
                  default: return `<h5><span class="badge badge-secondary">${formattedType}</span></h5>`;
                }
              },

            },
            calculation: {
              title: 'Calculated provision amount',
          },

      account: { 
        title: 'Account',
        valuePrepareFunction: (cell: any, row: any) => {
          return row.account?.name ;
        }
      }
    },
    pager: {
      display: true,
      perPage: 10,
    },
    };


    constructor(
      private router: Router,
      private es: ExportService,
      private provisionService: ProvisionService
  ) { }


  ngOnInit(): void {
    this.getAllProvisions();
}

getAllProvisions() {
  this.provisionService.getAllProvisions().subscribe(
    (data: Provision[]) => {
      console.log(data); // Vérifiez ici
      this.provisionData = data.filter(entry => !entry.archived);
      console.log(this.provisionData); // Vérifiez ici aussi
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      return err;
    },
  );
}



  

exportExcel() {
  const title = 'Provisions list';
  const data = this.provisionData.map(p => ({
      'Account': p.account.name,
      'Description': p.description,
      'Amount': p.amount,
      'Currency': p.currency,
      'Type': p.type,
      'Percentage': p.percentage,
      'Calculated amount': p.calculation,

      

  }));
  this.es.exportExcel(title, data);
}



  onCustom(event): void {
    const selectedProvision: Provision = event.data;
    this.router.navigate(['/finance/updateProvision', selectedProvision.id]);
}
  }