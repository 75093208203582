import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbToastrConfig, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService, NbToastrService } from '@nebular/theme';
import { Liability } from 'app/modules/financial-management/models/Liability';
import { LiabilityService } from 'app/modules/financial-management/service/LiabilityService/liability-service.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-liabiliy-data-list',
  templateUrl: './liabiliy-data-list.component.html',
  styleUrls: ['./liabiliy-data-list.component.scss']
})
export class LiabiliyDataListComponent implements OnInit {
  config: NbToastrConfig;
  current: number = 1;
  search: string = '';
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';
  pageSize: number = 3;
  liabilityData: Liability[] = [];

  constructor(
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private es: ExportService,
    private liabilityService: LiabilityService,
  ) { }

  ngOnInit(): void {
    this.getAllLiabilities();
  }

  

  getArchived(e: any) {
    this.filterByArchived(e);
  }

  filterByArchived(e: any) {
    this.liabilityService.getAllLiabilities().subscribe(
      (data: Liability[]) => {
        this.liabilityData = data.filter(d => d.archived === e);
      }, (err) => {
        console.error(err);
      });
  }

  getAllLiabilities() {
    this.liabilityService.getAllLiabilities().subscribe((data: Liability[]) => {
      this.liabilityData = data.filter(p => !p.archived);
    }, (err) => {
      console.error(err);
    });
  }

  exportExcel() {
    const title = 'Liabilities list';
    const data = this.liabilityData.map(p => ({
        'type': p.type,
        'amountOwed': p.amountOwed,
        'interestRate': p.interestRate,
        'maturityDate': p.maturityDate,
        'repaymentTerms': p.repaymentTerms,
        'collateral': p.collateral,
        'covenants': p.covenants,
        'purpose': p.purpose,
        'creditRating': p.creditRating,
        'legalRequirements': p.legalRequirements,
        'account': p.account.name,
  
        
  
    }));
    this.es.exportExcel(title, data);
  }
  

  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

  onArchiveConfirm(liability: Liability, ref: any) {
    this.liabilityService.archiveLiability(liability.id).subscribe(
      () => {
        this.showToast('success', 'info', 'Operation successful!');
        this.liabilityData.splice(this.liabilityData.indexOf(liability), 1);
        ref.close();
      }, errorArchive => {
        console.error(errorArchive);
      });
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
