import { Component, OnInit } from '@angular/core';
import { NbToastrService, NbComponentStatus } from '@nebular/theme';
import { Liability } from 'app/modules/financial-management/models/Liability';
import { Transaction } from 'app/modules/financial-management/models/Transaction';
import { LiabilityService } from 'app/modules/financial-management/service/LiabilityService/liability-service.service';
import { TransactionService } from 'app/modules/financial-management/service/Transaction/transaction.service';

@Component({
  selector: 'ngx-liabilities-reconciliation',
  templateUrl: './liabilities-reconciliation.component.html',
  styleUrls: ['./liabilities-reconciliation.component.scss']
})
export class LiabilitiesReconciliationComponent implements OnInit {
  liabilities: Liability[] = [];
  transactions: Transaction[] = [];
  selectedLiability: Liability | null = null;
  selectedTransaction: Transaction | null = null;
  reconciledCount: number = 0;
  nonReconciledCount: number = 0;



  constructor(
    private liabilityService: LiabilityService,
    private transactionService: TransactionService,
    private toastrService: NbToastrService,

  ) { }

  ngOnInit(): void {
    this.loadNonReconciledLiabilities();
    this.loadTransactions();

  }


  

  loadNonReconciledLiabilities(): void {
    this.liabilityService.getNonReconciledLiabilities().subscribe(data => {
      this.liabilities = data;
      this.nonReconciledCount = data.length;
      this.calculateReconciledCount(); // Update reconciled count
    }, error => {
      console.error('Error loading liabilities:', error);
    });
  }

  loadTransactions(): void {
    this.transactionService.getAllTransactions().subscribe(data => {
      this.transactions = data;
    }, error => {
      console.error('Error loading transactions:', error);
    });
  }

  reconcile(): void {
    if (this.selectedLiability && this.selectedTransaction) {
      this.liabilityService.reconcileLiability(this.selectedLiability.id!, this.selectedTransaction!)
        .subscribe(() => {
          this.loadNonReconciledLiabilities(); // Refresh the list
          this.showToast('success', 'Reconciliation Successful', 'The selected liability and transaction have been reconciled.');
        }, error => {
          console.error('Error reconciling liability:', error);
          this.showToast('danger', 'Reconciliation Failed', 'An error occurred while reconciling the liability.');
        });
    }
  }

  private calculateReconciledCount(): void {
    this.reconciledCount = this.liabilities.filter(liability => liability.reconciled).length;
  }

  private showToast(status: NbComponentStatus, title: string, message: string): void {
    this.toastrService.show(message, title, { status });
  }
}
