<mat-card>
    <mat-card-title>
        <h2>2. Income Statement : </h2>
    </mat-card-title>
    <div *ngIf="transactionData && transactionData.length > 0; else noDataTemplate">
        <ng2-smart-table [settings]="incomeStatementSettings" [source]="transactionData"></ng2-smart-table>
    </div>
    <ng-template #noDataTemplate>
        <p>Aucune donnée disponible pour l'instant.</p>
    </ng-template>
</mat-card>