<nb-card>
    <nb-card-header>
        <div class="row align-items-center">
            <div class="col-md-6">
                <h3>Add New Account</h3>
            </div>
            <div class="col-md-6 text-right">
                <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/finance/accountManagement']" nbButton>
                    <nb-icon icon="corner-up-right-outline"></nb-icon>
                </a>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="accountForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <div align="left">
                    <label for="code" class="label">Code</label>
                </div>
                <input nbInput id="code" placeholder="Code" formControlName="code" class="form-control" nbInput fieldSize="large" fullWidth>
                <div *ngIf="accountForm.get('code').invalid && (accountForm.get('code').dirty || accountForm.get('code').touched)" class="text-danger">
                    <div *ngIf="accountForm.get('code').errors.required">Code is required.</div>
                </div>
            </div>
            <div class="form-group">
                <div align="left">
                    <label for="name" class="label">Name</label>
                </div>
                <input nbInput id="name" placeholder="Name" formControlName="name" class="form-control" nbInput fieldSize="large" fullWidth>
                <div *ngIf="accountForm.get('name').invalid && (accountForm.get('name').dirty || accountForm.get('name').touched)" class="text-danger">
                    <div *ngIf="accountForm.get('name').errors.required">Name is required.</div>
                </div>
            </div>
            <div class="form-group">
                <div align="left">
                    <label for="balance" class="label">Balance</label>
                </div>
                <input nbInput id="balance" placeholder="Balance" formControlName="balance" class="form-control" nbInput fieldSize="large" fullWidth>
                <div *ngIf="accountForm.get('balance').invalid && (accountForm.get('balance').dirty || accountForm.get('balance').touched)" class="text-danger">
                    <div *ngIf="accountForm.get('balance').errors.required">Balance is required.</div>
                </div>
            </div>
            <div class="form-group">
                <div align="left">
                    <label for="createdAt" class="label">Created at</label>
                </div>
                <input nbInput fullWidth id="createdAt" formControlName="createdAt" type="date" placeholder="Enter created at date">
                <div *ngIf="accountForm.get('createdAt').invalid && (accountForm.get('createdAt').dirty || accountForm.get('createdAt').touched)" class="text-danger">
                    <div *ngIf="accountForm.get('createdAt').errors.required">Created at date is required.</div>
                </div>
            </div>
            <div class="row justify-content-center mt-4">
                <div class="col-md-12 text-center">
                    <button nbButton status="primary" class="mr-2" [disabled]="accountForm.invalid">
                        <nb-icon icon="checkmark-circle-outline"></nb-icon>Create
                    </button>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>