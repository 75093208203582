import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Account } from 'app/modules/financial-management/models/Account';
import { Expense } from 'app/modules/financial-management/models/Expense';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { ExpenseService } from "app/modules/financial-management/service/Expense/expenses.service"


@Component({
  selector: 'ngx-update-expense',
  templateUrl: './update-expense.component.html',
  styleUrls: ['./update-expense.component.scss']
})
export class UpdateExpenseComponent implements OnInit {
  expenseForm: FormGroup;
  accounts: Account[];
  expenseId: string;
  expense: Expense;
  selectedAccount: Account;
  formDisabled: boolean = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private expenseService: ExpenseService,
    private accountService: AccountsService,
    private toastrService: NbToastrService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.expenseId = this.route.snapshot.paramMap.get('id');
    this.createForm();
    this.loadAccounts();
    this.getExpenseById();
  }

  createForm() {
    this.expenseForm = this.fb.group({
      description: [{ value: '', disabled: this.formDisabled }, Validators.required],
      amount: [{ value: '', disabled: this.formDisabled }, Validators.required],
      paymentMethod: [{ value: '', disabled: this.formDisabled }, Validators.required],
      date: [{ value: '', disabled: this.formDisabled }, Validators.required],
      receiptNumber: [{ value: '', disabled: this.formDisabled }, Validators.required],
      account: [{ value: null, disabled: this.formDisabled }, Validators.required]
    });
  }

  loadAccounts() {
    this.accountService.getAllAccounts().subscribe(data => {
      this.accounts = data;
    });
  }

  getExpenseById(): void {
    this.expenseService.getExpenseById(this.expenseId).subscribe(
      expense => {
        this.expense = expense;
        this.populateForm();
      },
      error => {
        console.error('Error fetching expense details:', error);
      }
    );
  }

  populateForm(): void {
    if (this.expense) {
      this.expenseForm.patchValue({
        account: this.expense.account ? this.expense.account.id : null,
        amount: this.expense.amount,
        paymentMethod: this.expense.paymentMethod,
        date: this.expense.date,
        receiptNumber: this.expense.receiptNumber,
        description: this.expense.description,
      });
    }
  }

  onSubmit(): void {
    this.enableFormFields();  // Ensure the form fields are enabled before submission

    if (this.expenseForm.invalid) {
      this.markAllAsTouched();  // Mark all fields as touched to trigger validation messages
      console.error('Form is invalid', this.expenseForm.errors, this.expenseForm.controls);  // Log the errors
      return;
    }
  
    const formValue = this.expenseForm.value;
    const updatedExpense: Expense = {
      ...formValue,
      account: this.accounts.find(account => account.id === formValue.account)
    };
  
    this.expenseService.updateExpense(this.expenseId, updatedExpense)
      .subscribe(
        (updatedExpense: Expense) => {
          console.log('Expense updated successfully:', updatedExpense);
          this.showToast('success', 'Success', 'Expense updated successfully');
          this.router.navigate(['/finance/expensesList']);
        },
        (error: any) => {
          console.error('Error updating expense:', error);
          this.showToast('danger', 'Error', 'An error occurred while updating the expense.');
        }
      );
  }
  
  archiveConfirmation(): void {
    if (window.confirm('Are you sure you want to archive this expense?')) {
      this.archiveExpense();
    }
  }

  archiveExpense(): void {
    if (this.expense && this.expense.id) {
      this.expenseService.archiveExpense(this.expense.id).subscribe({
        next: () => {
          this.showToast('success', 'Expense Archived', 'The expense has been archived successfully.');
          this.router.navigate(['/finance/expensesList']);
        },
        error: (err: any) => {
          console.error('Error archiving expense:', err);
        }
      });
    } else {
      console.error('Expense id is undefined');
    }
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  onAccountChange(accountId: string): void {
    this.selectedAccount = this.accounts.find(account => account.id === accountId);
  }


  enableFormFields(): void {
    this.formDisabled = false;
    Object.keys(this.expenseForm.controls).forEach(key => {
      this.expenseForm.controls[key].enable();
    });
  }

  markAllAsTouched(): void {
    Object.keys(this.expenseForm.controls).forEach(key => {
      this.expenseForm.controls[key].markAsTouched();
    });
  }
}

