<nb-card>
  <nb-card-header>
    <h3>Usage tips</h3>
  </nb-card-header>
  <nb-card-body>
    <p>
      1-Choose the time Periode
      <br>
      2-Click On the Excel Logo to export data 
      

    </p>

  </nb-card-body>
</nb-card>