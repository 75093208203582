import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbToastrConfig, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService, NbToastrService } from '@nebular/theme';
import { Asset } from 'app/modules/financial-management/models/Asset';
import { AssetService } from 'app/modules/financial-management/service/AssetService/asset-service.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-assets-data-list',
  templateUrl: './assets-data-list.component.html',
  styleUrls: ['./assets-data-list.component.scss']
})
export class AssetsDataListComponent implements OnInit {
  config: NbToastrConfig;
  current: number = 1;
  search: string = '';
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';
  pageSize: number = 3;
  assetsData: Asset[] = [];

  constructor(
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private es: ExportService,
    private assetService: AssetService,
  ) { }

  ngOnInit(): void {
    this.getAllAssets();
  }

  

  getArchived(e: any) {
    this.filterByArchived(e);
  }

  filterByArchived(e: any) {
    this.assetService.getAllAssets().subscribe(
      (data: Asset[]) => {
        this.assetsData = data.filter(d => d.archived === e);
      }, (err) => {
        console.error(err);
      });
  }

  getAllAssets() {
    this.assetService.getAllAssets().subscribe((data: Asset[]) => {
      this.assetsData = data.filter(p => !p.archived);
    }, (err) => {
      console.error(err);
    });
  }

  exportExcel() {
    const title = 'Assets list';
    const data = this.assetsData.map(p => ({
        'name': p.name,
        'initialValue': p.initialValue,
        'currentValue': p.currentValue,
        'purchaseDate': p.purchaseDate,
        'depreciationRate': p.depreciationRate,
        'depreciationDate': p.depreciationDate,
        'account': p.account.name,
        

    }));
    this.es.exportExcel(title, data);
}

  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

  onArchiveConfirm(asset: Asset, ref: any) {
    this.assetService.archiveAsset(asset.id).subscribe(
      () => {
        this.showToast('success', 'info', 'Operation successful!');
        this.assetsData.splice(this.assetsData.indexOf(asset), 1);
        ref.close();
      }, errorArchive => {
        console.error(errorArchive);
      });
  }z

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

}