import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Expense } from 'app/modules/financial-management/models/Expense';
import { ExpenseService } from 'app/modules/financial-management/service/Expense/expenses.service';

@Component({
  selector: 'ngx-archived-expenses-list',
  templateUrl: './archived-expenses-list.component.html',
  styleUrls: ['./archived-expenses-list.component.scss']
})
export class ArchivedExpensesListComponent implements OnInit {

  constructor(private expenseService: ExpenseService ,   private datePipe: DatePipe, private router: Router ,    private toastrService: NbToastrService,
  )  {}

  expenses: Expense[] = [];
  expenseId: string;

  settings = {
    hideSubHeader: false ,
    pager: {
      display: true,
      perPage: 10,
    },
    columns: {
      description: {
        title: 'Description'
      },
      amount: {
        title: 'Amount'
      },
      date: {
        title: 'Date'
        , valuePrepareFunction: (date) => {
          return this.datePipe.transform(date, 'dd/MM/yyyy HH:mm');
        }, 
      },
      paymentMethod: {
        title: 'Payment Method'
      },
      receiptNumber: {
        title: 'Receipt Number'
      },
      account: {
        title: 'Account',
        valuePrepareFunction: (account) => {
          return account.name;
        }
      }
    },
    actions: {
      delete: false, 
      add: false, 
      edit: false ,
      custom: [
        {
          name: 'Restore',
          title: '<i class="fas fa-history fa-xs custom-restore-icon" title="Restore this expense"></i>',
        }],
  
  
    },
    mode: 'external'
  };


  ngOnInit(): void {
    this.getAllArchivedExpenses();
  }

  getAllArchivedExpenses(): void {
    this.expenseService.getArchivedExpenses().subscribe(
      data => {
        console.log('Received archived expenses :', data);
        this.expenses = data;
      },
      error => {
        console.error('Error fetching expenses:', error);
      }
    );
  }

  // Fonction pour confirmer la restauration du expenses
  restoreConfirmation(expenseId: string): void {
    if (window.confirm('Are you sure you want to restore this expenses?')) {
      this.restoreExpense(expenseId);
    }
  }

  // Fonction pour restaurer le compte
  restoreExpense(expenseId: string): void {
    this.expenseService.archiveExpense(expenseId).subscribe({
      next: () => {
        this.showToast('success', 'Expenses Restored', 'The expenses has been restored successfully.');
        setTimeout(() => {
          window.location.reload();
        }, 1500); 
      
      },
      error: (err: any) => {
        console.error('Error archiving expenses:', err);
        this.showToast('danger', 'Error', 'An error occurred while archiving the expenses.');
      }
    });
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }


  onCustom(event): void {
    if (event.action === 'Restore') {
      this.restoreConfirmation(event.data.id);
    }
  }

  

}