<div class="content">
    <div class="container">
        <nb-card-header>
            <div class="row">
                <div class="col-md-3">
                    <h3>Accounts</h3>
                </div>
                <div class="col-md-9 d-flex justify-content-end">
                    <a [routerLink]="['/finance/accountDataList']" nbButton nbTooltip="Visualize data on keypad" nbTooltipStatus="primary" status="warning">
                        <nb-icon icon="keypad-outline"></nb-icon>
                    </a>&nbsp;
                    <a [routerLink]="['/finance/accountManagement']" nbButton nbTooltip="Visualize data on list" nbTooltipStatus="primary" status="basic">
                        <nb-icon icon="list-outline"></nb-icon>
                    </a>&nbsp;
                    <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" (click)="exportExcel()">
                        <i class="fas fa-file-excel text-white"></i>
                    </button>&nbsp;
                    <button [routerLink]="['/finance/addAccount']" class="mx-1" nbButton nbTooltip="Click here to create a new account" nbTooltipStatus="primary" status="primary">
                        <nb-icon icon="plus-outline"></nb-icon> New account
                    </button>
                </div>
            </div>
        </nb-card-header>
        <nb-card>

            <nb-card-body>
                <br />
                <div class="row">
                    <ng2-smart-table [settings]="tabSettings" (createConfirm)="onAdd($event)" (custom)="onCustom($event)" class="table" [source]="accounts">
                    </ng2-smart-table>
                </div>
            </nb-card-body>
        </nb-card>