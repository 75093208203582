<nb-card>
    <nb-card-header>
        <h3>Usage tips</h3>
    </nb-card-header>
    <nb-card-body>
        <p>
            1-See the list of tax.<br> 2-Add a new tax
            <br>3-You can also go to tax details to see the details of a specefic tax , update or archive this tax.
            <br> 4-Besides you can click on the Excel Logo to export data.


        </p>

    </nb-card-body>
</nb-card>