<nb-card>
    <nb-card-header class="row">
        <div class="col-md-10">
            <h3>Periodic budget</h3>
        </div>
        <div align="right">
            <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" (click)="exportExcel()"><i class="fas fa-file-excel text-white"></i></button>
            <button style="margin-left: 10px" nbTooltip="Click here for help" nbTooltipStatus="primary" nbButton status="basic" (click)="openWindow()"><i class="fas fa-exclamation-circle fa-lg"></i>  </button>
        </div>
    </nb-card-header>
    <nb-card-body>
        <h6> Choose Periode Interval</h6>
        <div class="row">
            <div class="col-5">
                <nb-select [(ngModel)]="startYear" name="startYear" placeholder="Please choose start year">
                    <nb-option *ngFor="let year of yearArray" [value]="year">{{year}}</nb-option>
                </nb-select>
            </div>
            <div class="col-5">
                <nb-select [(ngModel)]="endYear" name="endYear" placeholder="Please choose end year">
                    <nb-option *ngFor="let year of yearArray" [value]="year">{{year}}</nb-option>
                </nb-select>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
                <!-- Utilisation des classes d-flex, align-items-center et justify-content-center pour centrer le bouton -->
                <button nbButton status="primary" (click)="afficherDonnees()">Show data</button>
            </div>
        </div>
    </nb-card-body>
</nb-card>


<nb-card>
    <nb-card-body>
        <h5>Expenses</h5>
        <div class="row">
            <ng2-smart-table (createConfirm)="addExpense($event)" (editConfirm)="updateExpense($event)" (deleteConfirm)="deleteExpense($event)" [settings]="expenseSettings" [source]="expenses"></ng2-smart-table>
        </div>
        <h5>Income</h5>
        <div class="row">
            <ng2-smart-table (createConfirm)="addIncome($event)" (editConfirm)="updateIncome($event)" (deleteConfirm)="deleteIncome($event)" [settings]="incomeSettings" [source]="incomes"></ng2-smart-table>
        </div>
    </nb-card-body>
</nb-card>