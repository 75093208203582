<nb-card>
    <nb-card-header>
        <div class="row align-items-center">
            <div class="col-md-6">
                <h3>Add New asset</h3>
            </div>
            <div class="col-md-6 text-right">
                <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/finance/assetsList']" nbButton>
                    <nb-icon icon="corner-up-right-outline"></nb-icon>
                </a>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="assetForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="label" for="name">Name</label>
                <input nbInput fullWidth id="name" formControlName="name" placeholder="Enter asset name">
            </div>
            <div class="form-group">
                <label class="label" for="initialValue">Initial Value</label>
                <input nbInput fullWidth id="initialValue" formControlName="initialValue" type="number" placeholder="Enter initial value">
            </div>
            <div class="form-group">
                <label class="label" for="currentValue">Current Value</label>
                <input nbInput fullWidth id="currentValue" formControlName="currentValue" type="number" placeholder="Current value" [disabled]="true">
            </div>
            <div class="form-group">
                <label class="label" for="purchaseDate">Purchase Date</label>
                <input nbInput fullWidth id="purchaseDate" formControlName="purchaseDate" type="date" placeholder="Enter purchase date">
            </div>


            <div class="form-group">
                <label class="label">Account</label>
                <nb-select fullWidth id="account" formControlName="account">
                    <nb-option *ngFor="let account of accounts" [value]="account.id">{{ account.name }}</nb-option>
                </nb-select>
            </div>

            <div class="row justify-content-center mt-4">
                <div class="col-md-12 text-center">
                    <button nbButton status="primary" [disabled]="!assetForm.valid" class="mr-2">
                        <nb-icon icon="checkmark-circle-outline"></nb-icon>Create
                    </button>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>