<nb-card>
  <nb-card-header>
    <h3>Usage tips</h3>
  </nb-card-header>
  <nb-card-body>
    <p>
      1-You can reconcile transactions and their files in the tables below, with the possibility of checking stored proof files
      <br>
      2-The goal is to have an equality between both balances
      <br>
      3-To export your data to PDF or Excel files click on the icons on the top right of the interface
      

    </p>

  </nb-card-body>
</nb-card>