import { Component } from '@angular/core';

@Component({
  selector: 'ngx-peridic-budget-helper',
  templateUrl: './peridic-budget-helper.component.html',
  styleUrls: ['./peridic-budget-helper.component.scss']
})
export class PeridicBudgetHelperComponent {

}
