import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Expense } from '../../models/Expense';


@Injectable({
  providedIn: 'root'
})
export class ExpenseService {
 

  private baseUrl = environment.financeUrl + 'api/expenses';


  constructor(private http: HttpClient) { }

  getAllExpenses(): Observable<Expense[]> {
    return this.http.get<Expense[]>(`${this.baseUrl}/getAllExpenses`);
  }

  getExpenseById(id: string): Observable<Expense> {
    return this.http.get<Expense>(`${this.baseUrl}/getExpenseById/${id}`);
  }

  createExpense(expense: Expense): Observable<Expense> {
    return this.http.post<Expense>(`${this.baseUrl}/createExpense`, expense);
  }

  updateExpense(id: string, expense: Expense): Observable<Expense> {
    return this.http.put<Expense>(`${this.baseUrl}/updateExpense/${id}`, expense);
  }

  deleteExpense(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/deleteExpense/${id}`);
  }

  archiveExpense(id: string): Observable<Expense> {
    return this.http.put<Expense>(`${this.baseUrl}/archiveExpense/${id}`, {});
  }

  getArchivedExpenses(): Observable<Expense[]> {
    return this.http.get<Expense[]>(`${this.baseUrl}/getArchivedExpenses`);
  }
}
