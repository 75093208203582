import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExpenseService } from "app/modules/financial-management/service/Expense/expenses.service"
import { Expense } from 'app/modules/financial-management/models/Expense';
import { DatePipe } from '@angular/common';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-expenseslist',
  templateUrl: './expenseslist.component.html',
  styleUrls: ['./expenseslist.component.scss']
})
export class ExpenseslistComponent implements OnInit {

    constructor(private expenseService: ExpenseService ,
               private datePipe: DatePipe, 
               private router: Router,
               private es: ExportService,
   )  {}

  expenses: Expense[] = [];
  settings = {
    columns: {
      description: {
        title: 'Description'
      },
      amount: {
        title: 'Amount'
      },
      date: {
        title: 'Date'
        , valuePrepareFunction: (date) => {
          return this.datePipe.transform(date, 'dd/MM/yyyy HH:mm');
        }, 
      },
      paymentMethod: {
          title: 'Payment Method',
          type: 'html',
          valuePrepareFunction: (paymentMethod) => {
            const formattedType = paymentMethod
              .split('_')
              .map(word => word.charAt(0) + word.slice(1).toLowerCase())
              .join(' ');
  
            switch (paymentMethod) {
              case 'bank_transfer': return `<h5><span class="badge badge-danger">${formattedType}</span></h5>`;
              case 'cheque': return `<h5><span class="badge badge-success">${formattedType}</span></h5>`;
              case 'direct_deposit': return `<h5><span class="badge badge-primary">${formattedType}</span></h5>`;
      
              default: return `<h5><span class="badge badge-secondary">${formattedType}</span></h5>`;
            }
          },
        },
      
      receiptNumber: {
        title: 'Receipt Number'
      },
      account: {
        title: 'Account',
        valuePrepareFunction: (account) => {
          return account.name;
        }
      }
    },
    actions: {
      delete: false, 
      add: false, 
      edit: false ,
      custom: [
        {
          name: 'view',
          title: '<br><i  class="fas fa-eye small mt-2"></i>',
    
        }]
    },
    mode: 'external',
    pager: {
      display: true,
      perPage: 10,
    },
  };


  ngOnInit(): void {
    this.loadExpenses();
  }

  loadExpenses(): void {
    this.expenseService.getAllExpenses().subscribe(
      (data: Expense[]) => {
        this.expenses = data.filter(data => !data.archived);
        console.log(this.expenses); 
      }, (err) => {
        return err;
      },
    );
  }


  
  onCustom(event): void {
    const selectedExpense: Expense = event.data;
    this.router.navigate(['/finance/updateExpense', selectedExpense.id]);
}

  exportExcel() {
    const title = 'Expenses list';
      const data = this.expenses.map(e => ({
          'Receipt Number': e.receiptNumber,
          'Account': e.account.name,
          'Description': e.description,
          'Amount': e.amount,
          'Payment Method': e.paymentMethod,
          'Date': e.date,

          

      }));
      this.es.exportExcel(title, data);
  }
}