<nb-card>
    <nb-card-header>
        <h3>Usage tips</h3>
    </nb-card-header>
    <nb-card-body>
        <p>
            You can click on the restore button in actions to restore an element of this list. <br>


        </p>

    </nb-card-body>
</nb-card>