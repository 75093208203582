import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-type-filter',
  templateUrl: './type-filter.component.html',
  styleUrls: ['./type-filter.component.scss'],
})
export class TypeFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
