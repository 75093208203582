
export class Quote {
  id: string;
  propertyName: string;
  propertySurface: string;
  propertyPieces: number;
  propertyRooms: number;
  propertyCity: string;
  propertyCountry: string;
  propertyPrice: number;
  customerName: string;
  customerEmail: string;
  customerAddress: string;
  customerPhone: number;
  reference: string;
  quoteDate: Date;
  taxRate: number;
  servicesFees: number;
  finalPrice: number;
  image: string;
  enterpriseName: string;
  enterpriseAddress: string;
  enterpriseCity: string;
  enterprisePostalCode: number;
   signature: string;
  enterpriseTaxRegistrationNumber: string;





}
