import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Shareholder } from '../../models/Shareholder';

@Injectable({
  providedIn: 'root'
})
export class ShareholderService {

  private baseUrl = environment.financeUrl + 'api/shareholders';



  constructor(private http: HttpClient) { }

  createShareholder(shareholder: Shareholder): Observable<Shareholder> {
    return this.http.post<Shareholder>(`${this.baseUrl}/createShareholder`, shareholder);
  }

  getShareholderById(id: string): Observable<Shareholder> {
    return this.http.get<Shareholder>(`${this.baseUrl}/getShareholderById/${id}`);
  }

  getAllShareholders(): Observable<Shareholder[]> {
    return this.http.get<Shareholder[]>(`${this.baseUrl}/getAllShareholders`);
  }

  updateShareholder(id: string, shareholder: Shareholder): Observable<Shareholder> {
    return this.http.put<Shareholder>(`${this.baseUrl}/updateShareholder/${id}`, shareholder);
  }

  deleteShareholder(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/deleteShareholder/${id}`);
  }


  
  archiveShareholder(id: string): Observable<Shareholder> {
    return this.http.put<Shareholder>(`${this.baseUrl}/archive/${id}`, {});
  }

  getArchivedShareholders(): Observable<Shareholder[]> {
    return this.http.get<Shareholder[]>(`${this.baseUrl}/getArchivedShareholder`);
  }
}