import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Provision } from '../../models/Provision';
import {environment} from '../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProvisionService {

   private Url = environment.financeUrl+'api/provisions';

  constructor(private http: HttpClient) { }

  createProvision(provision: Provision): Observable<Provision> {
    return this.http.post<Provision>(`${this.Url}/createProvision`, provision);
  }

  getProvisionsByAccountId(accountId: string): Observable<Provision[]> {
    return this.http.get<Provision[]>(`${this.Url}/getProvisionsByAccountId/${accountId}`);
  }

  getProvisionById(provisionId: string): Observable<Provision> {
    return this.http.get<Provision>(`${this.Url}/getProvisionById/${provisionId}`);
  }

  updateProvision(provisionId: string, provision: Provision): Observable<Provision> {
    return this.http.put<Provision>(`${this.Url}/updateProvision/${provisionId}`, provision);
  }

  deleteProvision(provisionId: string): Observable<void> {
    return this.http.delete<void>(`${this.Url}/deleteProvision/${provisionId}`);
  }

  archiveProvision(provisionId: string): Observable<Provision> {
    return this.http.put<Provision>(`${this.Url}/archiveProvision/${provisionId}/archive`, {});
  }


  getAllProvisions(): Observable<Provision[]> {
    return this.http.get<Provision[]>(`${this.Url}/getAllProvisions`);
  }
}
