import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrConfig, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService, NbDialogService } from '@nebular/theme';
import { Expense } from 'app/modules/financial-management/models/Expense';
import { ExpenseService } from 'app/modules/financial-management/service/Expense/expenses.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-expenses-data-list',
  templateUrl: './expenses-data-list.component.html',
  styleUrls: ['./expenses-data-list.component.scss']
})
export class ExpensesDataListComponent implements OnInit {

  constructor(private expenseService: ExpenseService ,
             private router: Router,
             private es: ExportService,
             private toastrService: NbToastrService,
             private dialogService: NbDialogService


 )  {}

expensesData: Expense[] = [];
expense: Expense;
config: NbToastrConfig;
current: number = 1;
search: string = '';
index = 1;
destroyByClick = true;
duration = 2000;
hasIcon = true;
position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
preventDuplicates = false;
status: NbComponentStatus = 'primary';
pageSize: number = 3;

ngOnInit(): void {
  this.loadExpenses();
}

loadExpenses(): void {
  this.expenseService.getAllExpenses().subscribe(
    (data: Expense[]) => {
      this.expensesData = data.filter(data => !data.archived);
      console.log(this.expensesData); 
    }, (err) => {
      return err;
    },
  );
}




  getExpenseByPaymentMethod(e) {
    this.filterByPaymentMethod(e);
  }

  filterByPaymentMethod(e) {
    this.expenseService.getAllExpenses().subscribe(
      (data: Expense[]) => {
        this.expensesData = [];
        this.expensesData = data.filter(
          (d =>
              d.paymentMethod === e
          ),
        );
      }, (err) => {
        return err;
      });
  }

  exportExcel() {
    const title = 'Expenses list';
      const data = this.expensesData.map(e => ({
          'Receipt Number': e.receiptNumber,
          'Account': e.account.name,
          'Description': e.description,
          'Amount': e.amount,
          'Payment Method': e.paymentMethod,
          'Date': e.date,

          

      }));
      this.es.exportExcel(title, data);
  }


  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }


  getArchived(isArchived: boolean) {
    this.expenseService.getAllExpenses().subscribe(
      (data: Expense[]) => {
        this.expensesData = data.filter(expense => expense.archived === isArchived);
      }, (err) => {
        console.error(err);
      }
    );
  }
  
  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }


  onArchiveConfirm(expense: Expense, ref: any) {
    this.expenseService.archiveExpense(expense.id).subscribe(
      () => {
        this.showToast('success', 'info', 'Operation successful!');
        this.expensesData.splice(this.expensesData.indexOf(expense), 1);
        ref.close();
      }, errorArchive => {
        console.error(errorArchive);
      });
  }
}
