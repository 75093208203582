import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'ngx-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  messageText: string = '';
  messages: Array<{ text: string, time: string, type: string }> = [];

  private flaskUrl = 'http://localhost:5000/get';

  constructor(private http: HttpClient, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    console.log('Component initialized.');
  }

  sendMessage(event: Event) {
    event.preventDefault(); // Prevent default form submission

    const currentTime = new Date();
    const timeString = `${currentTime.getHours()}:${currentTime.getMinutes()}`;

    // Add user message to the list
    this.messages.push({ text: this.messageText, time: timeString, type: 'user' });

    // Send request to Flask server
    this.http.post<any>(this.flaskUrl, { msg: this.messageText }, { headers: { 'Content-Type': 'application/json' } })
      .subscribe(response => {
        if (response && response.response) {
          // Add bot response to the list
          this.messages.push({ text: response.response, time: timeString, type: 'bot' });
        } else if (response.error) {
          console.error('Error from Flask:', response.error);
          this.messages.push({ text: 'Error: ' + response.error, time: timeString, type: 'bot' });
        }

        this.cdr.detectChanges();
      }, error => {
        console.error('Error sending message to Flask:', error);
        this.messages.push({ text: 'Error communicating with the server.', time: timeString, type: 'bot' });
        this.cdr.detectChanges();
      });

    // Clear message text
    this.messageText = '';
  }
}
