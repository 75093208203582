import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs/dist/exceljs.min';
import * as fs from 'file-saver';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExportService {

  constructor() {
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = {Sheets: {'data': myworksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(myworkbook, {bookType: 'xlsx', type: 'array'});
    ExportService.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private static saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }


  exportExcel(title: string, data: any[]) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(title);
    worksheet.mergeCells('A1', 'G1');
    const titleRow = worksheet.getCell('D1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibre',
      size: 25,
      underline: 'single',
      bold: true,
      color: { argb: '4167B8' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
  
    worksheet.addRow([]);
  
    const header = Object.keys(data[0]);
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFA500' }, 
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' }, 
        size: 12,
      };
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
      if (worksheet.getColumn(cell.column)) {
        worksheet.getColumn(cell.column).width = Math.max(60, cell.value.toString().length + 2); 
      }
    });
  
    data.forEach(item => {
      const values = Object.values(item);
      const row = worksheet.addRow(values);
      row.eachCell(cell => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.alignment = {
          horizontal: 'center',
          vertical: 'middle',
        };
      });
    });
  
    for (let i = 0; i < worksheet.columns.length; i += 1) {
      let dataMax = 0;
      const column = worksheet.columns[i];
      for (let j = 1; j < column.values.length; j += 1) {
        const columnLength = String(column.values[j]).length;
        if (columnLength > dataMax) {
          dataMax = columnLength;
        }
      }
      column.width = dataMax < 10 ? 10 : dataMax;
    }
  
    worksheet.columns.forEach(column => {
      column.alignment = { vertical: 'middle', horizontal: 'center' };
    });
  
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${title}.xlsx`);
    });
  }
}
