import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Account } from 'app/modules/financial-management/models/Account';
import { Liability } from 'app/modules/financial-management/models/Liability';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { LiabilityService } from 'app/modules/financial-management/service/LiabilityService/liability-service.service';

@Component({
  selector: 'ngx-add-liability',
  templateUrl: './add-liability.component.html',
  styleUrls: ['./add-liability.component.scss']
})
export class AddLiabilityComponent implements OnInit {

  LiabilityForm: FormGroup;
  accounts: Account[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private liabilityService: LiabilityService,
    private toastrService: NbToastrService,
    private accountService: AccountsService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadAccounts();
  }

  createForm() {
    this.LiabilityForm = this.fb.group({
      type: ['', Validators.required],
      amountOwed: ['', Validators.required],
      interestRate: ['', Validators.required],
      maturityDate: ['', Validators.required],
      repaymentTerms: ['', Validators.required],
      collateral: ['', Validators.required],
      covenants: ['', Validators.required],
      purpose: ['', Validators.required],
      creditRating: ['', Validators.required],
      legalRequirements: ['', Validators.required],
      //archived: [false],
      account: ['', Validators.required],
    //  name: ['', Validators.required]
    });
  }

  loadAccounts() {
    this.accountService.getAllAccounts().subscribe(
      (data: Account[]) => {
        this.accounts = data;
        console.log('Accounts loaded:', this.accounts);
      },
      (error) => {
        console.error('Failed to load accounts', error);
      }
    );
  }

  onSubmit() {
    console.log('Form submitted');
    
    if (this.LiabilityForm.invalid) {
      console.error('Form is invalid', this.getFormValidationErrors());
      return;
    }

    const formValues = this.LiabilityForm.value;
    console.log('Form values:', formValues);
    
    const selectedAccountId = formValues.account;
    console.log('Selected Account ID:', selectedAccountId);

    const selectedAccount = this.accounts.find(a => a.id === selectedAccountId);
    console.log('Selected Account:', selectedAccount);

    if (!selectedAccount) {
      console.error('Selected account not found');
      return;
    }

    const newEntry: Liability = {
      ...formValues,
      account: selectedAccount
    };

    console.log('Submitting new liability:', newEntry);

    this.liabilityService.createLiability(newEntry).subscribe(
      () => {
        this.showToast('success', 'Success', 'Liability added successfully');
        this.router.navigate(['/finance/liabilityList']);
      },
      (error) => {
        console.error('Error creating Liability:', error);
      }
    );
  }

  getFormValidationErrors() {
    const errors = [];
    Object.keys(this.LiabilityForm.controls).forEach(key => {
      const controlErrors = this.LiabilityForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          errors.push({
            control: key,
            error: keyError,
            value: controlErrors[keyError]
          });
        });
      }
    });
    return errors;
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }
}
