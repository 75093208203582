import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-budgets-helper',
  templateUrl: './budgets-helper.component.html',
  styleUrls: ['./budgets-helper.component.scss'],
})
export class BudgetsHelperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
