<nb-card>
  <nb-card-header>
    <h3>Usage tips</h3>
  </nb-card-header>
  <nb-card-body>
    <p>
      1-You can choose the transaction year to display the annual cashflow statement
<br>
      2-Click on the eye icon in the actions column to display monthly transactions
      <br>

      3-You can sort the table's columns by clicking on the titles
      <br>

      4-To export your data to PDF or Excel files click on the icons on the top right of the interface
      <br>
      5-You can search using the white fields
      
    </p>
   
  </nb-card-body>
</nb-card>