import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Account } from 'app/modules/financial-management/models/Account';
import { Provision } from 'app/modules/financial-management/models/Provision';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { ProvisionService } from 'app/modules/financial-management/service/Provision/provision.service';

@Component({
  selector: 'ngx-update-provision',
  templateUrl: './update-provision.component.html',
  styleUrls: ['./update-provision.component.scss']
})
export class UpdateProvisionComponent implements OnInit {
  provisionForm: FormGroup;
  accounts: Account[];
  provisionId: string;
  provision: Provision;
  selectedAccount: Account;
  formDisabled: boolean = true;


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private provisionService: ProvisionService,
    private accountService: AccountsService,
    private toastrService: NbToastrService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.provisionId = this.route.snapshot.paramMap.get('id');
    this.createForm();
    this.loadAccounts();
    this.getProvisionById();
  }

  createForm() {
    this.provisionForm = this.fb.group({
      description: [{ value: '', disabled: this.formDisabled }, Validators.required],
      type: [{ value: '', disabled: this.formDisabled }, Validators.required],
      amount: [{ value: '', disabled: this.formDisabled }, Validators.required],
      account: [{ value: '', disabled: this.formDisabled }, Validators.required],
      archived: [false],
      currency: [{ value: '', disabled: this.formDisabled }, Validators.required],
    });
  }


  enableFormFields(): void {
    this.formDisabled = false;
    Object.keys(this.provisionForm.controls).forEach(key => {
      this.provisionForm.controls[key].enable();
    });
  }
  loadAccounts() {
    this.accountService.getAllAccounts().subscribe(data => {
      this.accounts = data;
    });
  }

  getProvisionById(): void {
    this.provisionService.getProvisionById(this.provisionId).subscribe(
      provision => {
        this.provision = provision;
        this.populateForm();
      },
      error => {
        console.error('Error fetching provision details:', error);
      }
    );
  }

  populateForm(): void {
    if (this.provision) {
      this.provisionForm.patchValue({
        account: this.provision.account ? this.provision.account.id : null,
        amount: this.provision.amount,
        type: this.provision.type,
        description: this.provision.description,
        currency: this.provision.currency,
      });
    }
  }

  onSubmit(): void {

    this.enableFormFields();  // Ensure the form fields are enabled before submission

   
    if (this.provisionForm.invalid) {
      this.markAllAsTouched();  // Mark all fields as touched to trigger validation messages
      console.error('Form is invalid', this.provisionForm.errors, this.provisionForm.controls);  // Log the errors
      return;
    }
  
    const formValue = this.provisionForm.value;
    const updatedProvision: Provision = {
      ...formValue,
      account: this.accounts.find(account => account.id === formValue.account)
    };
  
    this.provisionService.updateProvision(this.provisionId, updatedProvision)
      .subscribe(
        (updatedProvision: Provision) => {
          console.log('Provision updated successfully:', updatedProvision);
          this.showToast('success', 'Success', 'Provision updated successfully');
          this.router.navigate(['/finance/provsionDataList']);
        },
        (error: any) => {
          console.error('Error updating provision:', error);
          this.showToast('danger', 'Error', 'An error occurred while updating the provision.');
        }
      );
  }
  
  archiveConfirmation(): void {
    if (window.confirm('Are you sure you want to archive this provision?')) {
      this.archiveProvision();
    }
  }

  archiveProvision(): void {
    if (this.provision && this.provision.id) {
      this.provisionService.archiveProvision(this.provision.id).subscribe({
        next: () => {
          this.showToast('success', 'Provision Archived', 'The provision has been archived successfully.');
          this.router.navigate(['/finance/provsionDataList']);
        },
        error: (err: any) => {
          console.error('Error archiving provision:', err);
        }
      });
    } else {
      console.error('Provision id is undefined.');
      this.showToast('danger', 'Error', 'An error occurred. Please try again.');
    }
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  onTypeChange(type: string): void {
    this.provisionForm.get('type').setValue(type);
  }


  onAccountChange(accountId: string): void {
    this.selectedAccount = this.accounts.find(account => account.id === accountId);
}



markAllAsTouched(): void {
  Object.keys(this.provisionForm.controls).forEach(key => {
    this.provisionForm.controls[key].markAsTouched();
  });
}

}
