<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h3>Liability details</h3>
                    </div>
                    <div class="col-md-6 text-right">
                        <button nbTooltip="Enable fields" nbTooltipStatus="primary" shape="round" status="basic" (click)="enableFormFields()" nbButton class="icon-button mr-2">
                            <nb-icon icon="edit-outline"></nb-icon> 
              </button>
                        <button nbTooltip="Archive liability" nbTooltipStatus="primary" shape="round" status="danger" (click)="archiveConfirmation()" nbButton class="icon-button mr-2">
                <nb-icon icon="archive-outline"></nb-icon>
              </button>
                        <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/finance/liabilityList']" nbButton class="icon-button">
                            <nb-icon icon="corner-up-right-outline"></nb-icon>
                        </a>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>
                <form [formGroup]="LiabilityForm" (ngSubmit)="onSubmit()">
                    <!-- Type -->
                    <div class="form-group">
                        <label class="label" for="type">Type:</label>
                        <nb-select formControlName="type" id="type" placeholder="Select type" fullWidth>
                            <nb-option value="Current">Current</nb-option>
                            <nb-option value="Non-Current">Non-Current</nb-option>
                            <nb-option value="Equity">Equity</nb-option>
                        </nb-select>
                        <div *ngIf="LiabilityForm.get('type').invalid && LiabilityForm.get('type').touched" class="text-danger">
                            Type is required
                        </div>
                    </div>
                    <!-- Amount Owed -->
                    <div class="form-group">
                        <label class="label" for="amountOwed">Amount Owed:</label>
                        <input nbInput id="amountOwed" placeholder="Amount Owed" formControlName="amountOwed" class="form-control" fullWidth>
                        <div *ngIf="LiabilityForm.get('amountOwed').invalid && LiabilityForm.get('amountOwed').touched" class="text-danger">
                            Amount Owed is required
                        </div>
                    </div>
                    <!-- Interest Rate -->
                    <div class="form-group">
                        <label class="label" for="interestRate">Interest Rate:</label>
                        <input nbInput id="interestRate" placeholder="Interest Rate" formControlName="interestRate" class="form-control" fullWidth>
                        <div *ngIf="LiabilityForm.get('interestRate').invalid && LiabilityForm.get('interestRate').touched" class="text-danger">
                            Interest Rate is required
                        </div>
                    </div>
                    <!-- Maturity Date -->
                    <div class="form-group">
                        <label class="label" for="maturityDate">Maturity Date:</label>
                        <input nbInput id="maturityDate" type="date" placeholder="Maturity Date" formControlName="maturityDate" class="form-control" fullWidth>
                        <div *ngIf="LiabilityForm.get('maturityDate').invalid && LiabilityForm.get('maturityDate').touched" class="text-danger">
                            Maturity Date is required
                        </div>
                    </div>
                    <!-- Repayment Terms -->
                    <div class="form-group">
                        <label class="label" for="repaymentTerms">Repayment Terms:</label>
                        <input nbInput id="repaymentTerms" placeholder="Repayment Terms" formControlName="repaymentTerms" class="form-control" fullWidth>
                        <div *ngIf="LiabilityForm.get('repaymentTerms').invalid && LiabilityForm.get('repaymentTerms').touched" class="text-danger">
                            Repayment Terms are required
                        </div>
                    </div>
                    <!-- Collateral -->
                    <div class="form-group">
                        <label class="label" for="collateral">Collateral:</label>
                        <input nbInput id="collateral" placeholder="Collateral" formControlName="collateral" class="form-control" fullWidth>
                        <div *ngIf="LiabilityForm.get('collateral').invalid && LiabilityForm.get('collateral').touched" class="text-danger">
                            Collateral is required
                        </div>
                    </div>
                    <!-- Covenants -->
                    <div class="form-group">
                        <label class="label" for="covenants">Covenants:</label>
                        <input nbInput id="covenants" placeholder="Covenants" formControlName="covenants" class="form-control" fullWidth>
                        <div *ngIf="LiabilityForm.get('covenants').invalid && LiabilityForm.get('covenants').touched" class="text-danger">
                            Covenants are required
                        </div>
                    </div>
                    <!-- Purpose -->
                    <div class="form-group">
                        <label class="label" for="purpose">Purpose:</label>
                        <input nbInput id="purpose" placeholder="Purpose" formControlName="purpose" class="form-control" fullWidth>
                        <div *ngIf="LiabilityForm.get('purpose').invalid && LiabilityForm.get('purpose').touched" class="text-danger">
                            Purpose is required
                        </div>
                    </div>
                    <!-- Credit Rating -->
                    <div class="form-group">
                        <label class="label" for="creditRating">Credit Rating:</label>
                        <input nbInput id="creditRating" placeholder="Credit Rating" formControlName="creditRating" class="form-control" fullWidth>
                        <div *ngIf="LiabilityForm.get('creditRating').invalid && LiabilityForm.get('creditRating').touched" class="text-danger">
                            Credit Rating is required
                        </div>
                    </div>
                    <!-- Legal Requirements -->
                    <div class="form-group">
                        <label class="label" for="legalRequirements">Legal Requirements:</label>
                        <input nbInput id="legalRequirements" placeholder="Legal Requirements" formControlName="legalRequirements" class="form-control" fullWidth>
                        <div *ngIf="LiabilityForm.get('legalRequirements').invalid && LiabilityForm.get('legalRequirements').touched" class="text-danger">
                            Legal Requirements are required
                        </div>
                    </div>
                    <!-- Account -->
                    <div class="form-group">
                        <label class="label" for="account">Account:</label>
                        <nb-select formControlName="account" id="account" placeholder="Select account" fullWidth>
                            <nb-option *ngFor="let account of accounts" [value]="account.id">{{ account.name }}</nb-option>
                        </nb-select>
                        <div *ngIf="LiabilityForm.get('account').invalid && LiabilityForm.get('account').touched" class="text-danger">
                            Account is required
                        </div>
                    </div>
                    <div class="row justify-content-center mt-4">
                        <div class="col-md-12 text-center">
                            <button nbButton status="success" class="mr-2">
                                <nb-icon icon="checkmark-circle-outline"></nb-icon>Update
                            </button>
                        </div>
                    </div>
                </form>
            </nb-card-body>
        </nb-card>
    </div>
</div>