<nb-card>
    <nb-card-header class="header-content">
        <h3>Reconciliation of Liabilities</h3>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="col-md-12 mb-3">
                <div class="card l-bg-mauve">
                    <div class="card-statistic-3 p-4">
                        <div class="mb-4">
                            <h2 class="card-title mb-0">Statement</h2>
                        </div>
                        <div class="row align-items-center mb-2 d-flex">
                            <div class="col-12">
                                <h5 class="d-flex align-items-center mb-0">
                                    Number of Non-Reconciled Liabilities: {{ nonReconciledCount }}
                                </h5>
                                <h5 class="d-flex align-items-center mb-0">
                                    Number of Reconciled Liabilities: {{ reconciledCount }}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row justify-content-center">
            <div class="col-md-5 mb-3">
                <nb-select placeholder="Select Liability" fullWidth [(ngModel)]="selectedLiability">
                    <nb-option *ngFor="let liability of liabilities" [value]="liability">
                        {{ liability.type }} - {{ liability.account?.name }}
                    </nb-option>
                </nb-select>
            </div>

            <div class="col-md-5 mb-3">
                <nb-select placeholder="Select Transaction" fullWidth [(ngModel)]="selectedTransaction">
                    <nb-option *ngFor="let transaction of transactions" [value]="transaction">
                        {{ transaction.details }} - {{ transaction.amount }}
                    </nb-option>
                </nb-select>
            </div>

            <div class="col-md-2 mb-3 d-flex align-items-end">
                <button nbButton status="success" fullWidth (click)="reconcile()">Reconcile</button>
            </div>
        </div>
    </nb-card-body>
</nb-card>