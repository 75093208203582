<div>
    <h2>Money Flow Management</h2>

    <div>
        <h3>Record Money Flow</h3>
        <form (ngSubmit)="recordMoneyFlow()">
            <div class="form-group">
                <label for="category">Category:</label>
                <input type="text" [(ngModel)]="newMoneyFlow.category" id="category" name="category" placeholder="Category" required>
            </div>
            <div class="form-group">
                <label for="actual">Actual:</label>
                <input type="number" [(ngModel)]="newMoneyFlow.actual" id="actual" name="actual" placeholder="Actual" required>
            </div>
            <div class="form-group">
                <label for="budget">Budget:</label>
                <input type="number" [(ngModel)]="newMoneyFlow.budget" id="budget" name="budget" placeholder="Budget" required>
            </div>
            <div class="form-group">
                <label for="direction">Direction:</label>
                <input type="text" [(ngModel)]="newMoneyFlow.direction" id="direction" name="direction" placeholder="Direction" required>
            </div>
            <button type="submit">Record</button>
        </form>
    </div>

    <div>
        <h3>Money Flow Analysis</h3>
        <div class="analyze-button-container">
            <button class="analyze-button" (click)="analyzeMoneyFlows()">Analyze</button>
        </div>
        <ng2-smart-table [settings]="settings" [source]="moneyFlows">
        </ng2-smart-table>
    </div>
</div>