<nb-card>
    <nb-card-header class="row">

        <div class="col-md-10">
            <h3>{{this.monthName}}'s Transactions {{this.yeaar}}</h3>
        </div>
        <div class="col-md-2" align="right">


            <button style="margin-left: 10px" nbTooltip="Click here for help" nbTooltipStatus="primary" nbButton status="basic" (click)="openWindow()"><i class="fas fa-exclamation-circle fa-lg"></i>  </button>



        </div>
        <div class="mt-3 ml-4">



            <nb-tag status="basic" appearance="outline" text="Global" (click)=globalSelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="January" (click)=januarySelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="February" (click)=februarySelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="March" (click)=marchSelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="April" (click)=aprilSelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="May" (click)=maySelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="June" (click)=juneSelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="July" (click)=julySelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="August" (click)=augustSelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="September" (click)=septemberSelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="October" (click)=octoberSelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="November" (click)=novemberSelect()></nb-tag>
            <nb-tag status="info" appearance="outline" text="December" (click)=decemberSelect()></nb-tag>


        </div>

    </nb-card-header>
    <nb-card-body>
        <div align="left" class="mb-2">
            <nb-tag appearance="filled" text="balance : {{this.balance}}" status="basic"></nb-tag>
        </div>
        <ng2-smart-table [settings]="Settings" (createConfirm)="createTransaction($event)" (editConfirm)="upTransaction($event)" (deleteConfirm)="deleteTransaction($event)" class="table" [source]="source">
        </ng2-smart-table>
        <br>
        <br>
        <div *ngIf="filesSize< tSize">
            <nb-alert outline="danger">You have to add proof files !</nb-alert>

        </div>

    </nb-card-body>

</nb-card>
<nb-card style="align-items: center;">

    <nb-card-body>
        <h5>Fill in the form to add proofs files :</h5>
        <form>
            <label for>Transaction Date :</label>
            <br>
            <nb-select #myselect (selectedChange)="onChange($event)" id="1" fullWidth>
                <nb-option *ngFor="let date of dateList" [value]="date">{{date}}</nb-option>

            </nb-select>
            <br>
            <label for>Transaction Description :</label>
            <br>
            <nb-select id="2" fullWidth (selectedChange)="onDetailsChange($event)">
                <nb-option *ngFor="let det of detailsList" [value]="det">{{det}}</nb-option>
            </nb-select>
            <br>
            <label for="files">Proofs :</label>
            <br>
            <input nbInput id="files" (change)="selectFiles($event)" type="file" multiple />
            <br>
            <br>
            <div style="margin-left: 95px; margin-right: auto">
                <button nbButton status="primary" (click)="uploadFiles()"> Add Files</button>

            </div>
            <br>

        </form>
    </nb-card-body>

</nb-card>
<nb-card>
    <nb-card-header>
        <h5> Files list </h5>
    </nb-card-header>
    <nb-card-body>
        <div>
            <ng2-smart-table [settings]="filesTableSettings" (edit)="onDownload($event)" (delete)="deleteFile($event)" [source]="filesSource">
            </ng2-smart-table>


        </div>
    </nb-card-body>
</nb-card>
<nb-card>
    <nb-card-header>
        <h5> Balance's Evolution Chart</h5>
    </nb-card-header>
    <nb-card-body>
        <canvas id="balance" width="400px"></canvas>

    </nb-card-body>
</nb-card>