import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Account } from 'app/modules/financial-management/models/Account';
import { Provision } from 'app/modules/financial-management/models/Provision';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { ProvisionService } from 'app/modules/financial-management/service/Provision/provision.service';


@Component({
  selector: 'ngx-add-provision',
  templateUrl: './add-provision.component.html',
  styleUrls: ['./add-provision.component.scss']
})
export class AddProvisionComponent implements OnInit{

  provisionForm: FormGroup;
  provisions: Provision[];
  accounts: Account[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private provisionService: ProvisionService,
    private accountService: AccountsService,
    private toastrService: NbToastrService

  ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadAccounts();
  }



  createForm() {
    this.provisionForm = this.fb.group({
        description: ['', Validators.required],
        type: ['', Validators.required],
        amount: ['', Validators.required],
        account: ['', Validators.required],
        archived: [false],
        currency: ['', Validators.required],
        percentage: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
    });
}

 
  loadAccounts() {
    this.accountService.getAllAccounts().subscribe(data => {
      this.accounts = data;
    });
  }

  onSubmit() {
    if (this.provisionForm.invalid) {
        return;
    }

    const formValues = this.provisionForm.value;
    const selectedAccount = this.accounts.find(a => a.id === formValues.account);

    const newEntry: Provision = {
        description: formValues.description,
        type: formValues.type,
        amount: formValues.amount,
        account: selectedAccount,
        archived: formValues.archived,
        currency: formValues.currency,
        percentage: formValues.percentage,
    };

    this.provisionService.createProvision(newEntry).subscribe(() => {
        this.showToast('success', 'Success', 'Provision added successfully');
        this.router.navigate(['/finance/provsionDataList']);
    });
}

  

  
  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

}

