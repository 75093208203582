<nb-card>
    <nb-card-header>
        <h3>Usage tips</h3>
    </nb-card-header>
    <nb-card-body>
        <p>
            1-Choose the time periode (between two specifec years).
            <br> 2-Click On the Excel Logo to export data.


        </p>

    </nb-card-body>
</nb-card>