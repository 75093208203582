import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbWindowService, NbToastrService } from '@nebular/theme';
import { Shareholder } from 'app/modules/financial-management/models/Shareholder';
import { ShareholderService } from 'app/modules/financial-management/service/Shareholder/shareholder.service';
import { ArchivedHelperComponent } from '../../Utils/helper/archived-helper/archived-helper.component';

@Component({
  selector: 'ngx-archived-shareholders',
  templateUrl: './archived-shareholders.component.html',
  styleUrls: ['./archived-shareholders.component.scss']
})
export class ArchivedShareholdersComponent implements OnInit {


  constructor(
    private shareholderService: ShareholderService,
    private router: Router,
    private ws: NbWindowService,
    private toastrService: NbToastrService,
    private datePipe: DatePipe

  ) {}

  shareholders: Shareholder[];
  isLoading = true;
  shareholdertId: string;
  shareholderShareholder: Shareholder;



  shareholdersSettings = {
    hideSubHeader: false ,
    pager: {
      display: true,
      perPage: 10,
    },
    actions: { edit: false, delete: false, add: false,
      custom: [
        {
          name: 'Restore',
          title: '<i class="fas fa-history fa-xs custom-restore-icon" title="Restore this shareholder"></i>',
        }],
     },
    columns: {
      shareholderFirstName: {
            title: 'First name'
        },
        shareholderlastName: {
            title: 'Last name',
         
        },
        shareholderAdress: {
            title: 'Adress'
        },
        shareholderEmail: {
            title: 'Email'
        },
        shareholderPhoneNumber: {
            title: 'Phone number'
        },
        shareholderNumberOfActions: {
            title: 'Number of actions'
        },
      

      }
    };
 
  
  ngOnInit() {
   this.getAllArchivedShareholders();
  }

  getAllArchivedShareholders(): void {
    this.shareholderService.getArchivedShareholders().subscribe(
      data => {
        console.log('Received archived shareholders :', data);
        this.shareholders = data;
      },
      error => {
        console.error('Error fetching shareholders:', error);
      }
    );
  }

  restoreConfirmation(shareholderId: string): void {
    if (window.confirm('Are you sure you want to restore this shareholders?')) {
      this.restoreShareholder(shareholderId);
    }
  }

  restoreShareholder(shareholderId: string): void {
    this.shareholderService.archiveShareholder(shareholderId).subscribe({
      next: () => {
        this.showToast('success', 'Shareholder Restored', 'The Shareholder has been restored successfully.');
        window.location.reload();
      },
      error: (err: any) => {
        console.error('Error archiving Shareholder:', err);
        this.showToast('danger', 'Error', 'An error occurred while archiving the shareholder.');
      }
    });
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }


  onCustom(event): void {
    if (event.action === 'Restore') {
      this.restoreConfirmation(event.data.id);
    }
  }

  openWindow() {
    this.ws.open(ArchivedHelperComponent, {title: `Help`});
  
  }

}
