import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-reconciliation-helper',
  templateUrl: './reconciliation-helper.component.html',
  styleUrls: ['./reconciliation-helper.component.scss'],
})
export class ReconciliationHelperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
