export class PurchaseOrders {
     id?: string;
    reference?: string;
    orderDate?: Date ;
    propertyName?: string ;
    propertySurface?: string ;
    propertyPieces?: string;
    propertyRooms?: number;
    propertyCity?: string;
    propertyCountry?: string;
    customerName?: string;
    customerEmail?: string;
    customerAddress?: string;
    customerPhone?: string;
    deliveryDate?: Date;
    propertyPrice?: number;
    taxRate?: number;
    servicesFees?: number;
    finalPrice?: number;
    advancePayment?: number;
    paymentMode?: string;
    paymentMethod?: string;
    numberOfMonths?: number;
    instalmentAmount?: number;
    dueDate?: Date;
    image?: String;
  enterpriseName?: String;
  enterpriseAddress?: String;
  enterpriseCity?: String;
  enterprisePostalCode?: number;
  signature?: string;
  enterpriseTaxRegistrationNumber?: string;




}
