import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormGroup, FormBuilder, Validators ,FormControl } from '@angular/forms';
import { NbToastrService, NbStepperComponent, NbWindowService } from '@nebular/theme';
import { Shareholder } from 'app/modules/financial-management/models/Shareholder';
import { Dividend } from 'app/modules/financial-management/models/Dividend';
import { DividendService } from 'app/modules/financial-management/service/Dividend/dividend.service';
import { ShareholderService } from 'app/modules/financial-management/service/Shareholder/shareholder.service';
import { Observable } from 'rxjs';
import { ExportService } from 'app/shared/exports/export.service';
import { Router } from '@angular/router';
import { DividendHelperComponent } from '../dividend-helper/dividend-helper.component';

@Component({
  selector: 'ngx-dividend-prcocess-management',
  templateUrl: './dividend-prcocess-management.component.html',
  styleUrls: ['./dividend-prcocess-management.component.scss']
})
export class DividendPrcocessManagementComponent implements OnInit {

  dividendGroup: FormGroup;
  shareholder: Shareholder ;
  shareholderGroup: FormGroup = new FormGroup({});
  dividend: Dividend ;
  dividends: Dividend[] = [];
  shareholders: Shareholder[];
  @ViewChild('stepper', { static: true }) stepper: NbStepperComponent;


  settings = {
    actions: { edit: false, delete: false, add: false,
      custom: [
        {
          name: 'view',
          title: '<i class="fas fa-eye small mt-2"></i>',
        }],
    },
    columns: {
      amount: { title: 'Amount' },
      currency: {
        title: 'Currency',
        type: 'html',
        valuePrepareFunction: (status) => {
          switch (status) {
            case 'USD': return `<h5><span class="badge badge-danger">${status}</span></h5>`;
            case 'EUR': return `<h5><span class="badge badge-success">${status}</span></h5>`;
            case 'GBP': return `<h5><span class="badge badge-primary">${status}</span></h5>`;
            case 'TND': return `<h5><span class="badge badge-warning">${status}</span></h5>`;
            case 'JPY': return `<h5><span class="badge badge-info">${status}</span></h5>`;
            default: return `<h5><span class="badge badge-secondary">${status}</span></h5>`;
          }
        },
      },
      shareholderr: {
        title: 'Shareholder FullName',
        valuePrepareFunction: (cell: any, row: any) => {
          if (row.shareholderr) {
            const firstName = row.shareholderr.shareholderFirstName || '';
            const lastName = row.shareholderr.shareholderlastName || '';
            return `${firstName} ${lastName}`.trim() || 'N/A';
          } else {
            return 'N/A';
          }
        },
      },
      shareholderNumberOfActions: { title: 'Shareholder Number Of Actions (%)' },
      shareholderPercentage: {
        title: 'Shareholder Percentage',
        valuePrepareFunction: (cell: any, row: any) => {
          return row.shareholderr?.shareholderPercentage ? `${row.shareholderr.shareholderPercentage.toFixed(3)}` : 'N/A';
        }
      }
    },
    pager: {
      display: true,
      perPage: 10,
    },
  };
  

    shareholdersSettings = {
      actions: { edit: false, delete: false, add: false,
        custom: [
          {
            name: 'view',
            title: '<i  class="fas fa-eye small mt-2"></i>',
  
          }],
       },
      columns: {
        shareholderFirstName: {
              title: 'First name'
          },
          shareholderlastName: {
              title: 'Last name',
           
          },
          shareholderAdress: {
              title: 'Adress'
          },
          shareholderEmail: {
              title: 'Email'
          },
          shareholderPhoneNumber: {
              title: 'Phone number'
          },
          shareholderNumberOfActions: {
              title: 'Number of actions'
          },
        
  
        },
        pager: {
          display: true,
          perPage: 10,
        },
      };



  constructor(
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private dividendService: DividendService,
    private shareholderService: ShareholderService,
    private ws: NbWindowService,
    private es:ExportService,
    private router: Router,

  ) {}


  
  ngOnInit(): void {
    this.loadShareholders();
    this.loadDividends();

    


    this.dividendGroup = this.fb.group({
     amount: ['', Validators.required ],
      paymentDate: ['', Validators.required],
      frequency: ['', Validators.required],
      status: ['', Validators.required],
      paymentMethod: ['', Validators.required],
      currency: ['', Validators.required],
      shareholderr: ['', Validators.required],
      shareholderId: ['', Validators.required] 



    });

    


       this.dividendGroup.get('shareholderr').valueChanges.subscribe(selectedShareholder => {
        if (selectedShareholder) {
          const selectedShareholderId = selectedShareholder.id;
          console.log('ID du actionnaire sélectionné :', selectedShareholderId);
        }
      });
    

      this.shareholderGroup = this.fb.group({
        shareholderFirstName: ['', Validators.required],
        shareholderlastName: ['', Validators.required],
        shareholderAdress: ['', Validators.required],
        shareholderEmail: ['', [Validators.required, Validators.email]],
        shareholderPhoneNumber: ['', Validators.required],
        shareholderNumberOfActions: ['', Validators.required],
      });
  }

  goForward(event: any): void {
    this.stepper.next();
  }
  
  
//cette fonction permet de calculer le pourcentage d'actionnaire à partir de divident
  calculateShareholderPercentage(dividendAmount: number, numberOfActions: number): number {
    return (dividendAmount / numberOfActions) * 100;
  }
  

  loadDividends(): void {
    this.dividendService.getAllDividends().subscribe(
      (data) => {
        this.dividends = data
          .filter(dividend => !dividend.archived) // Filtrer les dividendes non archivés
          .map(dividend => {
            const shareholder = dividend.shareholderr;
            if (shareholder && shareholder.shareholderNumberOfActions !== null && shareholder.shareholderNumberOfActions !== undefined) {
              const percentage = this.calculateShareholderPercentage(dividend.amount, shareholder.shareholderNumberOfActions);
              return {
                ...dividend,
                shareholderNumberOfActions: shareholder.shareholderNumberOfActions,
                ShareholderPercentage: percentage
              };
            }
          });
      },
      (error) => {
        console.error('Failed to load dividends:', error);
      }
    );
  }

  loadShareholders(): void {
    this.shareholderService.getAllShareholders().subscribe(
      (data: Shareholder[]) => {
        this.shareholders = [] ;
        for (let p = 0; p < data.length; p++) {
          if (data[p].archived === false) {
            this.shareholders.push(data[p]);
          }
        }
      
    }, (err) => {
      return err;
    },
  );
}





submitDividend() {
  const selectedShareholderId = this.dividendGroup.get('shareholderId').value;
  
  this.getShareholderById(selectedShareholderId).subscribe(
    (selectedShareholder) => {
      const dividendData = {
        amount: this.dividendGroup.get('amount').value,
        paymentDate: this.dividendGroup.get('paymentDate').value,
        frequency: this.dividendGroup.get('frequency').value,
        status: this.dividendGroup.get('status').value,
        paymentMethod: this.dividendGroup.get('paymentMethod').value,
        currency: this.dividendGroup.get('currency').value,
        shareholderr: selectedShareholder 
      };

      this.dividendService.saveDividend(dividendData).subscribe(
        (response) => {
          this.toastrService.success('Dividend added successfully', 'Success');
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        },
        (error) => {
          this.toastrService.danger('Failed to add dividend', 'Error');
          console.error(error);
        }
      );
    },
    (error) => {
      console.error('Failed to retrieve shareholder details:', error);
    }
  );
}

  getShareholderById(shareholderId: string): Observable<Shareholder> {
    return this.shareholderService.getShareholderById(shareholderId);
  }
  
  submitShareholder(): void {
    if (this.shareholderGroup.invalid) {
      return;
    }
    const shareholderData = this.shareholderGroup.value as Shareholder;
    console.log("Données de l'actionnaire avant l'ajout : ", shareholderData);
    this.shareholderService.createShareholder(shareholderData).subscribe(
      (response) => {
        this.toastrService.success('Shareholder added successfully', 'Success');
        console.log("Données de l'actionnaire après l'ajout : ", shareholderData);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      (error) => {
        this.toastrService.danger('Failed to add shareholder', 'Error');
        console.error(error);
      }
    );
  }
  

  openWindow() {
    this.ws.open(DividendHelperComponent, {title: `Help`});

  }
  exportExcel(): void {
    const title = 'Dividends list';
    const data = this.dividends.map(d => ({
      'Amount': d.amount,
      'Payment date': d.paymentDate,
      'Frequency': d.frequency,
      'Status': d.status,
      'Payment method': d.paymentMethod, 
      'Currency': d.currency, 
      'Shareholder fullName': d.shareholderr.shareholderFirstName+d.shareholderr.shareholderlastName 
    }));
    this.es.exportExcel(title, data)
  }
  


  onCustom(event): void {
    const selectedDividend: Dividend = event.data;
      console.log('Selected Dividend ID:', selectedDividend.id);
  this.router.navigate(['/finance/dividendDetails', selectedDividend.id]);

}

onCustomShareholders(event): void {
  const selectedShareholder: Shareholder = event.data;
    console.log('Selected Shareholder ID:', selectedShareholder.id);
this.router.navigate(['/finance/sharehlderDetails', selectedShareholder.id]);

}



showToast(status: string, title: string, message: string) {
  this.toastrService.show(message, title, { status });
}


}
