import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Asset } from 'app/modules/financial-management/models/Asset';
import { Liability } from 'app/modules/financial-management/models/Liability';
import { LiabilityService } from 'app/modules/financial-management/service/LiabilityService/liability-service.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-archived-liability-list',
  templateUrl: './archived-liability-list.component.html',
  styleUrls: ['./archived-liability-list.component.scss']
})
export class ArchivedLiabilityListComponent implements OnInit {
  liability: Liability[];
  isLoading = true;

  liabilitiesData: Liability[] = [];
  settings = {
    hideSubHeader: false,
  
    actions: {
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: 'restore', 
            title: '<i class="fas fa-history fa-xs custom-restore-icon" title="Restore this asset"></i>',
          },
        ],
      },
    columns: {
      type: {
            title: 'Type',
            type: 'string',
        },
    
        amountOwed: {
            title: 'Amount',
            type: 'string',
        },

        purpose: {
          title: 'Purpose',
          type: 'string',
      },
      

  account: { 
    title: 'Account',
    valuePrepareFunction: (cell: any, row: any) => {
      return row.account?.name ;
    }
  }
},
pager: {
  display: true,
  perPage: 10,
},
};

  constructor(
    private router: Router,
    private es: ExportService,
    private liabilityService: LiabilityService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllArchivedLiabilities();
  }

  getAllArchivedLiabilities() {
    this.liabilityService.getAllLiabilities().subscribe(
      (data: Asset[]) => {
        console.log(data); // Vérifiez ici
        this.liabilitiesData = data.filter(l => l.archived);
        console.log(this.liabilitiesData); // Vérifiez ici aussi
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        return err;
      },
    );
  }

  restoreConfirmation(liabilityId: string): void {
    if (window.confirm('Are you sure you want to restore this liability?')) {
      this.restoreALiability(liabilityId);
    }
  }

  restoreALiability(liabilityId: string): void {
    this.liabilityService.archiveLiability(liabilityId).subscribe({
      next: () => {
        this.showToast('success', 'Liability Restored', 'The liability has been restored successfully.');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      error: (err: any) => {
        console.error('Error archiving liability:', err);
        this.showToast('danger', 'Error', 'An error occurred while archiving the liability.');
      }
    });
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  onCustom(event): void {
    if (event.action === 'restore') { 
      this.restoreConfirmation(event.data.id);
    }
  }
}
