<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h3>Shareholder details</h3>
                    </div>
                    <div class="col-md-6 text-right">
                        <button nbTooltip="Enable fields" nbTooltipStatus="primary" shape="round" status="basic" (click)="enableFormFields()" nbButton class="icon-button mr-2">
                            <nb-icon icon="edit-outline"></nb-icon> 
                        </button>
                        <button nbTooltip="Archive Shareholder" nbTooltipStatus="primary" shape="round" status="danger" (click)="archiveShareholderConfirmation()" nbButton class="icon-button mr-2">
                <nb-icon icon="archive-outline"></nb-icon>
              </button>
                        <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/finance/dividentProcess']" nbButton>
                            <nb-icon icon="corner-up-right-outline"></nb-icon>
                        </a>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>
                <form [formGroup]="shareholderForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div align="left">
                            <label for="shareholderFirstName" class="label">First name</label>
                        </div>
                        <input nbInput id="shareholderFirstName" placeholder="First name" formControlName="shareholderFirstName" class="form-control" fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group">
                        <div align="left">
                            <label for="shareholderlastName" class="label">Last name</label>
                        </div>
                        <input nbInput id="shareholderlastName" placeholder="Last name" formControlName="shareholderlastName" class="form-control" fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group">
                        <div align="left">
                            <label for="shareholderAdress" class="label">Address</label>
                        </div>
                        <input nbInput id="shareholderAdress" placeholder="shareholder Adress" formControlName="shareholderAdress" class="form-control" fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group">
                        <div align="left">
                            <label for="shareholderEmail" class="label">Email</label>
                        </div>
                        <input nbInput id="shareholderEmail" placeholder="Email" formControlName="shareholderEmail" class="form-control" fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group">
                        <div align="left">
                            <label for="shareholderPhoneNumber" class="label">Phone number</label>
                        </div>
                        <input nbInput id="shareholderPhoneNumber" placeholder="Phone number" formControlName="shareholderPhoneNumber" class="form-control" fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group">
                        <div align="left">
                            <label for="shareholderNumberOfActions" class="label">Number of actions (%)</label>
                        </div>
                        <input nbInput id="shareholderNumberOfActions" placeholder="Number of actions (%)" formControlName="shareholderNumberOfActions" class="form-control" fieldSize="large" fullWidth>
                    </div>

                    <div class="row justify-content-center mt-4">
                        <div class="col-md-12 text-center">
                            <button nbButton status="success" class="mr-2">
                                <nb-icon icon="checkmark-circle-outline"></nb-icon>Update
                            </button>
                        </div>
                    </div>
                </form>
            </nb-card-body>
        </nb-card>
    </div>
</div>