<nb-card>
    <nb-card-header>
        <div class="row">
            <div class="col-md-10">
                <h3> All transactions history</h3>

            </div>
            <div class="col-md-2" align="right">


            </div>

        </div>


    </nb-card-header>
    <nb-card-body>
        <ng2-smart-table [settings]="settings" class="table" [source]="source">
        </ng2-smart-table>

    </nb-card-body>
</nb-card>