import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Shareholder } from 'app/modules/financial-management/models/Shareholder';
import { ShareholderService } from 'app/modules/financial-management/service/Shareholder/shareholder.service';

@Component({
  selector: 'ngx-sharehlder-details',
  templateUrl: './sharehlder-details.component.html',
  styleUrls: ['./sharehlder-details.component.scss']
})
export class SharehlderDetailsComponent implements OnInit{

  shareholderId: string;
  shareholder: Shareholder;
  shareholderForm: FormGroup;
  shareholders: Shareholder[];
  formDisabled: boolean = true;


  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private shareholderService: ShareholderService,
    private toastrService: NbToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.shareholderId = this.route.snapshot.paramMap.get('id');
    this.createForm();
    this.geshareholdersDetails();
  }

  createForm(): void {
    this.shareholderForm = this.formBuilder.group({
      shareholderFirstName: [{ value: '', disabled: this.formDisabled }, Validators.required],
      shareholderlastName: [{ value: '', disabled: this.formDisabled }, Validators.required],
      shareholderAdress: [{ value: '', disabled: this.formDisabled }, Validators.required],
      shareholderEmail: [{ value: '', disabled: this.formDisabled }, Validators.required],
      shareholderPhoneNumber: [{ value: '', disabled: this.formDisabled }, Validators.required],
      shareholderNumberOfActions: [{ value: '', disabled: this.formDisabled }, Validators.required],
    });
  }

  getShareholders(): void {
    this.shareholderService.getAllShareholders().subscribe(
      shareholders => {
        this.shareholders = shareholders;
      },
      error => {
        console.error('Error fetching shareholders:', error);
      }
    );
  }

  geshareholdersDetails(): void {
    this.shareholderService.getShareholderById(this.shareholderId).subscribe(
      shareholder => {
        this.shareholder = shareholder;
        this.populateForm();
      },
      error => {
        console.error('Error fetching shareholder details:', error);
      }
    );
  }

  populateForm(): void {
    if (this.shareholder) {
      this.shareholderForm.patchValue({
        shareholderFirstName: this.shareholder.shareholderFirstName,
        shareholderlastName: this.shareholder.shareholderlastName,
        shareholderAdress: this.shareholder.shareholderAdress,
        shareholderEmail: this.shareholder.shareholderEmail,
        shareholderPhoneNumber: this.shareholder.shareholderPhoneNumber,
        shareholderNumberOfActions: this.shareholder.shareholderNumberOfActions,
      });
    }
  }

 

  onSubmit(): void {

    this.enableFormFields(); 

    if (this.shareholderForm.invalid) {
      this.markAllAsTouched();  
      console.error('Form is invalid', this.shareholderForm.errors, this.shareholderForm.controls);  
      return;
    }
    if (this.shareholderForm.valid) {
      const updatedShareholderData = this.shareholderForm.value;
      this.shareholderService.updateShareholder(this.shareholderId, updatedShareholderData).subscribe(
        updatedShareholderData => {
          console.log('Shareholder updated successfully:', updatedShareholderData);
          this.showToast('success', 'Success', 'Shareholder updated successfully');
          this.router.navigate(['/finance/dividentProcess']);
        },
        error => {
          console.error('Error updating Shareholder:', error);
        }
      );
    }
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }


  archiveShareholderConfirmation(): void {
    if (window.confirm('Are you sure you want to archive this shareholder?')) {
      this.archiveShareholder();
    }
  }
  
  archiveShareholder(): void {
    if (this.shareholder && this.shareholder.id) {
        this.shareholderService.archiveShareholder(this.shareholder.id).subscribe({
            next: () => {
                this.showToast('success', 'shareholder Archived', 'The shareholder has been archived successfully.');
                this.router.navigate(['/finance/dividentProcess']);
  
            },
            error: (err: any) => {
                console.error('Error archiving shareholder:', err);
                this.showToast('danger', 'Error', 'An error occurred while archiving the shareholder.');
            }
        });
    } else {
        console.error('shareholder or shareholder id is undefined.');
        this.showToast('danger', 'Error', 'An error occurred. Please try again.');
    }
  }

  enableFormFields(): void {
    this.formDisabled = false;
    Object.keys(this.shareholderForm.controls).forEach(key => {
      this.shareholderForm.controls[key].enable();
    });
  }

  markAllAsTouched(): void {
    Object.keys(this.shareholderForm.controls).forEach(key => {
      this.shareholderForm.controls[key].markAsTouched();
    });
  }
  
}
