<div class="content">
    <div class="container">
        <nb-card-header>
            <div class="row">
                <div class="col-md-3">
                    <h3>Taxes</h3>
                </div>

                <div class="col-md-9 d-flex justify-content-end">
                    <a [routerLink]="['/finance/taxDataList']" nbButton nbTooltip="Visualize data on keypad" nbTooltipStatus="primary" status="warning">
                        <nb-icon icon="keypad-outline"></nb-icon>
                    </a>&nbsp;
                    <a [routerLink]="['/finance/taxes']" nbButton nbTooltip="Visualize data on list" nbTooltipStatus="primary" status="basic">
                        <nb-icon icon="list-outline"></nb-icon>
                    </a>&nbsp;
                    <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" (click)="exportExcel()">
                            <i class="fas fa-file-excel text-white"></i>
                        </button>&nbsp;
                    <button [routerLink]="['/finance/addTax']" class="mx-1" nbButton nbTooltip="Click here to create a new tax" nbTooltipStatus="primary" status="primary">
                            <nb-icon icon="plus-outline"></nb-icon> New tax
                        </button>
                </div>
            </div>
        </nb-card-header>
        <nb-card>
            <nb-card>

                <nb-card-body>
                    <br />

                    <ng2-smart-table [settings]="settings" class="table" (createConfirm)="onCreateConfirm($event)" (custom)="onCustom($event)" [source]="taxes">
                    </ng2-smart-table>
                </nb-card-body>
            </nb-card>