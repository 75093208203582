<nb-card >
  <nb-card-header>
    <div class="row">
      <div class="col-md-5 col-sm-3">     
        <h3> Product Sales Process</h3>
      </div>
      <div class="col-md-7 col-sm-12 " style="text-align: right;">
    <button style="margin-left: 10px" nbTooltip="Click here for help" nbTooltipStatus="primary" nbButton status="basic" (click) ="openWindow()"><i class="fas fa-exclamation-circle fa-lg"></i>  </button>
    </div>
    </div>
  </nb-card-header>
  
  <nb-card-body>
    <nb-stepper orientation="horizontal" #stepper>
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Quotes</ng-template>
        <h4 >Generate Quote</h4>
        <nb-card   >
          <nb-card-body>
            <h5 style ="color: #3464fb ">
              Fill the form with the quote's information :
            </h5>
            <form  [formGroup]="quoteGroup" >
              <br>
              <br>
              <h5> Product's Information:</h5>
              <label for= "PropertyName">Quantity:</label>
              <input [(ngModel)]="productQuote.quantity"
                     formControlName="quantity" fullWidth
                     id="PropertyName"
                     name="PropertyName" nbInput placeholder="Quantity"
                     type="number" >
              <br>
              <label for="surface">Unit Price:</label>
              <input [(ngModel)]="productQuote.unitPrice"  fieldSize="large"  fullWidth
                     id="surface" formControlName="unitPrice"
                     nbInput placeholder="Unit Price"
                     type="number"  >
              <br>
              <div class="feedback" *ngIf="quoteGroup['controls']['unitPrice'].errors?.min">This value should be positive.</div>

              <label for="pieces">Product Name :</label>

              <input [(ngModel)]="productQuote.productName"  fieldSize="large" fullWidth
                     id="pieces" formControlName="productName"
                     nbInput placeholder="Product Name"
                     type="text" >
              <br>
              <br>
              <br>
              
              <h5> Customer's Information:</h5>

              <label for="cName">Customer's name:</label>

              <input [(ngModel)]="productQuote.customerName" fieldSize="large"  fullWidth
                     id="cName" formControlName="customerName"
                     nbInput placeholder="Customer's Name"  type="text" >
              <br>
              <label for="cEmail">Customer's Email:</label>

              <input  [(ngModel)]="productQuote.customerEmail" formControlName="customerEmail" fieldSize="large"  fullWidth
                      id="cEmail"
                      nbInput placeholder="Customer's Email" type="text" >
              <br>
              <label for="cAddress">Customer's address:</label>

              <input [(ngModel)]="productQuote.customerAddress" formControlName="customerAddress" fieldSize="large"  fullWidth
                     id="cAddress"
                     nbInput placeholder="Customer's Address" type="text" >
              <br>
              <label for="cPhone">Customer's Phone:</label>

              <input [(ngModel)]="productQuote.customerPhone" formControlName="customerPhone" fieldSize="large" fullWidth
                     id="cPhone"
                     nbInput placeholder="Customer's Phone"  type="text" >
              <br>
              <br>
              <br>

              <h5> Quote's Information:</h5>

              <label for="qDate">Quote's Date:</label>

              <input  [(ngModel)]="productQuote.creationDate" formControlName="creationDate" nbInput id ="qDate" fieldSize="large" placeholder="Quote's Date" fullWidth [nbDatepicker]="dateTimePicker">
              <nb-datepicker withSeconds #dateTimePicker></nb-datepicker>

              <br>

              <label for="tax">Tax Rate:</label>
              <input [(ngModel)]="productQuote.taxRate" fieldSize="large" fullWidth
                     id="tax" formControlName="taxRate"
                     nbInput placeholder="Tax Rate" type="number">
              <br>
              <div class="feedback" *ngIf="quoteGroup['controls']['taxRate'].errors?.min">This value should be positive.</div>

              <br>

              <label for="services">Services Fees:</label>
              <input [(ngModel)]="productQuote.servicesFees" fieldSize="large" fullWidth
                     id="services" formControlName="servicesFees"
                     nbInput placeholder="Services Fees" type="number">
              <br>
              <div class="feedback" *ngIf="quoteGroup['controls']['servicesFees'].errors?.min">This value should be positive.</div>

              <br>
              <br>
              <h5> Entreprise Information:</h5>
              <label for="logoq">Entreprise logo:</label>
              <br>
              

              <input nbInput id="logoq" fieldSize="large" fullWidth (change)="CreateBase64String($event)"  type="file" multiple />
              <br>
              
              <label for="name">Enterprise Name:</label>
              <input [(ngModel)]="productQuote.enterpriseName" fieldSize="large" fullWidth
                     id="name" formControlName="enterpriseName"
                     nbInput placeholder="Enterprise Name" type="text" >
              <br>
              <label for="address">Enterprise Address:</label>
              <input [(ngModel)]="productQuote.enterpriseAddress" fieldSize="large" fullWidth
                     id="address" formControlName="enterpriseAddress"
                     nbInput placeholder="Enterprise Address" type="text">
              <br>
              <label for="city">Enterprise City:</label>
              <input [(ngModel)]="productQuote.enterpriseCity" fieldSize="large" fullWidth
                     id="city" formControlName="enterpriseCity"
                     nbInput placeholder="Enterprise City" type="text">
              <br>
              
              <label for="postal">Enterprise Postal Code:</label>
              <input [(ngModel)]="productQuote.enterprisePostalCode" fieldSize="large" fullWidth
                     id="postal" formControlName="enterprisePostalCode"
                     nbInput placeholder="Postal Code" type="number">
              <br>
              <label for="registration">Enterprise Tax Registration Number:</label>
              <input [(ngModel)]="productQuote.enterpriseTaxRegistrationNumber" fieldSize="large" fullWidth
                     id="registration" formControlName="enterpriseTaxRegistrationNumber"
                     nbInput placeholder="Tax Registration Number" type="text">
              <br>
              <label for="sig">Electronic Signature:</label>
              <div  id="sig" class="signature-container">
                <canvas #canvas width="255" height="255"  ></canvas>
              </div>
              <br>
              <div style="margin-left:440px ">
                <br>

                <button nbButton status="primary" (click)="addQuote()"  class="ml-5" >Create</button>

              </div>



            </form>
            
          </nb-card-body>
        </nb-card>
       
        <nb-card>
          <nb-card-header>
            <h3> Quotes List</h3>
          </nb-card-header>
          <nb-card-body>
            <ng2-smart-table [settings]="quotetabSettings" (delete)="deleteQuote($event)" (userRowSelect)="moveToSecondStep($event)" (edit)="downloadQuoteFromTable($event)"[source] ="quotes"  >
            </ng2-smart-table>
          </nb-card-body>
          
        </nb-card>
        <button nbButton disabled nbStepperNext>prev</button>
        <button nbButton nbStepperNext style="margin-left:920px ">next</button>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Purchase Orders</ng-template>
        
        <h4 >Generate Purchase Order</h4>
        <nb-card >
          <nb-card-body>
            <h5 style ="color: #3464fb ">
              Fill the form with the order's information :
            </h5>
            <form  [formGroup]="orderGroup" >
              <br>
              <br>
              <h5> Product's Information:</h5>
              <label for= "quantity">Quantity:</label>
              <input [(ngModel)]="productPurchaseOrder.quantity"
                     formControlName="quantity" fullWidth
                     id="quantity"
                      nbInput placeholder="Quantity"
                     type="number" >
              <br>
              <div class="feedback" *ngIf="orderGroup['controls']['quantity'].errors?.min">This value should be positive.</div>
              <label for="surface">Unit Price:</label>
              <input [(ngModel)]="productPurchaseOrder.unitPrice"  fieldSize="large"  fullWidth
                     id="surface" formControlName="unitPrice"
                     nbInput placeholder="Unit Price"
                     type="number"  >
              <br>
              <div class="feedback" *ngIf="orderGroup['controls']['unitPrice'].errors?.min">This value should be positive.</div>

              <label for="pieces">Product Name :</label>

              <input [(ngModel)]="productPurchaseOrder.productName"  fieldSize="large" fullWidth
                     id="pieces" formControlName="productName"
                     nbInput placeholder="Product Name"
                     type="text" >
              <br>
              <br>
              <br>

              <h5> Customer's Information:</h5>

              <label for="cName">Customer's name:</label>

              <input [(ngModel)]="productPurchaseOrder.customerName" fieldSize="large"  fullWidth
                     id="cName" formControlName="customerName"
                     nbInput placeholder="Customer's Name"  type="text" >
              <br>
              <label for="cEmail">Customer's Email:</label>

              <input  [(ngModel)]="productPurchaseOrder.customerEmail" formControlName="customerEmail" fieldSize="large"  fullWidth
                      id="cEmail"
                      nbInput placeholder="Customer's Email" type="text" >
              <br>
              <label for="cAddress">Customer's address:</label>

              <input [(ngModel)]="productPurchaseOrder.customerAddress" formControlName="customerAddress" fieldSize="large"  fullWidth
                     id="cAddress"
                     nbInput placeholder="Customer's Address" type="text" >
              <br>
              <label for="cPhone">Customer's Phone:</label>

              <input [(ngModel)]="productPurchaseOrder.customerPhone" formControlName="customerPhone" fieldSize="large" fullWidth
                     id="cPhone"
                     nbInput placeholder="Customer's Phone"  type="text" >
              <br>
              <br>
              <br>

              <h5> Payment's Information:</h5>

              <label for="tax">Tax Rate:</label>
              <input [(ngModel)]="productPurchaseOrder.taxRate" fieldSize="large" fullWidth
                     id="tax" formControlName="taxRate"
                     nbInput placeholder="Tax Rate" type="number">
              <br>
              <div class="feedback" *ngIf="orderGroup['controls']['taxRate'].errors?.min">This value should be positive.</div>

              <label for="services">Services Fees:</label>
              <input [(ngModel)]="productPurchaseOrder.servicesFees" fieldSize="large" fullWidth
                     id="services" formControlName="servicesFees"
                     nbInput placeholder="Services Fees" type="number">
             
              <br>
              <div class="feedback" *ngIf="orderGroup['controls']['servicesFees'].errors?.min">This value should be positive.</div>
              <label for="ap">Advance Payment:</label>
              <input [(ngModel)]="productPurchaseOrder.advancePayment" fieldSize="large" fullWidth
                     id="ap" formControlName="advancePayment"
                     nbInput placeholder="Advance Payment" type="number">
             
              <br>
              <div class="feedback" *ngIf="orderGroup['controls']['advancePayment'].errors?.min">This value should be positive.</div>
              <label for="a">Payment Mode:</label>
              <br>
              <nb-select [(ngModel)]="productPurchaseOrder.paymentMode" fieldSize="large"
                         id="a" formControlName="paymentMode" fieldSize="large" fullWidth
                         nbInput placeholder="Select payment mode" type="text">
                <nb-option  *ngFor="let mode of paymentModes" [value]="mode"> {{mode}}</nb-option>
              </nb-select>
              <br>
              <label for="advance">Payment Method:</label>
              <br>
              <nb-select [(ngModel)]="productPurchaseOrder.paymentMethod" fieldSize="large" fullWidth
                         id="advance" formControlName="paymentMethod" fieldSize="large" fullWidth
                         nbInput placeholder="Payment method:" type="text" (selectedChange)="selectedMethod($event)">
                <nb-option  *ngFor="let method of paymentMethod" [value]="method" > {{method}}</nb-option>
              </nb-select>

              <br>
              <label for="nm">Number Of Months:</label>
              <input [(ngModel)]="productPurchaseOrder.numberOfMonths" fieldSize="large" fullWidth
                     id="nm" formControlName="numberOfMonths"
                     nbInput placeholder="Number Of Months" type="number">
              
              <br>
              <div class="feedback" *ngIf="orderGroup['controls']['numberOfMonths'].errors?.min">This value should be positive.</div>
              <label for="qDate">Order's Date:</label>

              <input  [(ngModel)]="productPurchaseOrder.dueDate" formControlName="dueDate" nbInput id ="dDate" fieldSize="large" placeholder="Due Date" fullWidth [nbDatepicker]="dateTimePicker">
              <nb-datepicker withSeconds #dateTimePicker></nb-datepicker>

              <br>
              <br>
              <br>
              <h5> Entreprise Information:</h5>
              <label for="logopo">Entreprise logo:</label>
              <br>


              <input formControlName="logo" nbInput id="logopo" fieldSize="large" fullWidth (change)="CreateBase64String($event)"  type="file" multiple />
              <br>

              <label for="name">Enterprise Name:</label>
              <input [(ngModel)]="productPurchaseOrder.enterpriseName" fieldSize="large" fullWidth
                     id="name" formControlName="enterpriseName"
                     nbInput placeholder="Enterprise Name" type="text" >
              <br>
              <label for="address">Enterprise Address:</label>
              <input [(ngModel)]="productPurchaseOrder.enterpriseAddress" fieldSize="large" fullWidth
                     id="address" formControlName="enterpriseAddress"
                     nbInput placeholder="Enterprise Address" type="text">
              <br>
              <label for="city">Enterprise City:</label>
              <input [(ngModel)]="productPurchaseOrder.enterpriseCity" fieldSize="large" fullWidth
                     id="city" formControlName="enterpriseCity"
                     nbInput placeholder="Enterprise City" type="text">
              <br>

              <label for="postal">Enterprise Postal Code:</label>
              <input [(ngModel)]="productPurchaseOrder.enterprisePostalCode" fieldSize="large" fullWidth
                     id="postal" formControlName="enterprisePostalCode"
                     nbInput placeholder="Postal Code" type="number">
              <br>
              <label for="registration">Enterprise Tax Registration Number:</label>
              <input [(ngModel)]="productPurchaseOrder.enterpriseTaxRegistrationNumber" fieldSize="large" fullWidth
                     id="registration" formControlName="enterpriseTaxRegistrationNumber"
                     nbInput placeholder="Tax Registration Number" type="text">
              <br>
              
              <label for="sig2">Electronic Signature:</label>
              <div  id="sig2" class="signature-container">
                <canvas #canvas2 width="255" height="255"  ></canvas>
              </div>
              <br>
              <div style="margin-left:440px ">
                <br>
                <button nbButton status="primary" (click)="addProductPurchaseOrder()"  class="ml-5" >Create</button>

              </div>



            </form>
          </nb-card-body>
        </nb-card>
        <nb-card>
          <nb-card-header>
            <h3> Purchase Order's List</h3>
          </nb-card-header>
          <nb-card-body>
            <ng2-smart-table [settings]="quotetabSettings" (delete)="deleteOrder($event)" (edit)="viewOrderFromTable($event)" (userRowSelect)="moveToThirdStep($event)" [source] ="orders"  >
            </ng2-smart-table>
          </nb-card-body>

        </nb-card>

        <button nbButton  nbStepperPrevious>prev</button>
        <button nbButton nbStepperNext style="margin-left:920px ">next</button>
      </nb-step>
      <nb-step [label]="labelThree">
        <ng-template #labelThree>Bill Of Lading</ng-template>
        
        <h4> Generate Bill Of Lading</h4>
        <nb-card >
          
              <h5 style ="color: #3464fb ">
              Fill the form with the bill's information :
            </h5>
          
          <form [formGroup]="billGroup">
              <br>
              <br>
              <h5> Product's Information:</h5>
              <label for="bpname">Product Name :</label>
              <input [(ngModel)]="productBillOfLading.productName"  fieldSize="large" fullWidth
                     id="bpname" formControlName="productName"
                     nbInput placeholder="Product Name"
                     type="text" >
              <br>
              <label for= "bpquantity">Quantity:</label>
              <input [(ngModel)]="productBillOfLading.quantity"
                     formControlName="quantity" fullWidth
                     id="bpquantity"
                     name="PropertyName" nbInput placeholder="Quantity"
                     type="number" >
              <br>
            <div class="feedback" *ngIf="billGroup['controls']['quantity'].errors?.min">This value should be positive.</div>
            <label for="tax2">Tax Rate:</label>
            <input [(ngModel)]="productBillOfLading.taxRate" fieldSize="large" fullWidth

                   id="tax2" formControlName="taxRate"
                   nbInput placeholder="Tax Rate" type="number">
            <br>

            <div class="feedback" *ngIf="billGroup['controls']['taxRate'].errors?.min">This value should be positive.</div>
            <label for="services2">Services Fees:</label>
            <input [(ngModel)]="productBillOfLading.servicesFees" fieldSize="large" fullWidth
                   id="services2" formControlName="servicesFees"
                   nbInput placeholder="Services Fees" type="number">


            <br>
            <div class="feedback" *ngIf="billGroup['controls']['servicesFees'].errors?.min">This value should be positive.</div>
            <label for="advance">Advance Payment:</label>
            <input [(ngModel)]="productBillOfLading.advancePayment" fieldSize="large" fullWidth
                   id="advance" formControlName="advancePayment"
                   nbInput placeholder="advance Payment:" type="number">
            <br>
            <div class="feedback" *ngIf="billGroup['controls']['advancePayment'].errors?.min">This value should be positive.</div>
              <label for="ll">Product's Final Price:</label>
              <input [(ngModel)]="productBillOfLading.finalPrice"  fieldSize="large"  fullWidth
                     id="ll" formControlName="finalPrice"
                     nbInput placeholder="Final Price"
                     type="number"  >
            <br>
            <div class="feedback" *ngIf="billGroup['controls']['finalPrice'].errors?.min">This value should be positive.</div>
            <br>
            <br>


              <h5> Customer's Information:</h5>

              <label for="cbName">Customer's name:</label>

              <input [(ngModel)]="productBillOfLading.customerName" fieldSize="large"  fullWidth
                     id="cbName" formControlName="customerName"
                     nbInput placeholder="Customer's Name"  type="text" >
              <br>
              <label for="cbEmail">Customer's Email:</label>

              <input  [(ngModel)]="productBillOfLading.customerEmail" formControlName="customerEmail" fieldSize="large"  fullWidth
                      id="cbEmail"
                      nbInput placeholder="Customer's Email" type="text" >
              <br>
              <label for="cbAddress">Customer's address:</label>

              <input [(ngModel)]="productBillOfLading.customerAddress" formControlName="customerAddress" fieldSize="large"  fullWidth
                     id="cbAddress"
                     nbInput placeholder="Customer's Address" type="text" >
              <br>
              <label for="cbPhone">Customer's Phone:</label>

              <input [(ngModel)]="productBillOfLading.customerPhone" formControlName="customerPhone" fieldSize="large" fullWidth
                     id="cbPhone"
                     nbInput placeholder="Customer's Phone"  type="text" >
              <br>
              <br>
              <br>

              <h5> Delivery's Information:</h5>

              <label for="ddDate">Order's Date:</label>

              <input  [(ngModel)]="productBillOfLading.orderDate" formControlName="orderDate" nbInput id ="ddDate" fieldSize="large" placeholder="order Date" fullWidth [nbDatepicker]="dateTimePicker">
              <nb-datepicker withSeconds #dateTimePicker></nb-datepicker>
              <label for="aaDate">Shipment's Date:</label>

              <input  [(ngModel)]="productBillOfLading.shipmentDate" formControlName="shipmentDate" nbInput id ="aaDate" fieldSize="large" placeholder="shipment Date" fullWidth [nbDatepicker]="dateTimePicker2">
              <nb-datepicker withSeconds #dateTimePicker2></nb-datepicker>
              <label for="dddDate">Delivery's Date:</label>

              <input  [(ngModel)]="productBillOfLading.deliveryDate" formControlName="deliveryDate" nbInput id ="dddDate" fieldSize="large" placeholder="Delivery's Date" fullWidth [nbDatepicker]="dateTimePicker3">
              <nb-datepicker withSeconds #dateTimePicker3></nb-datepicker>

            <br>
            <br>
            <br>

              <h5> Enterprise Information:</h5>
              <label for="logopob">Entreprise logo:</label>
              <br>


              <input formControlName="logo" fieldSize="large" fullWidth nbInput id="logopob" (change)="CreateBase64String($event)"  type="file" multiple />
              <br>

              <label for="nameb">Enterprise Name:</label>
              <input [(ngModel)]="productBillOfLading.enterpriseName" fieldSize="large" fullWidth
                     id="nameb" formControlName="enterpriseName"
                     nbInput placeholder="Enterprise Name" type="text" >
              <br>
              <label for="addressb">Enterprise Address:</label>
              <input [(ngModel)]="productBillOfLading.enterpriseAddress" fieldSize="large" fullWidth
                     id="addressb" formControlName="enterpriseAddress"
                     nbInput placeholder="Enterprise Address" type="text">
              <br>
              <label for="cityb">Enterprise City:</label>
              <input [(ngModel)]="productBillOfLading.enterpriseCity" fieldSize="large" fullWidth
                     id="cityb" formControlName="enterpriseCity"
                     nbInput placeholder="Enterprise City" type="text">
              <br>
            
            <label for="postal">Enterprise Postal Code:</label>
            <input [(ngModel)]="productBillOfLading.enterprisePostalCode" fieldSize="large" fullWidth
                   id="postal" formControlName="enterprisePostalCode"
                   nbInput placeholder="Postal Code" type="number">
            <br>
            <label for="registration">Enterprise Tax Registration Number:</label>
            <input [(ngModel)]="productBillOfLading.enterpriseTaxRegistrationNumber" fieldSize="large" fullWidth
                   id="registration" formControlName="enterpriseTaxRegistrationNumber"
                   nbInput placeholder="Tax Registration Number" type="text">
            <br>            <label for="sig3">Electronic Signature:</label>
            <div  id="sig3" class="signature-container">
              <canvas #canvas3 width="255" height="255"  ></canvas>
            </div>
            <br>
              <div style="margin-left:440px ">
                <br>
                <button nbButton status="primary" (click)="createBillOfLading()"  class="ml-5" >Create</button>

              </div>



            </form>
            


          
        </nb-card>
        <nb-card>
          <nb-card-header>
            <h3> Bill's List</h3>
          </nb-card-header>
          <nb-card-body>
            <ng2-smart-table [settings]="billsSettings" (delete)="deleteBIll($event)" (edit)="viewBillFromTable($event)" (userRowSelect)="validateBill($event)" [source] ="bills"  >
            </ng2-smart-table>
          </nb-card-body>

        </nb-card>
         
        <button nbButton nbStepperPrevious>prev</button>
        <button nbButton nbStepperNext>next</button>
      </nb-step>

    </nb-stepper>
    
  </nb-card-body>
</nb-card>
