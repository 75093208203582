import { Component, OnInit } from '@angular/core';
import { Tax } from 'app/modules/financial-management/models/Tax';
import { TaxService } from 'app/modules/financial-management/service/Tax/tax.service';
import { ExportService } from 'app/shared/exports/export.service';
import {
  NbWindowService,
} from '@nebular/theme';
import { TaxHelperComponent } from '../../../Budgets/util/helpers/tax-helper/tax-helper.component';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-taxes-management',
  templateUrl: './taxes-management.component.html',
  styleUrls: ['./taxes-management.component.scss']
})
export class TaxesManagementComponent  implements OnInit{

  constructor(private taxService: TaxService, 
            private exportService: ExportService,
            private ws: NbWindowService,
           private router: Router

   
  ) { }
  taxes: Tax[];
  isLoading = true;
  tabSource = [];


  ngOnInit(): void {
    this.getAllTaxes();
    console.log(" this.getAllTaxes()"+ this.getAllTaxes())
  }



  
settings = {
  hideSubHeader: false ,
  pager: {
    display: true,
    perPage: 10,
  },
  columns: {
   
    name: {
      title: 'Name',
      type: 'string'
    },
    rate: {
      title: 'Rate %',
      type: 'number' 
    },
    country: {
      title: 'Country',
      type: 'number' 
    },
    description: {
      title: 'Description',
      type: 'string'
    },
    
  },

  



  actions: { edit: false, delete: false, add: false  ,  custom: [
    {
      name: 'view',
      title: '<i  class="fas fa-eye small mt-2"></i>',

    }],}, 
 /*  add: {
    addButtonContent: '<span class="material-icons">add</span>',
    saveButtonContent: '<i class="nb-checkmark"></i>',
    cancelButtonContent: '<i class="nb-close"></i>',
    confirmCreate: true,
  },*/
 
 };
 

 getAllTaxes(): void {
  console.log('Calling getAllTaxes()');
  this.taxService.getAllTaxes().subscribe(
    (data: Tax[]) => {
      this.taxes = [] ;
      for (let p = 0; p < data.length; p++) {
        if (data[p].archived === false) {
          this.taxes.push(data[p]);
        }
      }
    

    }, (err) => {
      return err;
    },
  );
}


loadData() {
  setTimeout(() => {
    this.isLoading = false;
  }, 1000); 
}




onCreateConfirm(event): void {
  
        this.taxService.createTax(event.newData).subscribe({
          next: (result: Tax) => {
            event.confirm.resolve();
          // this.loadData();
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          },
          error: (err: any) => {
            console.log(err);
            event.confirm.reject();
          }
        });
      
  
  
}

onEditConfirm(event): void {
  this.taxService.updateTax(event.newData.id, event.newData).subscribe({
    next: (result: Tax) => {
      event.confirm.resolve();
     // this.loadData();
     setTimeout(() => {
      window.location.reload();
    }, 1500);
    },
    error: (err: any) => {
      console.log(err);
      event.confirm.reject();
    }
  });
}



onDeleteConfirm(event): void {
  if (window.confirm('Are you sure you want to delete?')) {
    this.taxService.deleteTax(event.data.id).subscribe({
      next: () => {
        event.confirm.resolve();
        //this.loadData();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      error: (err: any) => {
        console.log(err);
        event.confirm.reject();
      }
    });
  } else {
    event.confirm.reject();
  }
}


openWindow() {
  this.ws.open(TaxHelperComponent, {title: `Help`});

}



exportExcel(): void {
  const title = 'Taxes';
  const tabSource = [];

  const headers = ['Name',  'Description', 'Rate', 'Country'];
  tabSource.push(headers);

  this.taxes.forEach(row => {
      tabSource.push([row.name, row.description, row.rate.toString() , row.country]);
  });

  this.exportService.exportExcel(title, tabSource);
}


onCustom(event): void {
  const selectedTax: Tax = event.data;
    console.log('Selected Tax ID:', selectedTax.id);
this.router.navigate(['/finance/updateTax', selectedTax.id]);

}


}

