import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { AssetService } from 'app/modules/financial-management/service/AssetService/asset-service.service';
import { LiabilityService } from 'app/modules/financial-management/service/LiabilityService/liability-service.service';
import { TransactionService } from 'app/modules/financial-management/service/Transaction/transaction.service';

@Component({
  selector: 'ngx-financial-report-list',
  templateUrl: './financial-report-list.component.html',
  styleUrls: ['./financial-report-list.component.scss']
})
export class FinancialReportListComponent implements OnInit {
  assetsData: any[] = [];
  liabilityData: any[] = [];
  transactionData: any[] = [];

  constructor(
    private assetService: AssetService,
    private liabilityService: LiabilityService,
    private transactionService: TransactionService
  ) {}

  ngOnInit(): void {
    this.getAllAssets();
    this.getAllLiabilities();
    this.getAllTransactions();
  }

  getAllAssets() {
    this.assetService.getAllAssets().subscribe(
      (data: any[]) => {
        this.assetsData = data.filter(l => !l.archived);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getAllLiabilities() {
    this.liabilityService.getAllLiabilities().subscribe(
      (data: any[]) => {
        this.liabilityData = data.filter(l => !l.archived);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getAllTransactions() {
    this.transactionService.getAllTransactions().subscribe(
      (data: any[]) => {
        this.transactionData = data;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  generatePDF() {
    const content = document.getElementById('reportContent');
    if (content) {
      html2canvas(content).then(canvas => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const doc = new jsPDF('p', 'mm', 'a4');

        const imageData = canvas.toDataURL('image/png');
        doc.addImage(imageData, 'PNG', 10, 10, imgWidth, imgHeight);

        doc.setFontSize(20);
        doc.text('Financial Report of 2023', 10, 5);

        doc.save('financial_report.pdf');
      });
    }
  }

  exportExcel() {
    const workbook = XLSX.utils.book_new();

    // Balance Sheet
    const balanceSheetData = this.prepareBalanceSheetData();
    const balanceSheetSheet = XLSX.utils.json_to_sheet(balanceSheetData, { header: ['Category', 'Name', 'Value'] });
    XLSX.utils.book_append_sheet(workbook, balanceSheetSheet, 'Balance Sheet');

    // Liabilities
    const liabilitiesData = this.prepareLiabilitiesData();
    const liabilitiesSheet = XLSX.utils.json_to_sheet(liabilitiesData, { header: ['Category', 'Type', 'Amount', 'Purpose', 'Account'] });
    XLSX.utils.book_append_sheet(workbook, liabilitiesSheet, 'Liabilities');

    // Income Statement
    const incomeStatementData = this.prepareIncomeStatementData();
    const incomeStatementSheet = XLSX.utils.json_to_sheet(incomeStatementData, { header: ['Date', 'Type', 'Details', 'Balance', 'Account'] });
    XLSX.utils.book_append_sheet(workbook, incomeStatementSheet, 'Income Statement');

    XLSX.writeFile(workbook, 'financial_report.xlsx');
  }

  prepareBalanceSheetData() {
    return this.assetsData.map(asset => ({
      Category: 'Assets',
      Name: asset.name,
      Value: asset.currentValue
    }));
  }

  prepareLiabilitiesData() {
    return this.liabilityData.map(liability => ({
      Category: 'Liabilities',
      Type: liability.type,
      Amount: liability.amountOwed,
      Purpose: liability.purpose,
      Account: liability.account?.name
    }));
  }

  prepareIncomeStatementData() {
    return this.transactionData.map(transaction => ({
      Date: transaction.date,
      Type: transaction.type,
      Details: transaction.details,
      Balance: transaction.balance,
      Account: transaction.account.name
    }));
  }
}
