<nb-card>
    <nb-card-header>
        <h3>Usage tips</h3>
    </nb-card-header>
    <nb-card-body>
        <p>
            1-You can choose the Budget Plan Year in the list in the top of the page.
            <br> 2-You can add Money Flow by clicking on the plus button in the table. You can also modify and delete.
            <br> 3-Monthly And Annual Budgets plans are linked. If you add a money flow in a monthly plan, it will be added automatically in the corresponding yearly plan.
            <br> 4-To export your data to Excel files click on the icon on the top right of the interface.
            <br>


        </p>

    </nb-card-body>
</nb-card>