import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Provision } from 'app/modules/financial-management/models/Provision';
import { ProvisionService } from 'app/modules/financial-management/service/Provision/provision.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-archived-provision-list',
  templateUrl: './archived-provision-list.component.html',
  styleUrls: ['./archived-provision-list.component.scss']
})
export class ArchivedProvisionListComponent  implements OnInit {
  provsion: Provision[];
  isLoading = true;

  provsionData: Provision[] = [];
  settings = {
    hideSubHeader: false,
  
    actions: {
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: 'restore', 
            title: '<i class="fas fa-history fa-xs custom-restore-icon" title="Restore this provision"></i>',
          },
        ],
      },
    columns: {
      description: {
            title: 'Description',
            type: 'string',
        },
    
        amount: {
            title: 'Amount',
            type: 'string',
        },
        type: {
          title: 'Type',
          type: 'html',
          valuePrepareFunction: (type) => {
            const formattedType = type
              .split('_')
              .map(word => word.charAt(0) + word.slice(1).toLowerCase())
              .join(' ');
  
            switch (type) {
              case 'PROVISION_FOR_DOUBTFUL_DEBTS': return `<h5><span class="badge badge-danger">${formattedType}</span></h5>`;
              case 'PROVISION_FOR_STOCK_DEPRECIATION': return `<h5><span class="badge badge-success">${formattedType}</span></h5>`;
              case 'PROVISION_FOR_LOSSES_ON_CONTRACTS': return `<h5><span class="badge badge-primary">${formattedType}</span></h5>`;
              case 'PROVISION_FOR_LITIGATION_AND_DISPUTES': return `<h5><span class="badge badge-warning">${formattedType}</span></h5>`;
              case 'PROVISION_FOR_DEFERRED_TAXES': return `<h5><span class="badge badge-info">${formattedType}</span></h5>`;
              default: return `<h5><span class="badge badge-secondary">${formattedType}</span></h5>`;
            }
          },

        },
        calculation: {
          title: 'Calculated provision amount',
      },

  account: { 
    title: 'Account',
    valuePrepareFunction: (cell: any, row: any) => {
      return row.account?.name ;
    }
  }
},
pager: {
  display: true,
  perPage: 10,
},
};


  constructor(
    private router: Router,
    private es: ExportService,
    private provsionService: ProvisionService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllArchivedProvisions();
  }

  getAllArchivedProvisions() {
    this.provsionService.getAllProvisions().subscribe(
      (data: Provision[]) => {
        console.log(data); // Vérifiez ici
        this.provsionData = data.filter(l => l.archived);
        console.log(this.provsionData); // Vérifiez ici aussi
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        return err;
      },
    );
  }

  restoreConfirmation(provsionId: string): void {
    if (window.confirm('Are you sure you want to restore this provision?')) {
      this.restoreProvision(provsionId);
    }
  }

  restoreProvision(provsionId: string): void {
    this.provsionService.archiveProvision(provsionId).subscribe({
      next: () => {
        this.showToast('success', 'Provision Restored', 'The provision has been restored successfully.');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      error: (err: any) => {
        console.error('Error archiving provision:', err);
        this.showToast('danger', 'Error', 'An error occurred while archiving the provison.');
      }
    });
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  onCustom(event): void {
    if (event.action === 'restore') { 
      this.restoreConfirmation(event.data.id);
    }
  }
}

