import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbWindowService, NbToastrService } from '@nebular/theme';
import { Dividend } from 'app/modules/financial-management/models/Dividend';
import { DividendService } from 'app/modules/financial-management/service/Dividend/dividend.service';
import { ArchivedHelperComponent } from '../../Utils/helper/archived-helper/archived-helper.component';

@Component({
  selector: 'ngx-archived-dividend',
  templateUrl: './archived-dividend.component.html',
  styleUrls: ['./archived-dividend.component.scss']
})
export class ArchivedDividendComponent implements OnInit {


  constructor(
    private dividendService: DividendService,
    private router: Router,
    private ws: NbWindowService,
    private toastrService: NbToastrService,
    private datePipe: DatePipe

  ) {}

  dividends: Dividend[];
  isLoading = true;
  dividendtId: string;
  dividend: Dividend;

  tabSettings = {
    hideSubHeader: false ,
    pager: {
      display: true,
      perPage: 10,
    },
    actions: { edit: false, delete: false, add: false,
      custom: [
        {
          name: 'Restore',
          title: '<i class="fas fa-history fa-xs custom-restore-icon" title="Restore this dividend"></i>',
        }],
    },
    columns: {
      amount: { title: 'Amount' },
      currency: {
        title: 'Currency',
        type: 'html',
        valuePrepareFunction: (status) => {
          switch (status) {
            case 'USD': return `<h5><span class="badge badge-danger">${status}</span></h5>`;
            case 'EUR': return `<h5><span class="badge badge-success">${status}</span></h5>`;
            case 'GBP': return `<h5><span class="badge badge-primary">${status}</span></h5>`;
            case 'TND': return `<h5><span class="badge badge-warning">${status}</span></h5>`;
            case 'JPY': return `<h5><span class="badge badge-info">${status}</span></h5>`;
            default: return `<h5><span class="badge badge-secondary">${status}</span></h5>`;
          }
        },
      },
      shareholderr: {
        title: 'Shareholder FullName',
        valuePrepareFunction: (cell: any, row: any) => {
          if (row.shareholderr) {
            const firstName = row.shareholderr.shareholderFirstName || '';
            const lastName = row.shareholderr.shareholderlastName || '';
            return `${firstName} ${lastName}`.trim() || 'N/A';
          } else {
            return 'N/A';
          }
        },
      },
      shareholderPercentage: {
        title: 'Shareholder Percentage',
        valuePrepareFunction: (cell: any, row: any) => {
          return row.shareholderr?.shareholderPercentage ? `${row.shareholderr.shareholderPercentage.toFixed(3)}` : 'N/A';
        }
      }
    }
  };
  
  
  ngOnInit() {
   this.getAllArchivedDividends();
  }

  getAllArchivedDividends(): void {
    this.dividendService.getArchivedDividends().subscribe(
      data => {
        console.log('Received archived dividends :', data);
        this.dividends = data;
      },
      error => {
        console.error('Error fetching dividends:', error);
      }
    );
  }

  restoreConfirmation(dividendId: string): void {
    if (window.confirm('Are you sure you want to restore this dividend?')) {
      this.restoreDividend(dividendId);
    }
  }

  restoreDividend(dividendId: string): void {
    this.dividendService.archiveDividend(dividendId).subscribe({
      next: () => {
        this.showToast('success', 'Dividend Restored', 'The dividend has been restored successfully.');
        window.location.reload();
      },
      error: (err: any) => {
        console.error('Error archiving dividend:', err);
        this.showToast('danger', 'Error', 'An error occurred while archiving the dividend.');
      }
    });
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }


  onCustom(event): void {
    if (event.action === 'Restore') {
      this.restoreConfirmation(event.data.id);
    }
  }


  openWindow() {
    this.ws.open(ArchivedHelperComponent, {title: `Help`});
  
  }
}