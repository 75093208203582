import { Component } from '@angular/core';

@Component({
  selector: 'ngx-dividend-helper',
  templateUrl: './dividend-helper.component.html',
  styleUrls: ['./dividend-helper.component.scss']
})
export class DividendHelperComponent {

}
