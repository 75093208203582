import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Asset } from 'app/modules/financial-management/models/Asset';
import { AssetService } from 'app/modules/financial-management/service/AssetService/asset-service.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-archived-assets-list',
  templateUrl: './archived-assets-list.component.html',
  styleUrls: ['./archived-assets-list.component.scss']
})
export class ArchivedAssetsListComponent implements OnInit {
  asset: Asset[];
  isLoading = true;

  assetsData: Asset[] = [];
  settings = {
    hideSubHeader: false,
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'restore', 
          title: '<i class="fas fa-history fa-xs custom-restore-icon" title="Restore this asset"></i>',
        },
      ],
    },
    columns: {
      name: {
        title: 'Name',
        type: 'string',
      },
      initialValue: {
        title: 'Initial Value',
        type: 'number',
      },
      currentValue: {
        title: 'Current Value',
        type: 'number',
      },
      account: {
        title: 'Account',
        valuePrepareFunction: (cell: any, row: any) => {
          return row.account?.name;
        }
      }
    },
    pager: {
      display: true,
      perPage: 10,
    },
  };

  constructor(
    private router: Router,
    private es: ExportService,
    private assetService: AssetService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllArchivedAssets();
  }

  getAllArchivedAssets() {
    this.assetService.getAllAssets().subscribe(
      (data: Asset[]) => {
        console.log(data); // Vérifiez ici
        this.assetsData = data.filter(l => l.archived);
        console.log(this.assetsData); // Vérifiez ici aussi
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        return err;
      },
    );
  }

  restoreConfirmation(assetId: string): void {
    if (window.confirm('Are you sure you want to restore this asset?')) {
      this.restoreAsset(assetId);
    }
  }

  restoreAsset(assetId: string): void {
    this.assetService.archiveAsset(assetId).subscribe({
      next: () => {
        this.showToast('success', 'Asset Restored', 'The asset has been restored successfully.');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      error: (err: any) => {
        console.error('Error archiving asset:', err);
        this.showToast('danger', 'Error', 'An error occurred while archiving the asset.');
      }
    });
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  onCustom(event): void {
    if (event.action === 'restore') { 
      this.restoreConfirmation(event.data.id);
    }
  }
}
