<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h3>Tax Details</h3>
                    </div>
                    <div class="col-md-6 text-right">


                        <button nbTooltip="Enable fields" nbTooltipStatus="primary" shape="round" status="basic" (click)="enableFormFields()" nbButton class="icon-button mr-2">
                            <nb-icon icon="edit-outline"></nb-icon> 
              </button>
                        <button nbTooltip="Archive Tax" nbTooltipStatus="primary" shape="round" status="danger" (click)="archiveConfirmation()" nbButton class="icon-button mr-2">
                <nb-icon icon="archive-outline"></nb-icon>
              </button>
                        <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/finance/taxes']" nbButton>
                            <nb-icon icon="corner-up-right-outline"></nb-icon>
                        </a>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>
                <form [formGroup]="taxForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div align="left">
                            <label for="name" class="label">Name</label>
                        </div>
                        <input nbInput id="name" placeholder="Name" formControlName="name" class="form-control" nbInput fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group">
                        <div align="left">
                            <label for="rate" class="label">Rate</label>
                        </div>
                        <input nbInput id="rate" placeholder="Rate" formControlName="rate" class="form-control" nbInput fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group">
                        <div align="left">
                            <label for="country" class="label">Country</label>
                        </div>
                        <input nbInput id="country" placeholder="Country" formControlName="country" class="form-control" nbInput fieldSize="large" fullWidth>
                    </div>

                    <div class="form-group">
                        <div align="left">
                            <label for="description" class="label">Description</label>
                        </div>
                        <input nbInput id="description" placeholder="Description" formControlName="description" class="form-control" nbInput fieldSize="large" fullWidth>
                    </div>


                    <div class="row justify-content-center mt-4">
                        <div class="col-md-12 text-center">
                            <button nbButton status="success" class="mr-2">
                                <nb-icon icon="checkmark-circle-outline"></nb-icon>Update
                            </button>
                        </div>
                    </div>
                </form>

            </nb-card-body>
        </nb-card>
    </div>
</div>