<nb-card>

    <nb-card-header>
        <div class="row">
            <div class="col-md-10">
                <h3>Archived shareholders</h3>
            </div>
        </div>
    </nb-card-header>

    <nb-card-body>
        <br />

        <ng2-smart-table [settings]="shareholdersSettings" (custom)="onCustom($event)" class="table" [source]="shareholders">
        </ng2-smart-table>
    </nb-card-body>
</nb-card>