import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Account } from 'app/modules/financial-management/models/Account';
import { Liability } from 'app/modules/financial-management/models/Liability';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { LiabilityService } from 'app/modules/financial-management/service/LiabilityService/liability-service.service';

@Component({
  selector: 'ngx-update-liability',
  templateUrl: './update-liability.component.html',
  styleUrls: ['./update-liability.component.scss']
})
export class UpdateLiabilityComponent implements OnInit {
  LiabilityForm: FormGroup;
  accounts: Account[] = [];
  liabilityId: string;
  liability: Liability;
  formDisabled: boolean = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private liabilityService: LiabilityService,
    private accountService: AccountsService,
    private toastrService: NbToastrService,
    private route: ActivatedRoute
  ) {
    this.LiabilityForm = this.fb.group({
      type: [{ value: '', disabled: this.formDisabled }, Validators.required],
      amountOwed: [{ value: '', disabled: this.formDisabled }, Validators.required],
      interestRate: [{ value: '', disabled: this.formDisabled }, Validators.required],
      maturityDate: [{ value: '', disabled: this.formDisabled }, Validators.required],
      repaymentTerms: [{ value: '', disabled: this.formDisabled }, Validators.required],
      collateral: [{ value: '', disabled: this.formDisabled }, Validators.required],
      covenants: [{ value: '', disabled: this.formDisabled }, Validators.required],
      purpose: [{ value: '', disabled: this.formDisabled }, Validators.required],
      creditRating: [{ value: '', disabled: this.formDisabled }, Validators.required],
      legalRequirements: [{ value: '', disabled: this.formDisabled }, Validators.required],
      account: [{ value: null, disabled: this.formDisabled }, Validators.required]
    });
  }

  ngOnInit(): void {
    this.liabilityId = this.route.snapshot.paramMap.get('id');
    this.getLiabilityDetails();
    this.loadAccounts();
  }

  loadAccounts(): void {
    this.accountService.getAllAccounts().subscribe(
      (data: Account[]) => {
        this.accounts = data;
      },
      (error) => {
        console.error('Error loading accounts:', error);
      }
    );
  }

  populateForm(): void {
    if (this.liability) {
      this.LiabilityForm.patchValue({
        type: this.liability.type,
        amountOwed: this.liability.amountOwed,
        interestRate: this.liability.interestRate,
        maturityDate: this.liability.maturityDate,
        repaymentTerms: this.liability.repaymentTerms,
        collateral: this.liability.collateral,
        purpose: this.liability.purpose,
        creditRating: this.liability.creditRating,
        legalRequirements: this.liability.legalRequirements,
        covenants:this.liability.covenants,
        account: this.liability.account ? this.liability.account.id : null,
      });
    }
  }

  onSubmit(): void {
    this.enableFormFields();  

    if (this.LiabilityForm.invalid) {
      this.markAllAsTouched();  
      console.error('Form is invalid', this.LiabilityForm.errors, this.LiabilityForm.controls);  
      return;
    }

    const formValue = this.LiabilityForm.value;
    const updatedLiabilityData = {
      ...formValue,
      account: this.accounts.find(account => account.id === formValue.account)
    };

    this.liabilityService.updateLiability(this.liabilityId, updatedLiabilityData).subscribe(
      updatedAj => {
        console.log('Liability updated successfully:', updatedAj);
        this.showToast('success', 'Success', 'Liability updated successfully');
        this.router.navigate(['/finance/liabilityList']);
      },
      error => {
        console.error('Error updating liability:', error);
      }
    );
  }

  getLiabilityDetails(): void {
    this.liabilityService.getLiabilityById(this.liabilityId).subscribe(
      liability => {
        this.liability = liability;
        this.populateForm();
      },
      error => {
        console.error('Error fetching liability details:', error);
      }
    );
  }

  archiveConfirmation(): void {
    if (window.confirm('Are you sure you want to archive this liability?')) {
      this.archiveLiability();
    }
  }
  archiveLiability(): void {
    if (this.liability && this.liability.id) {
      this.liabilityService.archiveLiability(this.liability.id).subscribe({
        next: () => {
          this.toastrService.success('Liability archived successfully', 'Success');
          this.router.navigate(['/finance/liabilityList']);
        },
        error: (err: any) => {
          console.error('Error archiving asset:', err);
          this.toastrService.danger('Failed to archive liability', 'Error');
        }
      });
    } else {
      console.error('Asset id is undefined.');
      this.toastrService.danger('Failed to archive asset', 'Error');
    }
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  enableFormFields(): void {
    this.formDisabled = false;
    Object.keys(this.LiabilityForm.controls).forEach(key => {
      this.LiabilityForm.controls[key].enable();
    });
  }

  markAllAsTouched(): void {
    Object.keys(this.LiabilityForm.controls).forEach(key => {
      this.LiabilityForm.controls[key].markAsTouched();
    });
  }
}
