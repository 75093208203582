<nb-card>
  <nb-card-header>
    <h3>Usage tips</h3>
  </nb-card-header>
  <nb-card-body>
    <p>
      1-You can choose the month to display its transactions or go back to the global table
      <br>
      2-Click on the plus icon in the actions columns to add a new transaction
      <br>
      3-You can export date to PDF or to Excel files by clicking on the icons on the top right
      <br>
      4-You can search using the white fields
      <br>
      5-Click on the column's title to sort
    </p>

  </nb-card-body>
</nb-card>