import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NbToastrService } from "@nebular/theme";
import { Account } from "app/modules/financial-management/models/Account";
import { Expense } from "app/modules/financial-management/models/Expense";
import { AccountsService } from "app/modules/financial-management/service/Accounts/accounts.service";
import { ExpenseService } from "app/modules/financial-management/service/Expense/expenses.service"

@Component({
  selector: 'ngx-add-expense',
  templateUrl: './add-expense.component.html',
  styleUrls: ['./add-expense.component.scss']
})
export class AddExpenseComponent implements OnInit {
  expenseForm: FormGroup;
  accounts: Account[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private expenseService: ExpenseService,
    private accountService: AccountsService,
    private toastrService: NbToastrService
  ) {
    this.expenseForm = this.fb.group({
      description: ['', Validators.required],
      amount: ['', Validators.required],
      date: ['', Validators.required],
      paymentMethod: ['', Validators.required],
      receiptNumber: ['', Validators.required],
      archived: [false, Validators.required],
      account: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadAccounts();
  }

  loadAccounts(): void {
    this.accountService.getAllAccounts().subscribe(
      (data: Account[]) => {
        this.accounts = data;
      },
      (error) => {
        console.error('Error loading accounts:', error);
      }
    );
  }

  onSubmit(): void {

   
    if (this.expenseForm.valid) {
      const newExpense: Expense = this.expenseForm.getRawValue();
      newExpense.account = { id: this.expenseForm.get('account').value };

      this.expenseService.createExpense(newExpense).subscribe(
        () => {
          this.showToast('success', 'Expense Created', 'The expense has been created successfully.');
          this.router.navigate(['/finance/expensesList']);
        },
        (error) => {
          console.error('Error creating expense:', error);
        }
      );
    }
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }
}
