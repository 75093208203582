import { Component } from '@angular/core';

@Component({
  selector: 'ngx-cash-flow-list',
  templateUrl: './cash-flow-list.component.html',
  styleUrls: ['./cash-flow-list.component.scss']
})
export class CashFlowListComponent {

}
