import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Liability } from '../../models/Liability';
import { environment } from '../../../../../environments/environment';
import { Transaction } from '../../models/Transaction';

@Injectable({
  providedIn: 'root'
})
export class LiabilityService {

  private baseUrl = environment.financeUrl + 'api/liabilities';

  constructor(private http: HttpClient) { }

  getAllLiabilities(): Observable<Liability[]> {
    return this.http.get<Liability[]>(`${this.baseUrl}/getAllLiabilities`);
  }

  getLiabilityById(id: string): Observable<Liability> {
    return this.http.get<Liability>(`${this.baseUrl}/getLiabilityById/${id}`);
  }

  createLiability(liability: Liability): Observable<Liability> {
    return this.http.post<Liability>(`${this.baseUrl}/createLiability`, liability);
  }

  updateLiability(id: string, liability: Liability): Observable<Liability> {
    return this.http.put<Liability>(`${this.baseUrl}/updateLiability/${id}`, liability);
  }

  deleteLiability(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/deleteLiability/${id}`);
  }

  archiveLiability(liabilityId: string): Observable<Liability> {
    return this.http.put<Liability>(`${this.baseUrl}/${liabilityId}/archive`, {});
  }

  verifyLiabilityConsistency(liabilityId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/${liabilityId}/verifyConsistency`);
  }

  reconcileLiability(liabilityId: string, transaction: Transaction): Observable<Liability> {
    return this.http.put<Liability>(`${this.baseUrl}/${liabilityId}/reconcile`, transaction);
  }
  

  getNonReconciledLiabilities(): Observable<Liability[]> {
    return this.http.get<Liability[]>(`${this.baseUrl}/non-reconciled`);
  }
}
