import { Component } from '@angular/core';

@Component({
  selector: 'ngx-archived-helper',
  templateUrl: './archived-helper.component.html',
  styleUrls: ['./archived-helper.component.scss']
})
export class ArchivedHelperComponent {

}
