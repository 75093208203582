<nb-layout xmlns:color="http://www.w3.org/1999/xhtml">

    <nb-layout-column>

        <div class="background">
            <div class="shape"></div>
            <div class="shape"></div>
        </div>

        <form [formGroup]="forgetPWDForm">
            <tr>
                <img src="assets/images/logo/Manajero-logo.png" alt="" style="width: 40%; margin-left: 120px; " />
            </tr>
            <tr>
                <h4 style="color: #fcfcfc; margin-left: 40px; ">Forgot your password?</h4>
            </tr>
            <label>{{ resetOption === 'email' ? 'Email*' : 'Phone number*' }}</label>
            <div class="input-group">
                <select class="form-control1" (change)="onChange($event)" formControlName="resetOption" style="margin-right: 5px;">
          <option value="email">Email</option>
          <option value="phone">Number</option>
        </select>

                <!-- Bloc pour l'email -->
                <div *ngIf="resetOption === 'email'">
                    <input type="email" class="form-control email-input" placeholder="Enter your email address" formControlName="email" required pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}$">
                    <!-- Messages d'erreur pour l'email -->
                    <div *ngIf="forgetPWDForm.get('email')?.invalid && forgetPWDForm.get('email')?.touched">
                        <div class="danger-text" *ngIf="forgetPWDForm.get('email')?.errors?.required">
                            Email is required!
                        </div>
                        <div *ngIf="forgetPWDForm.get('email')?.errors?.pattern" class="danger-text">
                            Your email is invalid, please check
                        </div>
                    </div>
                </div>

                <!-- Bloc pour le numéro de téléphone -->
                <div *ngIf="resetOption === 'phone'">
                    <ngx-intl-tel-input [preferredCountries]="['tn', 'ca' , 'br']" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="searchCountryFields" [selectFirstCountry]="false" [selectedCountryISO]="defaultCountryISO" [inputId]="'phone'" formControlName="phone">
                    </ngx-intl-tel-input>
                    <!-- Messages d'erreur pour le numéro de téléphone -->
                    <div *ngIf="forgetPWDForm.get('phone')?.invalid && forgetPWDForm.get('phone').touched">
                        <div class="danger-text" *ngIf="forgetPWDForm.get('phone')?.errors?.required">
                            Phone number is required!
                        </div>
                        <div class="danger-text" *ngIf="forgetPWDForm.get('phone').get('number').invalid">
                            Your phone number is invalid, please check
                        </div>
                    </div>
                </div>
            </div>


            <div class="form-group-cap">
                <re-captcha [siteKey]="recaptchaSecret" (resolved)="onCaptchaResolved($event)">
                </re-captcha>
            </div>

            <small class="form-text" style="color: #008cff;">Type in the email address or phone number you used when you registered.
        Then we'll send a code to this address.</small>

            <!--
            <div style="display: flex; justify-content: center; gap: 15px;">
                <button nbButton status="info" [disabled]="!isFormValid()" hero (click)="resetOption === 'phone' ? sendSMStwilio() : forgetPWD()">
          {{ resetOption === 'phone' ? 'Get New Password' : 'Get New Password' }}
        </button>
            </div>-->

            <div style="align-self: flex-end; width: 100%; text-align: center; margin-top: 20px;">
                <a href="/auth/login" style="margin-bottom: 10px; text-decoration: underline; color: #000c26; font-weight: bold;">Back to Home</a>
            </div>

        </form>
    </nb-layout-column>
</nb-layout>