import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { Oauth2CallbackComponent } from './components/oauth2-callback/oauth2-callback.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { UnlockingComponent } from './components/unlocking/unlocking.component';
//import { VerificationCodeComponent } from './components/verification-code/verification-code.component';

const routes: Routes = [
  {
    path: 'login', component: LoginComponent,
  }, 
  {
    path: 'callback', component: Oauth2CallbackComponent,
  },
  {
    path: 'unlocking/:email', component: UnlockingComponent,
  },
  {
    path: 'forget',
    component: ForgetPasswordComponent,
  },
  {
    path: 'reset_pwd/:token',
    component: ResetPasswordComponent,
  },
/*  {
    path: 'verif_code/:token',
    component: VerificationCodeComponent,
  },*/


];

@NgModule({ 
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
