import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Liability } from 'app/modules/financial-management/models/Liability';
import { LiabilityService } from 'app/modules/financial-management/service/LiabilityService/liability-service.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-liability-list',
  templateUrl: './liability-list.component.html',
  styleUrls: ['./liability-list.component.scss']
})
export class LiabilityListComponent implements OnInit{
  liability: Liability[];
    isLoading = true;

    liabilityData: Liability[] = [];
    settings = {
        hideSubHeader: false,
      
        actions: {
            add: false,
            edit: false,
            delete: false,
            custom: [
                {
                    name: 'view',
                    title: '<i class="fas fa-eye small mt-2"></i>',
                }
            ],
        },
        columns: {
          type: {
                title: 'Type',
                type: 'string',
            },
        
            amountOwed: {
                title: 'Amount',
                type: 'string',
            },

            purpose: {
              title: 'Purpose',
              type: 'string',
          },
          

      account: { 
        title: 'Account',
        valuePrepareFunction: (cell: any, row: any) => {
          return row.account?.name ;
        }
      }
    },
    pager: {
      display: true,
      perPage: 10,
    },
    };


    constructor(
      private router: Router,
      private es: ExportService,
      private liabilityService: LiabilityService
  ) { }


  ngOnInit(): void {
    this.getAllLiabilities();
}

getAllLiabilities() {
  this.liabilityService.getAllLiabilities().subscribe(
    (data: Liability[]) => {
      console.log(data); // Vérifiez ici
      this.liabilityData = data.filter(l => !l.archived);
      console.log(this.liabilityData); // Vérifiez ici aussi
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      return err;
    },
  );
}



  

  
exportExcel() {
  const title = 'Liabilities list';
  const data = this.liabilityData.map(p => ({
      'type': p.type,
      'amountOwed': p.amountOwed,
      'interestRate': p.interestRate,
      'maturityDate': p.maturityDate,
      'repaymentTerms': p.repaymentTerms,
      'collateral': p.collateral,
      'covenants': p.covenants,
      'purpose': p.purpose,
      'creditRating': p.creditRating,
      'legalRequirements': p.legalRequirements,
      'account': p.account.name,

      

  }));
  this.es.exportExcel(title, data);
}


  onCustom(event): void {
    const selectedLiability: Liability = event.data;
    this.router.navigate(['/finance/updateLiability', selectedLiability.id]);
}
  }
