<nb-card>
    <nb-card-header>
        <div class="row">
            <div class="col-md-10">
                <h3>Archived Expenses</h3>
            </div>

        </div>
    </nb-card-header>

    <nb-card-body>
        <br/>

        <ng2-smart-table [settings]="settings" (custom)="onCustom($event)" class="table" [source]="expenses">
        </ng2-smart-table>
    </nb-card-body>
</nb-card>