import { Component, OnInit } from '@angular/core';
import { NbWindowService, NbToastrService } from '@nebular/theme';
import { ArchivedHelperComponent } from 'app/modules/financial-management/components/Utils/helper/archived-helper/archived-helper.component';
import { Tax } from 'app/modules/financial-management/models/Tax';
import { TaxService } from 'app/modules/financial-management/service/Tax/tax.service';

@Component({
  selector: 'ngx-archived-taxes-list',
  templateUrl: './archived-taxes-list.component.html',
  styleUrls: ['./archived-taxes-list.component.scss']
})
export class ArchivedTaxesListComponent implements OnInit  {

  constructor(
    private taxService: TaxService,
    private ws: NbWindowService,
    private toastrService: NbToastrService,

  ) {}

  taxes: Tax[];
  isLoading = true;
  taxId: string;
  tax: Tax;



  tabSettings = {
    hideSubHeader: false ,
    pager: {
      display: true,
      perPage: 10,
    },
    columns: {
     
      name: {
        title: 'Name',
        type: 'string'
      },
      rate: {
        title: 'Rate %',
        type: 'number' 
      },
      country: {
        title: 'Country',
        type: 'number' 
      },
      description: {
        title: 'Description',
        type: 'string'
      },
     
     
      
       /*  account: {
        title: 'Account',
        type: 'String' 
  
     valuePrepareFunction: (Account) => {
          return `${Account.name} `;
  
      },
    
    }
        */
  
  
      
    },
  
    actions: { edit: false, delete: false, add: false,
      custom: [
        {
          name: 'Restore',
          title: '<i class="fas fa-history fa-xs custom-restore-icon" title="Restore this tax"></i>',
        }],
    },
   
   };


   
   
  
  ngOnInit(): void {
    this.getAllArchivedTaxes();
  }

  getAllArchivedTaxes(): void {
    this.taxService.getArchivedTaxes().subscribe(
      data => {
        console.log('Received archived taxes :', data);
        this.taxes = data;
      },
      error => {
        console.error('Error fetching taxes:', error);
      }
    );
  }

  restoreConfirmation(taxId: string): void {
    if (window.confirm('Are you sure you want to restore this taxes?')) {
      this.restoreTax(taxId);
    }
  }

  restoreTax(taxId: string): void {
    this.taxService.archiveTax(taxId).subscribe({
      next: () => {
        this.showToast('success', 'Tax Restored', 'The tax has been restored successfully.');
        window.location.reload();
      },
      error: (err: any) => {
        console.error('Error archiving tax:', err);
        this.showToast('danger', 'Error', 'An error occurred while archiving the tax.');
      }
    });
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }


  onCustom(event): void {
    if (event.action === 'Restore') {
      this.restoreConfirmation(event.data.id);
    }
  }


  openWindow() {
    this.ws.open(ArchivedHelperComponent, {title: `Help`});
  
  }
  
}
