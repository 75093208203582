import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-forecast-helper',
  templateUrl: './forecast-helper.component.html',
  styleUrls: ['./forecast-helper.component.scss'],
})
export class ForecastHelperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
