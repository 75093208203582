import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService, NbDialogService, NbToastrConfig, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { Account } from 'app/modules/financial-management/models/Account';
import { Expense } from 'app/modules/financial-management/models/Expense';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-account-data-list',
  templateUrl: './account-data-list.component.html',
  styleUrls: ['./account-data-list.component.scss']
})
export class AccountDataListComponent  implements OnInit {

  constructor(private accountService: AccountsService ,
             private router: Router,
             private es: ExportService,
             private toastrService: NbToastrService,
             private dialogService: NbDialogService


 )  {}

accountsData: Account[] = [];
account: Account;
config: NbToastrConfig;
current: number = 1;
search: string = '';
index = 1;
destroyByClick = true;
duration = 2000;
hasIcon = true;
position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
preventDuplicates = false;
status: NbComponentStatus = 'primary';
pageSize: number = 3;

ngOnInit(): void {
  this.loadAccounts();
}

loadAccounts(): void {
  this.accountService.getAllAccounts().subscribe(
    (data: Expense[]) => {
      this.accountsData = data.filter(data => !data.archived);
      console.log(this.accountsData); 
    }, (err) => {
      return err;
    },
  );
}


exportExcel() {
  const title = 'Accounts list';
  const data = this.accountsData.map(a => ({
      'Code': a.code,
      'Name': a.name,
      'Balance': a.balance,
      'Created At': a.createdAt,
      'Updated At': a.updatedAt,

  }));
  this.es.exportExcel(title, data);
}

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }


  getArchived(isArchived: boolean) {
    this.accountService.getAllAccounts().subscribe(
      (data: Expense[]) => {
        this.accountsData = data.filter(account => account.archived === isArchived);
      }, (err) => {
        console.error(err);
      }
    );
  }
  
  open2(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }


  onArchiveConfirm(account: Account, ref: any) {
    this.accountService.archiveAccount(account.id).subscribe(
      () => {
        this.showToast('success', 'info', 'Operation successful!');
        this.accountsData.splice(this.accountsData.indexOf(account), 1);
        ref.close();
      }, errorArchive => {
        console.error(errorArchive);
      });
  }
}
