<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h3>Add Expense</h3>
                    </div>
                    <div class="col-md-6 text-right">
                        <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/finance/expensesList']" nbButton>
                            <nb-icon icon="corner-up-right-outline"></nb-icon>
                        </a>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>
                <form [formGroup]="expenseForm" (ngSubmit)="onSubmit()">

                    <div class="form-group">
                        <label for="amount" class="label">Amount</label>
                        <input nbInput fullWidth id="amount" formControlName="amount" type="number" placeholder="Enter amount">
                        <div *ngIf="expenseForm.get('amount').invalid && (expenseForm.get('amount').dirty || expenseForm.get('amount').touched)" class="text-danger">
                            <div *ngIf="expenseForm.get('amount').errors.required">Amount is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="description" class="label">Description</label>
                        <input nbInput fullWidth id="description" formControlName="description" placeholder="Enter expense description">
                        <div *ngIf="expenseForm.get('description').invalid && (expenseForm.get('description').dirty || expenseForm.get('description').touched)" class="text-danger">
                            <div *ngIf="expenseForm.get('description').errors.required">Description is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="paymentMethod" class="label">Payment method:</label>
                        <nb-select formControlName="paymentMethod" id="paymentMethod" fieldSize="large" fullWidth placeholder="Select payment method">
                            <nb-option value="bank_transfer">Bank Transfer</nb-option>
                            <nb-option value="cheque">Cheque</nb-option>
                            <nb-option value="direct_deposit">Direct Deposit</nb-option>
                        </nb-select>
                        <div *ngIf="expenseForm.get('paymentMethod').invalid && (expenseForm.get('paymentMethod').dirty || expenseForm.get('paymentMethod').touched)" class="text-danger">
                            <div *ngIf="expenseForm.get('paymentMethod').errors.required">Payment method is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="date" class="label">Date</label>
                        <input nbInput fullWidth id="date" formControlName="date" type="date" placeholder="Enter date">
                        <div *ngIf="expenseForm.get('date').invalid && (expenseForm.get('date').dirty || expenseForm.get('date').touched)" class="text-danger">
                            <div *ngIf="expenseForm.get('date').errors.required">Date is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="receiptNumber" class="label">Receipt Number</label>
                        <input nbInput fullWidth id="receiptNumber" formControlName="receiptNumber" placeholder="Enter receipt number">
                        <div *ngIf="expenseForm.get('receiptNumber').invalid && (expenseForm.get('receiptNumber').dirty || expenseForm.get('receiptNumber').touched)" class="text-danger">
                            <div *ngIf="expenseForm.get('receiptNumber').errors.required">Receipt number is required.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="account" class="label">Account</label>
                        <nb-select fullWidth id="account" formControlName="account" placeholder="Select account">
                            <nb-option *ngFor="let account of accounts" [value]="account.id">{{ account.name }}</nb-option>
                        </nb-select>
                        <div *ngIf="expenseForm.get('account').invalid && (expenseForm.get('account').dirty || expenseForm.get('account').touched)" class="text-danger">
                            <div *ngIf="expenseForm.get('account').errors.required">Account is required.</div>
                        </div>
                    </div>

                    <div class="row justify-content-center mt-4">
                        <div class="col-md-12 text-center">
                            <button nbButton status="primary" [disabled]="!expenseForm.valid" class="mr-2">
                                <nb-icon icon="checkmark-circle-outline"></nb-icon>Create
                            </button>
                        </div>
                    </div>
                </form>
            </nb-card-body>
        </nb-card>
    </div>
</div>