import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-global-help',
  templateUrl: './global-help.component.html',
  styleUrls: ['./global-help.component.scss'],
})
export class GlobalHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
