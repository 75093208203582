import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Account } from 'app/modules/financial-management/models/Account';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';

@Component({
  selector: 'ngx-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent {

  accountForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private accountService: AccountsService,
    private toastrService: NbToastrService
  ) {
    this.accountForm = this.fb.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      balance: ['', Validators.required],
      createdAt: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.accountForm.valid) {
      const newAccount: Account = this.accountForm.getRawValue();
      this.accountService.createAccount(newAccount).subscribe(
        () => {
          this.showToast('success', 'Account Created', 'The account has been created successfully.');
          this.router.navigate(['/finance/accountManagement']);
        },
        (error) => {
          console.error('Error creating account:', error);
          this.showToast('danger', 'Error', 'An error occurred while creating the account.');
        }
      );
    } else {
      this.markAllAsTouched();
    }
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  private markAllAsTouched() {
    Object.keys(this.accountForm.controls).forEach(field => {
      const control = this.accountForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
