import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Tax } from 'app/modules/financial-management/models/Tax';
import { TaxService } from 'app/modules/financial-management/service/Tax/tax.service';

@Component({
  selector: 'ngx-add-tax',
  templateUrl: './add-tax.component.html',
  styleUrls: ['./add-tax.component.scss']
})
export class AddTaxComponent {

  taxForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private taxService: TaxService,
    private toastrService: NbToastrService
  ) {
    this.taxForm = this.fb.group({
      name: ['', Validators.required],
      rate: ['', Validators.required],
      country: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.taxForm.valid) {
      const newTax: Tax = this.taxForm.getRawValue();
      this.taxService.createTax(newTax).subscribe(
        () => {
          this.showToast('success', 'Tax Created', 'The tax has been created successfully.');
          this.router.navigate(['/finance/taxes']);
        },
        (error) => {
          console.error('Error creating tax:', error);
          this.showToast('danger', 'Error', 'An error occurred while creating the tax.');
        }
      );
    } else {
      this.markAllAsTouched();
    }
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  private markAllAsTouched() {
    Object.keys(this.taxForm.controls).forEach(field => {
      const control = this.taxForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
