import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import { Account } from '../../models/Account';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {

  httpOptions = {
    headers: new HttpHeaders({

      'Content-Type': 'application/json;  charset=utf-8 ',
    }),
  };

  private Url = environment.financeUrl + 'account';

  constructor(private httpclient: HttpClient) {



  }
  public getAllExternalAccounts (): Observable<Object[]> {
    return this.httpclient.get<Object[]>(`${this.Url}/retrieveExternalAccounts`);
  }
  public getGlobalAccountBalance (): Observable<number> {
    return this.httpclient.get<number>(`${this.Url}/retrieveGlobalAccountBalance`);
  }
  public getBankAccountBalance (): Observable<number> {
    return this.httpclient.get<number>(`${this.Url}/retrieveBankAccountBalance`); }

    createAccount(account: Account): Observable<Account> {
      return this.httpclient.post<Account>(`${this.Url}/createAccount`, account);
    }
  
    updateAccount(id: string, account: Account): Observable<Account> {
      return this.httpclient.put<Account>(`${this.Url}/updateAccount/${id}`, account);
    }

   
  
    updateBankAccountBalance(amount: number): Observable<Account> {
      return this.httpclient.put<Account>(`${this.Url}/updateBankAccountBalance?amount=${amount}`, {});
    }
  
    updateGlobalAccountBalance(amount: number): Observable<Account> {
      return this.httpclient.put<Account>(`${this.Url}/updateGlobalAccountBalance?amount=${amount}`, {});
    }
  
    retrieveExternalAccounts(): Observable<Object[]> {
      return this.httpclient.get<Object[]>(`${this.Url}/retrieveExternalAccounts`);
    }
  
    retrieveGlobalAccountBalance(): Observable<string> {
      return this.httpclient.get<string>(`${this.Url}/retrieveGlobalAccountBalance`);
    }
  
    retrieveBankAccountBalance(): Observable<string> {
      return this.httpclient.get<string>(`${this.Url}/retrieveBankAccountBalance`);
    }
  
    getAccountById(id: string): Observable<Account> {
      return this.httpclient.get<Account>(`${this.Url}/getAccountById/${id}`);
    }
  
    getAllAccounts(): Observable<Account[]> {
      return this.httpclient.get<Account[]>(`${this.Url}/getAllAccounts`);
    }
  
    updateBalance(id: string, amount: number): Observable<Account> {
      return this.httpclient.put<Account>(`${this.Url}/updateBalance/${id}?amount=${amount}`, {});
    }
  
    archiveAccount(id: string): Observable<Account> {
      return this.httpclient.put<Account>(`${this.Url}/archive/${id}`, {});
    }
  
    getArchivedAccounts(): Observable<Account[]> {
      return this.httpclient.get<Account[]>(`${this.Url}/getArchivedAccounts`);
    }

}
