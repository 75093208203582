<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h3>Asset details</h3>
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- Bouton pour activer les champs -->
                        <button nbTooltip="Enable fields" nbTooltipStatus="primary" shape="round" status="basic" (click)="enableFormFields()" nbButton class="icon-button mr-2">
                            <nb-icon icon="edit-outline"></nb-icon> 
                        </button>
                        <button nbTooltip="Archive asset" nbTooltipStatus="primary" shape="round" status="danger" (click)="archiveConfirmation()" nbButton class="icon-button mr-2">
                            <nb-icon icon="archive-outline"></nb-icon>
                        </button>
                        <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/finance/assetsList']" nbButton class="icon-button">
                            <nb-icon icon="corner-up-right-outline"></nb-icon>
                        </a>
                    </div>
                </div>
            </nb-card-header>

            <nb-card-body>
                <form [formGroup]="assetForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="label" for="name">Name</label>
                        <input nbInput fullWidth id="name" formControlName="name" placeholder="Enter asset name">
                    </div>
                    <div class="form-group">
                        <label class="label" for="initialValue">Initial Value</label>
                        <input nbInput fullWidth id="initialValue" formControlName="initialValue" type="number" placeholder="Enter initial value">
                    </div>
                    <div class="form-group">
                        <label class="label" for="currentValue">Current Value</label>
                        <input nbInput fullWidth id="currentValue" formControlName="currentValue" type="number" placeholder="Current value">
                    </div>
                    <div class="form-group">
                        <label class="label" for="purchaseDate">Purchase Date</label>
                        <input nbInput fullWidth id="purchaseDate" formControlName="purchaseDate" type="date" placeholder="Enter purchase date">
                    </div>
                    <div class="form-group">
                        <label class="label" for="account">Account</label>
                        <nb-select fullWidth id="account" formControlName="account">
                            <nb-option *ngFor="let account of accounts" [value]="account.id">{{ account.name }}</nb-option>
                        </nb-select>
                    </div>
                    <div class="form-group d-flex justify-content-between">
                        <button nbButton status="warning" (click)="openDepreciationDialog()">
                            <nb-icon icon="pie-chart-outline"></nb-icon> 

                                                       Calculate Depreciation
                        </button>
                        <button type="submit" nbButton status="success">
                            <nb-icon icon="checkmark-circle-outline"></nb-icon>
                            Update 
                        </button>
                    </div>
                </form>
            </nb-card-body>
        </nb-card>
    </div>
</div>