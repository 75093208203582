<nb-card>
    <nb-card-header>
        <h3>Usage tips</h3>
    </nb-card-header>
    <nb-card-body>
        <p>
            1-Add the shareholder.
            <br> 2-Add The dividend.
            <br>3-see the list of dividends with associated shareholders.
            <br>4-Click On the Excel Logo to export data.




        </p>


        <br>
        <h4> Notes </h4>
        <br>
        <p>-When you access the dividend details, you can update only the fields related to the dividend such as amount, payment date, frequency, status, payment method, and currency, but if you want to update shareholder's data you can access to shareholders
            details page </p>



    </nb-card-body>
</nb-card>