import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Asset } from 'app/modules/financial-management/models/Asset';
import { Liability } from 'app/modules/financial-management/models/Liability';
import { AssetService } from 'app/modules/financial-management/service/AssetService/asset-service.service';
import { LiabilityService } from 'app/modules/financial-management/service/LiabilityService/liability-service.service';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.scss']
})
export class BalanceSheetComponent implements OnInit {
  asset: Asset[];
  isLoading = true;

  assetsData: Asset[] = [];
  assetsSettings = {
    hideSubHeader: false,
    actions: {
        add: false,
        edit: false,
        delete: false,
      
    },
    columns: {
        name: {
            title: 'Name',
            type: 'string',
        },
        initialValue: {
            title: 'Initial Value',
            type: 'number',
        },
        currentValue: {
            title: 'Current Value',
            type: 'number',
        },
        account: {
            title: 'Account',
            valuePrepareFunction: (cell: any, row: any) => {
                return row.account?.name;
            }
        }
    },
    pager: {
        display: true,
        perPage: 15,
    },
};

liability: Liability[];

liabilityData: Liability[] = [];
LiabilitySettings = {
    hideSubHeader: false,
  
    actions: {
        add: false,
        edit: false,
        delete: false,
       
    },
    columns: {
      type: {
            title: 'Type',
            type: 'string',
        },
    
        amountOwed: {
            title: 'Amount',
            type: 'string',
        },

        purpose: {
          title: 'Purpose',
          type: 'string',
      },
      

  account: { 
    title: 'Account',
    valuePrepareFunction: (cell: any, row: any) => {
      return row.account?.name ;
    }
  }
},
    pager: {
        display: true,
        perPage: 15,
    },
};



constructor(
  private assetService: AssetService,
  private liabilityService: LiabilityService


) { }


ngOnInit(): void {
this.getAllAssets();
this.getAllLiabilities();

}

getAllAssets() {
  this.assetService.getAllAssets().subscribe(
    (data: Asset[]) => {
      console.log(data); // Vérifiez ici
      this.assetsData = data.filter(l => !l.archived);
      console.log(this.assetsData); // Vérifiez ici aussi
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      return err;
    },
  );
}

getAllLiabilities() {
  this.liabilityService.getAllLiabilities().subscribe(
    (data: Liability[]) => {
      console.log(data); // Vérifiez ici
      this.liabilityData = data.filter(l => !l.archived);
      console.log(this.liabilityData); // Vérifiez ici aussi
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      return err;
    },
  );
}
}
