import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dividend } from '../../models/Dividend';
import { environment } from '../../../../../environments/environment';
import { Shareholder } from '../../models/Shareholder';

@Injectable({
  providedIn: 'root'
})
export class DividendService {


  private baseUrl = environment.financeUrl + 'api/dividends';


  constructor(private http: HttpClient) { }

  saveDividend(dividend: Dividend): Observable<Dividend> {
    return this.http.post<Dividend>(`${this.baseUrl}/createDividend`, dividend);
  }

  updateDividend(id: string, dividend: Dividend): Observable<Dividend> {
    return this.http.put<Dividend>(`${this.baseUrl}/updateDividend/${id}`, dividend);
  }

  deleteDividend(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/deleteDividend/${id}`);
  }

  getDividendById(id: string): Observable<Dividend> {
    return this.http.get<Dividend>(`${this.baseUrl}/getDividendById/${id}`);
  }

  getAllDividends(): Observable<Dividend[]> {
    return this.http.get<Dividend[]>(`${this.baseUrl}/getAllDividends`);
  }

  getShareholderById(id: string): Observable<Shareholder> {
    return this.http.get<Shareholder>(`${this.baseUrl}/getShareholderById/${id}`);
  }


  archiveDividend(id: string): Observable<Dividend> {
    return this.http.put<Dividend>(`${this.baseUrl}/archive/${id}`, {});
  }

  getArchivedDividends(): Observable<Dividend[]> {
    return this.http.get<Dividend[]>(`${this.baseUrl}/getArchivedDividend`);
  }



  
  
}
