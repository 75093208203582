import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbWindowService } from '@nebular/theme';
import { Dividend } from 'app/modules/financial-management/models/Dividend';
import { DividendService } from 'app/modules/financial-management/service/Dividend/dividend.service';
import { DividendHelperComponent } from '../dividend-helper/dividend-helper.component';

@Component({
  selector: 'ngx-dividend-details',
  templateUrl: './dividend-details.component.html',
  styleUrls: ['./dividend-details.component.scss']
})
export class DividendDetailsComponent implements OnInit {

  idUri: string = '';
  divdend: Dividend;
  dividendForm: FormGroup;
  formDisabled: boolean = true;


  constructor(
    private activatedroute: ActivatedRoute,
    private dividendService: DividendService,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private router: Router,
    private ws: NbWindowService,

  ) { }

  ngOnInit(): void {
    this.dividendForm = this.fb.group({
      amount: [{ value: '' }, Validators.required],
      paymentDate: [{ value: '' }, Validators.required],
      frequency: [{ value: '' }, Validators.required],
      status: [{ value: '' }, Validators.required],
      paymentMethod: [{ value: '' }, Validators.required],
      currency: [{ value: '' }, Validators.required],
      shareholderFirstName: [{value: '', disabled: true}],
      shareholderLastName: [{value: '', disabled: true}],
      shareholderNumberOfActions: [{value: '', disabled: true}],
      shareholderPercentage: [{value: '', disabled: true}]
    });

    this.activatedroute.paramMap.subscribe(result => {
      this.idUri = result.get('id');
      this.dividendService.getDividendById(this.idUri).subscribe(
        result => {
          console.log('Fetched dividend details:', result); 
          this.divdend = result;

          // Ensure shareholderr exists and contains the necessary fields
          if (result.shareholderr) {
            this.dividendForm.patchValue({
              amount: result.amount,
              paymentDate: result.paymentDate,
              frequency: result.frequency,
              status: result.status,
              paymentMethod: result.paymentMethod,
              currency: result.currency,
              shareholderFirstName: result.shareholderr.shareholderFirstName,
              shareholderLastName: result.shareholderr.shareholderlastName,
              shareholderNumberOfActions: result.shareholderr.shareholderNumberOfActions,
              shareholderPercentage: result.shareholderr.shareholderPercentage
            });
            console.log('Form values after patchValue:', this.dividendForm.value); 
          } else {
            console.error('shareholderr data is missing in the response');
          }
        },
        error => {
          console.error('Error fetching dividend management details:', error);
        }
      );
    });
  }

  calculateShareholderPercentage(dividendAmount: number, numberOfActions: number): number {
    return (dividendAmount / numberOfActions) * 100;
  }

  onSubmit(): void {
    if (this.dividendForm.valid) {
      const dividendAmount = this.dividendForm.get('amount')?.value;
      const numberOfActions = this.dividendForm.get('shareholderNumberOfActions')?.value;
      const calculatedPercentage = this.calculateShareholderPercentage(dividendAmount, numberOfActions);

      const updatedDividend: Dividend = {
        ...this.divdend,
        ...this.dividendForm.value,
        shareholderr: {
          ...this.divdend.shareholderr,
          shareholderPercentage: calculatedPercentage
        }
      };

      this.dividendService.updateDividend(this.idUri, updatedDividend).subscribe(
        result => {
          this.toastrService.success('Dividend updated successfully', 'Success');
          this.router.navigate(['/finance/dividentProcess']);
          console.log('Dividend updated:', result);
        },
        error => {
          console.error('Error updating dividend:', error);
        }
      );
    }
  }

  archiveDividendConfirmation(): void {
    if (window.confirm('Are you sure you want to archive this dividend?')) {
      this.archiveDividend();
    }
  }

  archiveDividend(): void {
    this.dividendService.archiveDividend(this.idUri).subscribe(
      result => {
        console.log('Dividend archived:', result);
        this.router.navigate(['/finance/dividentProcess']);
      },
      error => {
        console.error('Error archiving dividend:', error);
      }
    );
  }


  openWindow() {
    this.ws.open(DividendHelperComponent, {title: `Help`});

  }

  enableFormFields(): void {
    this.formDisabled = false;
    Object.keys(this.dividendForm.controls).forEach(key => {
      this.dividendForm.controls[key].enable();
    });
  }

  markAllAsTouched(): void {
    Object.keys(this.dividendForm.controls).forEach(key => {
      this.dividendForm.controls[key].markAsTouched();
    });
  }
}
