import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService, NbWindowService } from '@nebular/theme';
import { Account } from 'app/modules/financial-management/models/Account';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { GlobalHelpComponent } from '../../Transactions/transactions-utils/global-help/global-help.component';
import { DatePipe } from '@angular/common';
import { ExportService } from 'app/shared/exports/export.service';

@Component({
  selector: 'ngx-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {

  constructor(
    private as: AccountsService,
    private router: Router,
    private ws: NbWindowService,
    private toastrService: NbToastrService,
    private datePipe: DatePipe,
    private es: ExportService


  ) {}

  accounts: Account[];
  isLoading = true;

  tabSettings = {
    hideSubHeader: false,

   /* add: {
      addButtonContent: ' <i class="nb-plus"></i> ',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
      inputClass: 'MoneyFlow',
    },*/
    actions: {
      add: false,
      edit: false,
      delete:false,

      custom: [
        {
          name: 'view',
          title: '<i  class="fas fa-eye small mt-2"></i>',

        }],
    },
  
    columns: {
      code: {
        title: 'Code',
        type: 'string',
      },
      name: {
        title: 'Name',
        type: 'string',
      },

      balance: {
        title: 'Balance',
        type: 'number',
      },
      createdAt: {
        title: 'Created At',
        valuePrepareFunction: (date) => {
          return this.datePipe.transform(date, 'dd/MM/yyyy HH:mm');
        },      },
      updatedAt: {
        title: 'Updated At',
        valuePrepareFunction: (date) => {
          return this.datePipe.transform(date, 'dd/MM/yyyy HH:mm');

        },      },
     
    },
    pager: {
      display: true,
      perPage: 10,
    },
  };
  

  ngOnInit(): void {
    this.getAllAccounts();
  }

  getAllAccounts(): void {
    this.as.getAllAccounts().subscribe(
      (data: Account[]) => {
        this.accounts = [] ;
        for (let p = 0; p < data.length; p++) {
          if (data[p].archived === false) {
            this.accounts.push(data[p]);
          }
        }
      

      }, (err) => {
        return err;
      },
    );
  }


 
  loadData() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000); 
  }

  openWindow() {
    this.ws.open(GlobalHelpComponent, { title: `Help` });
  }

 onCustom(event): void {
          const selectedAccount: Account = event.data;
            console.log('Selected Account ID:', selectedAccount.id);
        this.router.navigate(['/finance/update-account', selectedAccount.id]);
    
  }


  onAdd(event): void {
    const newAccount: Account = event.newData; 
    this.as.createAccount(newAccount).subscribe(
      (createdAccount: Account) => {
        this.showToast('success', 'Account Created', 'The account has been created successfully.');
        // Attendre quelques secondes avant de recharger la page
        setTimeout(() => {
          window.location.reload();
        }, 1500); 
      },
      (error) => {
        console.error('Error creating account:', error);
        this.showToast('danger', 'Error', 'An error occurred while creating the account.');
      }
    );
  }
  

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  exportExcel() {
    const title = 'Accounts list';
    const data = this.accounts.map(a => ({
        'Code': a.code,
        'Name': a.name,
        'Balance': a.balance,
        'Created At': a.createdAt,
        'Updated At': a.updatedAt,

    }));
    this.es.exportExcel(title, data);
}
  
  }
