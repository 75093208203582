<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">

<div class="content">
    <div class="container">

        <nb-card-header>
            <div class="row">
                <div class="col-md-3">
                    <h3>Taxes</h3>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <input [(ngModel)]="search" nbInput class="form-control" placeholder="Search Tax" type="text">

                    </div>
                </div>
                <div class="col-md-5 text-right">
                    <a [routerLink]="['/finance/taxDataList']" nbButton nbTooltip="Visualize data on keypad" nbTooltipStatus="primary" status="warning">
                        <nb-icon icon="keypad-outline"></nb-icon>
                    </a>&nbsp;
                    <a [routerLink]="['/finance/taxes']" nbButton nbTooltip="Visualize data on list" nbTooltipStatus="primary" status="basic">
                        <nb-icon icon="list-outline"></nb-icon>
                    </a>&nbsp;
                    <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" (click)="exportExcel()">
                            <i class="fas fa-file-excel text-white"></i>
                        </button>&nbsp;
                    <button [routerLink]="['/finance/addTax']" class="mx-1" nbButton nbTooltip="Click here to create a new expense" nbTooltipStatus="primary" status="primary">
                            <nb-icon icon="plus-outline"></nb-icon> New tax
                        </button>
                </div>
            </div>
        </nb-card-header>


        <div class="row mt-3">
            <div class="col-md-3">
                <nb-form-field>
                    <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
                    <nb-select placeholder="Filter by Archived" (selectedChange)="getArchived($event)" selected="0" class="mx-2 mt-3 w-75">
                        <nb-option [value]="false">Active</nb-option>
                        <nb-option [value]="true">Archived</nb-option>
                    </nb-select>
                </nb-form-field>
                <br>

                <div class="col-lg-10 mt-3">
                    <label>Items Per Page</label>
                    <input class="w-75" type="number" nbInput [(ngModel)]="pageSize" min="3">
                </div>
            </div>

            <div class="col-md-9">
                <div *ngIf="!taxData || taxData.length === 0">
                    <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
                </div>
                <div [hidden]="taxData.length === 0" *ngIf="(taxData | filter : search).length === 0">
                    <nb-alert outline="danger" class="w-100 mt-3">Item Not Found</nb-alert>
                </div>
                <div class="row mt-3">
                    <div class="col-md-4" *ngFor="let e of taxData  | filter : search | paginate : 
                       {itemsPerPage:pageSize, currentPage: current, totalItems: taxData.length}; let i = index">
                        <nb-card>
                            <nb-card-body>
                                <div class="row">
                                    <div class="col-md-10">
                                        <strong>Name:</strong><br> <span>{{e.name}}</span>
                                        <br><br>
                                        <strong>Rate:</strong><br> <span>{{e.rate}}</span>
                                        <br><br>
                                        <strong>Country:</strong> <br><span>{{e.country}}</span>
                                        <br><br>
                                    </div>
                                    <div class="col-md-2">
                                        <button shape="round" nbButton status="basic" size="small" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <nb-icon icon="more-horizontal-outline"></nb-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item>
                                                <a [routerLink]="['/finance/updateTax/', e.id]" nbButton fullWidth>
                                                    <span class="text-info"><nb-icon icon="eye-outline"></nb-icon></span> Check tax
                                                </a>
                                            </button>
                                            <button mat-menu-item [hidden]="!e.archived">
                                                <a (click)="open2(dialog)" nbButton fullWidth>
                                                    <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon></span> Activate
                                                </a>
                                            </button>
                                            <button mat-menu-item [hidden]="e.archived">
                                                <a (click)="open2(dialog2)" nbButton fullWidth>
                                                    <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon></span> Archive
                                                </a>
                                            </button>
                                        </mat-menu>
                                        <ng-template #dialog let-data let-ref="dialogRef">
                                            <nb-card>
                                                <nb-card-header>Confirm Archive Operation</nb-card-header>
                                                <nb-card-body>Are you sure you would like to activate this tax?</nb-card-body>
                                                <nb-card-footer>
                                                    <div class="row">
                                                        <button class="col-md-2" status="danger" nbButton (click)="ref.close()">No</button>
                                                        <div class="col-md-8"></div>
                                                        <a class="col-md-2" status="success" nbButton (click)="onArchiveConfirm(e, ref)">Yes</a>
                                                    </div>
                                                </nb-card-footer>
                                            </nb-card>
                                        </ng-template>
                                        <ng-template #dialog2 let-data let-ref="dialogRef">
                                            <nb-card>
                                                <nb-card-header>Confirm Archive Operation</nb-card-header>
                                                <nb-card-body>Are you sure you would like to archive this tax?</nb-card-body>
                                                <nb-card-footer>
                                                    <div class="row">
                                                        <button class="col-md-2" status="danger" nbButton (click)="ref.close()">No</button>
                                                        <div class="col-md-8"></div>
                                                        <a class="col-md-2" status="success" nbButton (click)="onArchiveConfirm(e, ref)">Yes</a>
                                                    </div>
                                                </nb-card-footer>
                                            </nb-card>
                                        </ng-template>
                                    </div>
                                </div>
                            </nb-card-body>
                        </nb-card>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <nav aria-label="Page navigation example" class="bg-light" style="border-radius: 25px; height: 35px;">
                        <pagination-controls (pageChange)="current = $event" align="right"></pagination-controls>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>