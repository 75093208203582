<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h3>Dividend details</h3>
                    </div>
                    <div class="col-md-6 text-right">

                        <button nbTooltip="Archive Dividend" nbTooltipStatus="primary" shape="round" status="danger" (click)="archiveDividendConfirmation()" nbButton class="icon-button mr-2">
                            <nb-icon icon="archive-outline"></nb-icon>
                        </button>
                        <a nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/finance/dividentProcess']" nbButton class="icon-button mr-2">
                            <nb-icon icon="corner-up-right-outline"></nb-icon>
                        </a>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>
                <form [formGroup]="dividendForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="label">Amount:</label><br>
                        <input nbInput id="amount" placeholder="amount" formControlName="amount" class="form-control" fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group">
                        <label class="label" for="paymentDate">Payment date:</label><br>
                        <input formControlName="paymentDate" nbInput id="paymentDate" [nbDatepicker]="dividend" fieldSize="large" fullWidth>
                        <nb-datepicker withSeconds #dividend></nb-datepicker>
                    </div>
                    <div class="form-group">
                        <label class="label">Frequency:</label><br>
                        <nb-select formControlName="frequency" id="frequency" class="w-100" placeholder="Select frequency" fieldSize="large" fullWidth>
                            <nb-option value="quarterly">Quarterly</nb-option>
                            <nb-option value="annually">Annually</nb-option>
                        </nb-select>
                    </div>
                    <div class="form-group">
                        <label class="label">Status:</label><br>
                        <nb-select formControlName="status" id="status" class="w-100" placeholder="Select status" fieldSize="large" fullWidth>
                            <nb-option value="confirmed">Confirmed</nb-option>
                            <nb-option value="pending">Pending</nb-option>
                            <nb-option value="cancelled">Cancelled</nb-option>
                        </nb-select>
                    </div>
                    <div class="form-group">
                        <label class="label">Payment method:</label><br>
                        <nb-select formControlName="paymentMethod" id="paymentMethod" class="w-100" placeholder="Select payment method" fieldSize="large" fullWidth>
                            <nb-option value="bank_transfer">Bank Transfer</nb-option>
                            <nb-option value="cheque">Cheque</nb-option>
                            <nb-option value="direct_deposit">Direct Deposit</nb-option>
                        </nb-select>
                    </div>
                    <div class="form-group">
                        <label class="label">Currency:</label><br>
                        <nb-select formControlName="currency" id="currency" class="w-100" placeholder="Select currency" fieldSize="large" fullWidth>
                            <nb-option value="USD">USD</nb-option>
                            <nb-option value="EUR">EUR</nb-option>
                            <nb-option value="TND">TND</nb-option>
                            <nb-option value="GBP">GBP</nb-option>
                            <nb-option value="JPY">JPY</nb-option>
                        </nb-select>
                    </div>
                    <div class="form-group">
                        <label class="label">Shareholder first name:</label>
                        <input nbInput id="shareholderFirstName" placeholder="shareholderFirstName" formControlName="shareholderFirstName" fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group">
                        <label class="label">Shareholder last name:</label>
                        <input nbInput id="shareholderLastName" placeholder="shareholderLastName" formControlName="shareholderLastName" fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group">
                        <label class="label">Shareholder number of actions:</label>
                        <input nbInput id=" shareholderNumberOfActions" placeholder="shareholderNumberOfActions" formControlName="shareholderNumberOfActions" fieldSize="large" fullWidth>
                    </div>
                    <div class="form-group ">
                        <label class="label">Shareholder percentage:</label>
                        <input nbInput id=" shareholderPercentage" placeholder="shareholderPercentage" formControlName="shareholderPercentage" fieldSize="large" fullWidth>
                    </div>

                    <div class="col-md-12 text-center">
                        <button nbButton status="success" class="mr-2">
                            <nb-icon icon="checkmark-circle-outline"></nb-icon>Update
                        </button>
                    </div>
                </form>
            </nb-card-body>
        </nb-card>
    </div>
</div>