import { Component, OnInit } from '@angular/core';
import { MoneyFlow } from '../../models/MoneyFlow';
import { MoneyFlowService } from '../../../financial-management/service/Gap/money-flow.service';
import { DisabledInputComponent } from '../Transactions/annual-cashflow/months/Utils/Editors/disabled-input/disabled-input.component';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-money-flow',
  templateUrl: './money-flow.component.html',
  styleUrls: ['./money-flow.component.scss']
})
export class MoneyFlowComponent implements OnInit {
  moneyFlows: MoneyFlow[] = [];
  newMoneyFlow: MoneyFlow = {
    category: '',
    actual: 0,
    budget: 0,
    difference: 0,
    direction: '',
    subCategory: '',
    year: new Date().getFullYear(),
    month: '',
    type: ''
  };

  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      category: {
        title: 'Category',
        type: 'string'
      },
      actual: {
        title: 'Actual',
        type: 'number'
      },
      budget: {
        title: 'Budget',
        type: 'number'
      },
      direction: {
        title: 'Direction',
        type: 'string'
      },
      difference: {
        title: 'Difference',
        type : 'html',
        editor: {type: 'custom', component: DisabledInputComponent},
        valuePrepareFunction: (difference) => {
          switch (true) {
            case (difference < 0): {
              return `<h5><span class="badge badge-success">${difference} </span></h5>`;
            }
            case (difference >= 0) : {
              return `<h5><span class="badge badge-danger">${difference} </span></h5>`;
            }

          }



        },      },
    }
  };

  constructor(private moneyFlowService: MoneyFlowService ,  private toastrService: NbToastrService ) {}

  ngOnInit(): void {
    this.moneyFlows = [];
  }

  recordMoneyFlow(): void {
    this.moneyFlowService.recordMoneyFlow(this.newMoneyFlow).subscribe({
      next: (moneyFlow) => {
        console.log('MoneyFlow recorded:', moneyFlow);
     //   this.resetForm(); // Réinitialiser le formulaire
        this.toastrService.success('Money flow recorded!', 'Succes');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      error: (err) => {
        console.error('Error recording Money flow', err);
        this.toastrService.danger('Error recording MoneyFlow', 'Erreur');
      }
    });
  }
  

  analyzeMoneyFlows(): void {
    this.moneyFlowService.analyzeMoneyFlows().subscribe({
      next: (moneyFlows) => {
        // Calculer la différence pour chaque flux d'argent
        this.moneyFlows = moneyFlows.map(moneyFlow => ({
          ...moneyFlow,
          difference: moneyFlow.budget - moneyFlow.actual 
        })) as MoneyFlow[];
        console.log('Money flows analyzed:', this.moneyFlows);
      },
      error: (err) => {
        console.error('Error analyzing Money flows', err);
      }
    });
  }

  private resetForm(): void {
    this.newMoneyFlow = {
      category: '',
      actual: 0,
      budget: 0,
      difference: 0,
      direction: '',
      subCategory: '',
      year: new Date().getFullYear(),
      month: '',
      type: ''
    };
  }




}
